import React, { useCallback } from 'react';
import { Intake, SoftwareType } from '../../../generated/softwarematching-api';
import { useTranslation } from 'react-i18next';
import { FaChevronRight } from 'react-icons/fa';
import { ListItem } from '../../../common/ListItem';
import { useNavigate } from 'react-router-dom';

interface Props {
    intakes: Intake[];
    softwareTypes: SoftwareType[];
}

export function NewMatchListView({ intakes, softwareTypes }: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const determineSoftwareType = useCallback(
        (intake: Intake) =>
            softwareTypes.find(softwareType => softwareType.id === intake.softwareTypeId)?.naam ?? t('portal.unknown'),
        [softwareTypes, t]
    );
    return (
        <>
            {intakes.length > 0 && (
                <div className='space-y-5 mb-11'>
                    <h2 className='portal-heading-2' data-testid='new-matches-title'>
                        {t('portal.newMatches')}
                    </h2>

                    <ul data-testid='new-matches-list'>
                        {intakes.map(intake => {
                            return (
                                <ListItem
                                    key={intake.id}
                                    title={t('portal.matchItemTitle', { softwareType: determineSoftwareType(intake) })}
                                    subTitle={t('portal.matchItemSubTitle')}
                                    onClick={() => navigate(`/portal/intakes/${intake.id}/matches`)}>
                                    <FaChevronRight className='text-primary'></FaChevronRight>
                                </ListItem>
                            );
                        })}
                    </ul>
                </div>
            )}
        </>
    );
}
