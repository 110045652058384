import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePartnerService from '../hooks/usePartnerService';
import { Partnerprofiel } from '../../../generated/softwarematching-api';
import PartnerCreationModal from '../modals/PartnerCreationModal';
import { makeCancellable } from '../../../helpers/promise';
import { showErrorResponse } from '../../../helpers/notify';
import { FaEdit } from 'react-icons/fa';
import Loader from '../../../common/Loader';
import { Link, useNavigate } from 'react-router-dom';
import AddButton from '../../../common/button/AddButton';

export default function PartnersPage() {
    const { t } = useTranslation();
    const partnerService = usePartnerService();
    const navigate = useNavigate();

    const [profielen, setProfielen] = useState<Array<Partnerprofiel> | null>(null);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);

    const retrieveData = useCallback(() => {
        setLoading(true);
        setShowModal(false);

        const [promise, cancel] = makeCancellable(partnerService.getAllPartnerprofielen());
        promise
            .then(setProfielen)
            .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t))
            .finally(() => setLoading(false));
        return cancel;
    }, [partnerService, t]);

    useEffect(retrieveData, [retrieveData]);

    return (
        <div>
            <h1 className='font-header text-3xl'>{t('partner:management.manage-implementation-partners')}</h1>

            {loading ? (
                <Loader />
            ) : (
                <table className='admin-table lg:w-1/2 ' data-testid='partner-table'>
                    <thead>
                        <tr className='admin-table--header-row'>
                            <th className='admin-table--header-cell pl-4'>Bedrijfsnaam</th>
                            <th className='admin-table--header-cell'></th>
                            <th className='admin-table--header-cell'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {profielen?.map(profiel => (
                            <tr
                                key={profiel.id}
                                className='admin-table--body-row hover:cursor-pointer'
                                onClick={() => navigate(profiel.id!!)}>
                                <td className='admin-table--body-cell pl-4'>{profiel.bedrijfsnaam}</td>
                                {!profiel.actief ? (
                                    <td className='admin-table--body-cell' data-testid='inactive-status-pill'>
                                        <span className='badge badge-gray'>{t('common:inactive')}</span>
                                    </td>
                                ) : (
                                    <td className='admin-table--body-cell'></td>
                                )}
                                <td className='admin-table--body-cell w-10'>
                                    <Link to={profiel.id} data-testid={`edit-${profiel.bedrijfsnaam}`}>
                                        <FaEdit className='admin-icon hover:text-black' />
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            <AddButton onClick={() => setShowModal(true)} dataTestId='create-partner-btn' />
            {showModal && <PartnerCreationModal onClose={retrieveData} />}
        </div>
    );
}
