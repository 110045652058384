/* tslint:disable */
/* eslint-disable */
/**
 * Software Matching Api
 * Software Matching API for the match-making questionnaire service
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Types indicating how much a klant is in need of a certain functionaliteit
 * @export
 * @enum {string}
 */

export const Moscow = {
    MUST: 'MUST',
    SHOULD: 'SHOULD',
    WONT: 'WONT'
} as const;

export type Moscow = typeof Moscow[keyof typeof Moscow];



