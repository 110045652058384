import ExternalLink from '../../../common/ExternalLink';
import { convertToAlternativeMeetingProposal } from '../../../helpers/meeting';
import usePartnerService from '../hooks/usePartnerService';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import {
    IntakeMatchMeeting,
    MatchDetail,
    MatchStatus,
    MeetingAcceptRequest,
    MeetingAlternativeProposeRequest,
    TimeSlot,
} from '../../../generated/softwarematching-api';
import { useTranslation } from 'react-i18next';
import MatchDetailFunctionaliteitGroep from '../../portal/components/MatchDetailFunctionaliteitGroep';
import { FaArrowRight } from 'react-icons/fa';
import Accordion from '../../../common/accordion/Accordion';
import { MatchDetailVraaggroepVragen } from './MatchDetailVraaggroepVragen';
import { makeCancellable } from '../../../helpers/promise';
import { showErrorResponse } from '../../../helpers/notify';
import Loader from '../../../common/Loader';
import { MatchMeeting } from './MatchMeeting';
import MatchDeclineConfirmationModal from '../modals/MatchDeclineConfirmationModal';
import { useNavigate } from 'react-router-dom';

type Props = {
    matchResultId: string;
};

export function MatchDetails({ matchResultId }: Readonly<Props>) {
    const { t, i18n } = useTranslation();
    const partnerService = usePartnerService();
    const navigate = useNavigate();

    const [matchDetail, setMatchDetail] = useState<MatchDetail | null>(null);
    const [meetingProposals, setMeetingProposals] = useState<IntakeMatchMeeting | null>(null);
    const [loading, setLoading] = useState(true);
    const numberFmt = new Intl.NumberFormat(i18n.resolvedLanguage);
    const [matchDeclineConfirmation, setMatchDeclineConfirmation] = useState(false);

    const enableDetails =
        matchDetail &&
        (matchDetail.status === MatchStatus.PROPOSED_ALTERNATIVE ||
            matchDetail.status === MatchStatus.PLANNED ||
            matchDetail.status === MatchStatus.WON ||
            matchDetail.status === MatchStatus.KENNISMAKING_REVIEWED);

    useEffect(() => {
        const [promise, cancel] = makeCancellable(
            Promise.all([
                partnerService.getMatchDetail(matchResultId),
                partnerService.getMeetingProposals(matchResultId),
            ])
        );
        promise
            .then(([matchDetail, meetingProposals]) => {
                setMatchDetail(matchDetail);
                setMeetingProposals(meetingProposals);
            })
            .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t))
            .finally(() => setLoading(false));
        return cancel;
    }, [partnerService, t, matchResultId]);

    const acceptProposal = useCallback(
        (meetingId: string, timeslot: TimeSlot) => {
            setLoading(true);
            const meetingAcceptRequest: MeetingAcceptRequest = { meetingId: meetingId, timeslot: timeslot };
            partnerService
                .acceptMeeting(matchResultId, meetingAcceptRequest)
                .then(setMatchDetail)
                .catch(error => showErrorResponse(t('common:failedToUpdate'), error, t))
                .finally(() => setLoading(false));
        },
        [matchResultId, partnerService, t]
    );

    function clearMatchDeclineConfirmation() {
        setMatchDeclineConfirmation(false);
    }

    function showMatchDeclineConfirmation() {
        setMatchDeclineConfirmation(true);
    }

    const handleMeetingSubmit = useCallback(
        (timeslot: string) => {
            setLoading(true);
            const meetingAlternativeProposeRequest: MeetingAlternativeProposeRequest = {
                meetingProposal: convertToAlternativeMeetingProposal(timeslot),
            };
            const promise = partnerService.createMeetingAlternativeProposal(
                matchResultId,
                meetingAlternativeProposeRequest
            );
            promise
                .then(setMatchDetail)
                .catch(error => showErrorResponse(t('common:failedToUpdate'), error, t))
                .finally(() => setLoading(false));
        },
        [partnerService, t, matchResultId]
    );

    if (loading) {
        return <Loader />;
    }
    if (!matchDetail) {
        return <div />;
    }

    const branchSeparator = (extraClassNames: string) => (
        <FaArrowRight className={'h-4 w-4 mt-1 text-gray-500 ' + extraClassNames} />
    );

    const bedrijfDetails = enableDetails ? (
        <div className='grid grid-cols-2 gap-4'>
            <div>
                <div className='font-medium'>{t('partner:matchDetails.bedrijf')}</div>
                <div data-testid='company-company'>{matchDetail.bedrijfsprofiel.bedrijfsnaam}</div>
                <div data-testid='company-address'>{matchDetail.bedrijfsprofiel.adres}</div>
                <div data-testid='company-zipcode_city_country'>
                    {matchDetail.bedrijfsprofiel.postcode} {matchDetail.bedrijfsprofiel.stad}{' '}
                    {t(`country.${matchDetail.bedrijfsprofiel.land}`)}
                </div>
                {matchDetail.bedrijfsprofiel.website && (
                    <ExternalLink
                        dataTestId='company-website'
                        link={matchDetail.bedrijfsprofiel.website}
                        linkText={matchDetail.bedrijfsprofiel.website}></ExternalLink>
                )}
            </div>
            <div>
                <div className='font-medium'>{t('partner:matchDetails.contact')}</div>
                <div data-testid='company-contact'>
                    {matchDetail.contactgegevens?.voornaam} {matchDetail.contactgegevens?.achternaam}
                </div>
                {matchDetail.contactgegevens?.email && (
                    <ExternalLink
                        dataTestId='company-email'
                        link={'mailto:' + matchDetail.contactgegevens.email}
                        linkText={matchDetail.contactgegevens.email}></ExternalLink>
                )}
                <div>{matchDetail.contactgegevens?.telefoonnummer}</div>
            </div>
        </div>
    ) : (
        <div>
            <div className='font-medium'>{t('partner:matchDetails.postcode')}</div>
            <div data-testid='company-zipcode'>{matchDetail.bedrijfsprofiel.postcode}</div>
        </div>
    );

    return (
        <div className='space-y-4'>
            <div className='flex justify-between items-center'>
                <h1 className='text-2xl font-medium'>
                    {t('partner:matchDetails.match')} {matchDetail.partnerMatch.vendor}
                    {' - '}
                    {matchDetail.partnerMatch.productNaam}
                    {matchDetail.partnerMatch.productEigenNaam ? (
                        <span className='block text-sm'>{matchDetail.partnerMatch.productEigenNaam}</span>
                    ) : (
                        ''
                    )}
                </h1>
                <div className='text-xl'>
                    {t('partner:matchDetails.match')}{' '}
                    <span className='text-primary font-bold '>{Math.trunc(matchDetail.rate)}%</span>
                </div>
            </div>
            {meetingProposals && (
                <MatchMeeting
                    matchResultId={matchResultId}
                    matchStatus={matchDetail.status!!}
                    meetingDetails={matchDetail.kennismaking}
                    meetingProposals={meetingProposals}
                    acceptProposal={acceptProposal}
                    rejectProposal={showMatchDeclineConfirmation}
                    alternativeProposal={handleMeetingSubmit}
                />
            )}
            {matchDeclineConfirmation && (
                <MatchDeclineConfirmationModal
                    matchResultId={matchResultId}
                    onCancel={() => clearMatchDeclineConfirmation()}
                    onReject={() => navigate(-1)}
                />
            )}
            <div className='p-4 rounded border border-gray-200 space-y-4'>
                <h2 className='text-xl'>{t('partner:matchDetails.detailsTitle')}</h2>
                <div>
                    <div className='font-medium'>{t('partner:matchDetails.bedrijfsactiviteiten')}</div>
                    <div>
                        {matchDetail.bedrijfsactiviteiten.map(ba => (
                            <div className='flex' key={ba.sector + ba.branche + ba.subbranche}>
                                {ba.sector} {branchSeparator('mx-1')} {ba.branche} {branchSeparator('mx-1')}{' '}
                                {ba.subbranche}
                            </div>
                        ))}
                    </div>
                </div>
                <div>
                    <div className='font-medium'>{t('partner:matchDetails.beschrijving')}</div>
                    <div>{matchDetail.bedrijfsprofiel.beschrijving}</div>
                </div>
                {bedrijfDetails}
                <div className='grid grid-cols-2 gap-4'>
                    <div>
                        <div className='font-medium'>{t('partner:matchDetails.implementatieBudget')}</div>
                        <div>€{numberFmt.format(matchDetail.implementatieBudget)}</div>
                    </div>
                    <div>
                        <div className='font-medium'>{t('partner:matchDetails.maandelijksBudget')}</div>
                        <div>€{numberFmt.format(matchDetail.maandelijksBudget)}</div>
                    </div>
                    <div>
                        <div className='font-medium'>{t('partner:matchDetails.maandenTotImplementatie')}</div>
                        <div data-testId='maanden-implementatie'>{matchDetail.maandenTotImplementatie ?? '-'}</div>
                    </div>
                    <div>
                        <div className='font-medium'>{t('partner:matchDetails.maandenTotBeslissing')}</div>
                        <div data-testId='maanden-beslissing'>{matchDetail.maandenTotBeslissing ?? '-'}</div>
                    </div>
                    <div>
                        <div className='font-medium'>{t('partner:matchDetails.aantalGebruikers')}</div>
                        <div>{matchDetail.bedrijfsprofiel.aantalGebruikers}</div>
                    </div>
                    <div>
                        <div className='font-medium'>{t('partner:matchDetails.aantalMedewerkers')}</div>
                        <div>{matchDetail.bedrijfsprofiel.aantalMedewerkers}</div>
                    </div>
                </div>
                <div>
                    <div className='font-medium'>{t('partner:matchDetails.functionaliteiten')}</div>
                    <div className='grid grid-cols-2 gap-5'>
                        {matchDetail.functionaliteiten.map(functionaliteitGroep => (
                            <MatchDetailFunctionaliteitGroep
                                key={functionaliteitGroep.naam}
                                functionaliteitGroep={functionaliteitGroep}
                                showRequested={true}
                            />
                        ))}
                    </div>
                </div>
                <div>
                    <Accordion label={t('partner:matchDetails.vragenEnAntwoorden')}>
                        <div className='p-4 space-y-4'>
                            <div className='font-medium'>
                                {t('partner:matchDetails.bedrijfsactiviteitVraaggroepen')}
                            </div>
                            {matchDetail.bedrijfsactiviteitVraaggroepen
                                .filter(vg => vg.vragenEnAntwoorden.length > 0)
                                .map(vg => (
                                    <MatchDetailVraaggroepVragen key={vg.naam} vraaggroep={vg} />
                                ))}
                            <div className='font-medium'>{t('partner:matchDetails.functionaliteitVraaggroepen')}</div>
                            {matchDetail.functionaliteitVraaggroepen
                                .filter(vg => vg.vragenEnAntwoorden.length > 0)
                                .map(vg => (
                                    <MatchDetailVraaggroepVragen key={vg.naam} vraaggroep={vg} />
                                ))}
                        </div>
                    </Accordion>
                </div>
            </div>
        </div>
    );
}
