import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setYupLocale } from '../helpers/i18n';
import Routing from './Routing';

export default function App() {
    const { t } = useTranslation();
    useEffect(() => setYupLocale(t), [t]);

    return (
        <div id='root' data-cy='root-content' className='font-default text-regular accent-primary'>
            <Routing />
        </div>
    );
}
