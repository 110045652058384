import React, { useMemo } from 'react';
import { IntakeVoortgang, PhaseState, PhaseType } from '../../../../generated/softwarematching-api';

type Props = {
    intakeVoortgang: IntakeVoortgang | null;
};

export default function IntakeVoortgangIndicatorMobile({ intakeVoortgang }: Props) {
    const phases = intakeVoortgang?.phases;

    const percentage = useMemo(() => {
        const totalPhases = phases?.length ?? 1;
        const completedPhases = phases?.filter(it => it.state === PhaseState.COMPLETED).length ?? 0;
        const lastPhase = phases?.some(
            it => it.phaseType === PhaseType.AFSLUITING && it.state === PhaseState.IN_PROGRESS
        );
        return lastPhase ? 100 : (completedPhases / totalPhases) * 100;
    }, [phases]);

    return (
        <div className='lg:hidden bg-faded rounded-lg overflow-hidden mb-2'>
            <div className='bg-primary-ultraLight h-2' style={{ width: `${percentage}%` }} />
        </div>
    );
}
