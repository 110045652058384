import { ErrorMessage } from 'formik';
import React, { ReactNode } from 'react';

type Props = {
    name: string;
};

export default function FormFieldError({ name }: Props) {
    return (
        <ErrorMessage
            name={name}
            render={(errorMessage: ReactNode) => {
                return (
                    <div data-testid={`error-${name}`} className='text-warning mt-1 block'>
                        {errorMessage}
                    </div>
                );
            }}
        />
    );
}
