import React, { ReactNode } from 'react';
import IntakeHeader from '../components/IntakeHeader';

type Props = {
    children: ReactNode;
    dataTestId?: string;
};

export default function Page({ children, dataTestId }: Props) {
    return (
        <div className='flex flex-col min-max-h-dynamic-screen' data-testid={dataTestId}>
            <IntakeHeader />
            <div className='flex flex-col grow min-h-[0] container mx-auto p-4'>{children}</div>
        </div>
    );
}
