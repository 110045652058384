import { useField } from 'formik';
import React from 'react';
import FormLabel from './FormLabel';
import FormFieldError from './FormFieldError';

type Props = {
    name: string;
    label: string;
    autoFocus?: boolean;
    required?: boolean;
    disabled?: boolean;
    min?: number;
    max?: number;
};

export default function FormFieldCurrency({ name, label, autoFocus, required, disabled, min, max }: Props) {
    const [field, meta] = useField(name);

    return (
        <div>
            <FormLabel htmlFor={name} label={label} required={required} />
            <div className='relative mt-1'>
                <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                    <span>€</span>
                </div>
                <input
                    className={`block w-full rounded-md shadow-sm border-0 p-2 pl-7 text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-light leading-6 ${
                        meta.touched && meta.error ? 'form-field-invalid' : ''
                    }`}
                    id={name}
                    data-testid={name}
                    type='number'
                    inputMode='numeric'
                    aria-describedby={'price-currency'}
                    autoFocus={autoFocus}
                    required={required}
                    readOnly={disabled}
                    min={min}
                    max={max}
                    {...field}
                    placeholder={'0'}
                    value={field.value ?? ''} // Handle null values from the backend
                />
            </div>
            <FormFieldError name={name} />
        </div>
    );
}
