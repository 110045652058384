import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '../../../common/layout/NavigationMenu';
import { FaRegClipboard, FaRegUser, FaUsers } from 'react-icons/fa';
import { Outlet } from 'react-router-dom';
import Role from '../../../models/Role';
import AdminHeader from '../components/AdminHeader';
import AdminContent from '../AdminContent';
import AdminPageWithMenu from './AdminPageWithMenu';

export function AdminLayout() {
    const { t } = useTranslation();

    const menuItems: MenuItem[] = [
        { name: t('partner:menuItems.profile'), icon: <FaRegUser />, to: 'profiel', roles: [Role.PartnerAdmin] },
        { name: t('partner:menuItems.matches'), icon: <FaRegClipboard />, to: 'matches', roles: [Role.PartnerAdmin] },
        { name: t('partner:menuItems.partners'), icon: <FaUsers />, to: 'partners', roles: [Role.Admin] },
    ];

    return (
        <AdminPageWithMenu header={<AdminHeader />} dataTestId='admin-page' menuItems={menuItems}>
            <AdminContent>
                <article className='p-4 w-full'>
                    <Outlet />
                </article>
            </AdminContent>
        </AdminPageWithMenu>
    );
}
