import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';

export default function MatchProposalCompleted() {
    const { t } = useTranslation();
    const auth = useAuth();
    const userProfile = auth.isAuthenticated ? auth.user?.profile : undefined;

    return (
        <div className='flex flex-col items-center p-8' data-testid='matchproposal-completed'>
            <Link to='/portal'>
                <img
                    src='/SoftwareMatchingLogo.svg'
                    className='max-h-20 lg:max-h-32'
                    alt={t('logoSoftwareMatchingIo')}
                />
            </Link>
            <h4 className='pt-11 portal-heading-4'>{t('matchProposalCompleted.title')}</h4>
            <div className='portal-body--large pt-5'>
                {t('matchProposalCompleted.text', { name: userProfile?.given_name })}
            </div>
        </div>
    );
}
