import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaExclamation, FaTimes } from 'react-icons/fa';
import { IntakeMatchFunctionaliteit } from '../../../generated/softwarematching-api';

type Props = {
    functionaliteit: IntakeMatchFunctionaliteit;
    showRequested?: boolean;
};

export default function MatchDetailFunctionaliteit({ functionaliteit, showRequested = false }: Props) {
    const { t } = useTranslation();

    const coverageIcon = () => {
        const classNames = 'mr-2 w-4 h-4 mt-1';
        switch (functionaliteit.coverage) {
            case 'COVERED':
                return (
                    <FaCheck
                        className={classNames + ' text-primary'}
                        data-testid='completely_covered'
                        title={t('matchSelection.functionaliteitCoverage.covered')}
                    />
                );
            case 'PARTLY':
                return (
                    <FaExclamation
                        className={classNames + ' text-secondary'}
                        data-testid='partly_covered'
                        title={t('matchSelection.functionaliteitCoverage.partly')}
                    />
                );
            case 'NOT':
                return (
                    <FaTimes
                        className={classNames + ' text-secondary'}
                        data-testid='not_covered'
                        title={t('matchSelection.functionaliteitCoverage.not')}
                    />
                );
        }
    };

    return (
        <div className='flex portal-body--small' data-testid={'functionaliteit_' + functionaliteit.naam}>
            {coverageIcon()} {functionaliteit.naam}
            {showRequested && (
                <span className='text-gray-500 pl-3'>{'(' + t(`moscow.${functionaliteit.requested}`) + ')'}</span>
            )}
        </div>
    );
}
