import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Bedrijfsactiviteit,
    Bedrijfsprofiel,
    ChosenSoftwareType,
    Functionaliteitwens,
    IntakeAntwoord,
    IntakeApi,
    IntakeContactgegevens,
    IntakeVoortgang,
    IntakeVraagAntwoord,
    KlantvraagFunctionaliteitGroep,
    Project,
    SoftwareType,
    StartIntakeRequest,
} from '../../../generated/softwarematching-api';
import { useToken } from '../../../hooks/auth-hooks';
import { TokenizedService } from '../../../hooks/TokenizedService';

const INTAKE_KLANTVRAAG_ID_KEY = 'INTAKE_KLANTVRAAG_ID';

export default function useIntakeService() {
    const { i18n } = useTranslation();
    const token = useToken();
    const intakeService = useMemo(() => new IntakeService(i18n.language), [i18n.language]);
    useEffect(() => intakeService.updateToken(token), [token, intakeService]);
    return intakeService;
}

export class IntakeService extends TokenizedService<IntakeApi> {
    public constructor(language: string) {
        super(IntakeApi, language);
    }

    getKlantvraagId(): string | null {
        return localStorage.getItem(INTAKE_KLANTVRAAG_ID_KEY);
    }

    setKlantvraagId(klantvraagId: string) {
        localStorage.setItem(INTAKE_KLANTVRAAG_ID_KEY, klantvraagId);
    }

    startIntake(startIntakeRequest: StartIntakeRequest): Promise<IntakeVoortgang> {
        return this.api.startIntake(startIntakeRequest).then(response => response.data);
    }

    getIntakeVoortgang(klantvraagId: string): Promise<IntakeVoortgang> {
        return this.api.getIntakeVoortgang(klantvraagId).then(response => response.data);
    }

    getBedrijfsactiviteiten(klantvraagId: string): Promise<Bedrijfsactiviteit[]> {
        return this.api.getBedrijfsactiviteitenHierarchy(klantvraagId).then(response => response.data);
    }

    getChosenBedrijfsactiviteiten(klantvraagId: string): Promise<Bedrijfsactiviteit[]> {
        return this.api.getChosenBedrijfsactiviteiten(klantvraagId).then(response => response.data);
    }

    postBedrijfsactiviteiten(klantvraagId: string, bedrijfsactiviteitIds: string[]): Promise<IntakeVoortgang> {
        return this.api
            .postBedrijfsactiviteiten(klantvraagId, { bedrijfsactiviteitIds })
            .then(response => response.data);
    }

    getIntakeVraagAntwoord(klantvraagId: string, vraagId: string): Promise<IntakeVraagAntwoord> {
        return this.api.getVraag(klantvraagId, vraagId).then(response => response.data);
    }

    postBedrijfsactiviteitAntwoord(klantvraagId: string, intakeAntwoord: IntakeAntwoord): Promise<IntakeVoortgang> {
        return this.api.postBedrijfsactiviteitAntwoord(klantvraagId, intakeAntwoord).then(response => response.data);
    }

    putBedrijfsactiviteitAntwoord(
        klantvraagId: string,
        vraagId: string,
        intakeAntwoord: IntakeAntwoord
    ): Promise<IntakeVoortgang> {
        return this.api
            .putBedrijfsactiviteitAntwoord(klantvraagId, vraagId, intakeAntwoord)
            .then(response => response.data);
    }

    postFunctionaliteitAntwoord(klantvraagId: string, intakeAntwoord: IntakeAntwoord): Promise<IntakeVoortgang> {
        return this.api.postFunctionaliteitAntwoord(klantvraagId, intakeAntwoord).then(response => response.data);
    }

    putFunctionaliteitAntwoord(
        klantvraagId: string,
        vraagId: string,
        intakeAntwoord: IntakeAntwoord
    ): Promise<IntakeVoortgang> {
        return this.api
            .putFunctionaliteitAntwoord(klantvraagId, vraagId, intakeAntwoord)
            .then(response => response.data);
    }

    postProject(
        klantvraagId: string,
        implementatieBudget?: number | null,
        maandelijksBudget?: number | null,
        beoogdeAantalMaandenTotImplementatie?: number | null,
        beoogdeAantalMaandenTotBeslissing?: number | null
    ): Promise<IntakeVoortgang> {
        return this.api
            .postProject(klantvraagId, {
                implementatieBudget,
                maandelijksBudget,
                beoogdeAantalMaandenTotImplementatie,
                beoogdeAantalMaandenTotBeslissing,
            })
            .then(response => response.data);
    }

    getProject(klantvraagId: string): Promise<Project> {
        return this.api.getProject(klantvraagId).then(response => response.data);
    }

    getFunctionaliteitwensen(klantvraagId: string): Promise<KlantvraagFunctionaliteitGroep[]> {
        return this.api.getFunctionaliteitwensen(klantvraagId).then(response => response.data);
    }

    postFunctionaliteitwensen(klantvraagId: string, wensen: Functionaliteitwens[]): Promise<IntakeVoortgang> {
        return this.api.postFunctionaliteitwensen(klantvraagId, wensen).then(response => response.data);
    }

    putFunctionaliteitwensen(klantvraagId: string, wensen: Functionaliteitwens[]): Promise<IntakeVoortgang> {
        return this.api.putFunctionaliteitwensen(klantvraagId, wensen).then(response => response.data);
    }

    postBedrijfsprofiel(klantvraagId: string, bedrijfsprofiel: Bedrijfsprofiel): Promise<IntakeVoortgang> {
        return this.api.postBedrijfsprofiel(klantvraagId, bedrijfsprofiel).then(response => response.data);
    }

    getBedrijfsprofiel(klantvraagId: string): Promise<Bedrijfsprofiel> {
        return this.api.getBedrijfsprofiel(klantvraagId).then(response => response.data);
    }

    postContactgegevens(klantvraagId: string, contactgegevens: IntakeContactgegevens): Promise<IntakeVoortgang> {
        return this.api.postContactgegevens(klantvraagId, contactgegevens).then(response => response.data);
    }

    getIntakeContactgegevens(klantvraagId: string): Promise<IntakeContactgegevens> {
        return this.api.getIntakeContactgegevens(klantvraagId).then(response => response.data);
    }

    getSoftwareTypes(): Promise<SoftwareType[]> {
        return this.api.getSoftwareTypes().then(response => response.data);
    }

    getSoftwareType(klantvraagId: string): Promise<ChosenSoftwareType> {
        return this.api.getSoftwareType(klantvraagId).then(response => response.data);
    }

    postSoftwareType(klantvraagId: string, softwareTypeId: string): Promise<IntakeVoortgang> {
        return this.api
            .postSoftwareType(klantvraagId, { softwareTypeId: softwareTypeId })
            .then(response => response.data);
    }
}
