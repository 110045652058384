import { useEffect } from 'react';
import useIntakeService from './useIntakeService';
import { useToken } from '../../../hooks/auth-hooks';
import useProtectedService from './useProtectedService';

export default function useLinkUserIdToKlantvraag() {
    const intakeService = useIntakeService();
    const protectedService = useProtectedService();
    const token = useToken();

    useEffect(() => {
        const klantvraagId = intakeService.getKlantvraagId();
        if (klantvraagId && token) {
            protectedService.postAanmelding(klantvraagId);
        }
    }, [token, intakeService, protectedService]);
}
