import React, { ReactNode } from 'react';
import { FaTimes } from 'react-icons/fa';

type Props = {
    children: ReactNode;
    onClose: () => void;
};

export default function ModalHeader({ children, onClose }: Props) {
    return (
        <div className='border-b p-4 flex justify-between gap-2'>
            <div>{children}</div>
            <FaTimes
                className='admin-icon hover:fill-black transition-all text-lg'
                style={{ color: 'lightslategray' }}
                data-testid='exit-button'
                onClick={onClose}
            />
        </div>
    );
}
