import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';

type Props = {
    disabled?: boolean;
    label?: string;
};

export default function ResetButton({ label, disabled }: Props) {
    const { t } = useTranslation();

    return (
        <Button type='reset' variant='outlined' color='primary' dataTestId='reset-button' disabled={disabled}>
            {label ?? t('common:reset')}
        </Button>
    );
}
