import React from 'react';
import PartnerPageHeader from '../components/PartnerPageHeader';
import ProductAanbodList from '../components/ProductAanbodList';
import { useAuthPartnerId } from '../../../hooks/auth-hooks';
import UnauthorizedPage from '../../../common/page/UnauthorizedPage';

export default function ProfielAanbodPage() {
    const partnerId = useAuthPartnerId();

    if (!partnerId) {
        return <UnauthorizedPage />;
    }

    return (
        <>
            <PartnerPageHeader></PartnerPageHeader>
            <ProductAanbodList partnerId={partnerId}></ProductAanbodList>
        </>
    );
}
