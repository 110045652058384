import React from 'react';
import { useTranslation } from 'react-i18next';
import Tabs from '../../../common/tabs/Tabs';

export default function PartnerPageHeader() {
    const { t } = useTranslation();
    const tabs = [
        { title: t('partner:profile.tabGeneral'), link: '../algemeen' },
        { title: t('partner:gebruiker.title'), link: '../gebruikers' },
        { title: t('partner:aanbod.title'), link: '../aanbod' },
    ];

    return (
        <>
            <Tabs tabs={tabs}></Tabs>
        </>
    );
}
