import { useFormikContext } from 'formik';
import { MatchSelectionFormValues } from '../pages/SelectMatches';
import { useState, useEffect } from 'react';
export function UseMatchSelection(matches: any[]) {
    const { values, submitForm } = useFormikContext<MatchSelectionFormValues>();
    const [isEnabled, setIsEnabled] = useState(false);
    const [isEnabledMin, setIsEnabledMin] = useState(false);
    const [isEnabledMax, setIsEnabledMax] = useState(false);

    useEffect(() => {
        const min = values.selected.length >= 1 + matches.filter(m => m.status !== 'MATCHED').length;
        const max = values.selected.length <= 5 + matches.filter(m => m.status === 'REJECTED').length;
        setIsEnabledMin(min);
        setIsEnabledMax(max);
        setIsEnabled(min && max);
    }, [values.selected, matches]);

    const handleSubmit = () => {
        if (isEnabled) {
            submitForm();
        }
    };

    return { isEnabled, handleSubmit, isEnabledMin, isEnabledMax, values };
}
