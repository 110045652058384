import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FunctionaliteitApi, Functionaliteitgroep } from '../../../generated/softwarematching-api';
import { useToken } from '../../../hooks/auth-hooks';
import { TokenizedService } from '../../../hooks/TokenizedService';

export default function useFunctionaliteitService() {
    const token = useToken();
    const { i18n } = useTranslation();
    const functionaliteitService = useMemo(() => new FunctionaliteitService(i18n.language), [i18n.language]);
    useEffect(() => functionaliteitService.updateToken(token), [token, functionaliteitService]);
    return functionaliteitService;
}

export class FunctionaliteitService extends TokenizedService<FunctionaliteitApi> {
    public constructor(language: string) {
        super(FunctionaliteitApi, language);
    }

    getFunctionaliteiten(): Promise<Functionaliteitgroep[]> {
        return this.api.getFunctionaliteiten().then(response => response.data);
    }
}
