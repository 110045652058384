import { MeetingDetails } from '../../../generated/softwarematching-api';
import { formatTimeslot } from '../../../helpers/meeting';
import React, { useCallback, useState } from 'react';
import { useDateTimeFormatter } from '../../../hooks/useDateTimeFormatter';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/button/Button';
import useProtectedService from '../../intake/hooks/useProtectedService';
import { showErrorResponse } from '../../../helpers/notify';
import Loader from '../../../common/Loader';

type Props = {
    matchResultId: string;
    meeting: MeetingDetails;
    onAccepted: () => void;
};

export default function MatchMeetingProposal({ matchResultId, meeting, onAccepted }: Props) {
    const protectedService = useProtectedService();
    const { t, i18n } = useTranslation();
    const { formatDayOfWeek, formatDate } = useDateTimeFormatter(i18n.resolvedLanguage);
    const [loading, setLoading] = useState(false);

    const doAccept = useCallback(
        (acceptTimeSlot: boolean) => {
            setLoading(true);
            protectedService
                .acceptProposedMeeting(matchResultId, acceptTimeSlot)
                .then(() => onAccepted())
                .catch(error => showErrorResponse(t('common:failedToUpdate'), error, t))
                .finally(() => setLoading(false));
        },
        [matchResultId, onAccepted, protectedService, t]
    );

    if (loading) {
        return <Loader />;
    }

    return (
        <div>
            <h2 className='portal-heading-2 mb-3'>{t('matchDetails.voorstelTitle')}</h2>
            <div className='font-medium'>
                {formatDayOfWeek(meeting.meetingDate!)} {formatDate(meeting.meetingDate!)}
            </div>
            <div>{formatTimeslot(meeting.meetingTimeslot!)}</div>
            <div>{t(`matchMeeting.location.${meeting.location!.toLowerCase()}`)}</div>
            <div className='mt-3 flex flex-col md:flex-row md:flex-start gap-3'>
                <Button variant='contained' color='secondary' onClick={() => doAccept(true)} dataTestId='btn-accept'>
                    {t('matchDetails.acceptButton')}
                </Button>
                <Button variant='outlined' color='secondary' onClick={() => doAccept(false)} dataTestId='btn-reject'>
                    {t('matchDetails.rejectButton')}
                </Button>
            </div>
        </div>
    );
}
