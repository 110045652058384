import { FieldMetaProps } from 'formik';
import React from 'react';

type Props = {
    meta: FieldMetaProps<any>;
    name: string;
};

/**
 * @deprecated This component should not be used. Use the new FormFieldError component
 */
export default function FormError({ meta, name }: Props) {
    if (!meta.touched || !meta.error) {
        return null;
    }

    return (
        <div data-testid={`error-${name}`} className='text-warning mt-1 block'>
            {meta.error}
        </div>
    );
}
