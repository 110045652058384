import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AdminLayout } from './layouts/AdminLayout';
import PartnersPage from './pages/PartnersPage';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { User } from 'oidc-client-ts';
import AppRequiredAuthentication from '../../common/AppRequiredAuthentication';
import ProtectedRoute from '../../common/ProtectedRoute';
import Role from '../../models/Role';
import PartnersAlgemeenPage from './pages/PartnersAlgemeenPage';
import PartnersGebruikersPage from './pages/PartnersGebruikersPage';
import ProfielAlgemeenPage from './pages/ProfielAlgemeenPage';
import ProfielGebruikersPage from './pages/ProfielGebruikersPage';
import AanbodAddPage from './pages/AanbodAddPage';
import AanbodEditPage from './pages/AanbodEditPage';
import ProfielAanbodPage from './pages/ProfielAanbodPage';
import PartnersAanbodPage from './pages/PartnersAanbodPage';
import { useAuthRoles } from '../../hooks/auth-hooks';
import PartnerMatchesPage from './pages/PartnerMatchesPage';
import { PartnerMatchDetailsPage } from './pages/PartnerMatchDetailsPage';

const oidcConfig: AuthProviderProps = {
    authority: '/auth/realms/' + window.env.REACT_APP_KEYCLOAK_ADMIN_REALM,
    client_id: window.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    redirect_uri: window.location.href,
    onSigninCallback: (user: User | void) => {
        window.history.replaceState({}, document.title, window.location.pathname);
    },
};
export default function AdminRouting() {
    return (
        <AuthProvider {...oidcConfig}>
            <AppRequiredAuthentication>
                <AdminRoutes />
            </AppRequiredAuthentication>
        </AuthProvider>
    );
}

function AdminRoutes() {
    const { hasRole } = useAuthRoles();

    const isAdmin = hasRole(Role.Admin);

    return (
        <Routes>
            <Route element={<AdminLayout />}>
                <Route path='*'></Route>
                <Route
                    path='/'
                    element={isAdmin ? <Navigate to='/admin/partners' /> : <Navigate to='/admin/matches' />}
                />
                <Route element={<ProtectedRoute roles={[Role.PartnerAdmin]} />}>
                    <Route path='profiel' element={<Navigate to='algemeen' />} />
                    <Route path='profiel/algemeen' element={<ProfielAlgemeenPage />} />
                    <Route path='profiel/gebruikers' element={<ProfielGebruikersPage />} />
                    <Route path='profiel/aanbod' element={<ProfielAanbodPage />} />
                    <Route path='matches' element={<PartnerMatchesPage />} />
                    <Route path='matches/:matchResultId' element={<PartnerMatchDetailsPage />} />
                </Route>
                <Route element={<ProtectedRoute roles={[Role.Admin]} />}>
                    <Route path='partners' element={<PartnersPage />} />
                    <Route path='partners/:partnerId' element={<Navigate to='algemeen' />} />
                    <Route path='partners/:partnerId/algemeen' element={<PartnersAlgemeenPage />} />
                    <Route path='partners/:partnerId/gebruikers' element={<PartnersGebruikersPage />} />
                    <Route path='partners/:partnerId/aanbod' element={<PartnersAanbodPage />} />
                </Route>
                <Route element={<ProtectedRoute roles={[Role.Admin, Role.PartnerAdmin]} />}>
                    <Route path='partners/:partnerId/aanbod/new' element={<AanbodAddPage />} />
                    <Route path='partners/:partnerId/aanbod/:aanbiedingId' element={<AanbodEditPage />} />
                </Route>
            </Route>
        </Routes>
    );
}
