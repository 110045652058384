import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';

type Props = {
    disabled?: boolean;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export default function CancelButton({ disabled, onClick }: Props) {
    const { t } = useTranslation();

    return (
        <Button variant='outlined' color='primary' disabled={disabled} dataTestId='cancel-button' onClick={onClick}>
            {t('common:cancel')}
        </Button>
    );
}
