import { TFunction } from 'i18next';
import { toast } from 'react-toastify';

export function showInfo(message: string) {
    toast(message, { type: 'info' });
}

export function showSuccess(message: string) {
    toast(message, { type: 'success' });
}

export function showWarning(message: string) {
    toast(message, { type: 'warning' });
}

export function showError(title: string, message: string | null = null) {
    const content = message ? toastContent(title, message) : title;
    toast(content, { type: 'error' });
}

export function showErrorResponse(title: string, error: any, t: TFunction) {
    const message = errorKeyToText(error?.key || error?.response.data.key, t);
    toast(toastContent(title, message), { type: 'error' });
}

function toastContent(title: string, message: string | null) {
    return (
        <>
            <p>
                <strong>{title}</strong>
            </p>
            {message && <p className='whitespace-pre-line'>{message}</p>}
        </>
    );
}

export function errorKeyToText(errorKey: any, t: TFunction): string {
    return t([`common:error.${errorKey}`, 'common:error.UNKNOWN']);
}
