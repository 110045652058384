import * as React from 'react';
import { IntakeMatch } from '../../../generated/softwarematching-api';
import { LiaTimesSolid } from 'react-icons/lia';

type Props = {
    match: IntakeMatch;
    onRemoveMatch?: (id: string) => void;
};

export function SummarySelectedMatch({ match, onRemoveMatch }: Props) {
    const removeButton =
        onRemoveMatch && match.status === 'MATCHED' ? (
            <div className='flex items-center justify-center'>
                <button type='button' className='cursor-pointer' onClick={() => onRemoveMatch(match.matchResultId)}>
                    <LiaTimesSolid className='w-4 h-4 text-gray-500' />
                </button>
            </div>
        ) : null;

    return (
        <div className='p-2 bg-white rounded-md border border-gray-300 flex content-between'>
            <div className='flex-grow'>
                <div className='portal-body--large'>{match.partner.name}</div>
            </div>
            {removeButton}
        </div>
    );
}
