import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PartnerPageHeader from '../components/PartnerPageHeader';
import ProductAanbodList from '../components/ProductAanbodList';

export default function PartnersAanbodPage() {
    const { t } = useTranslation();
    const { partnerId } = useParams();

    if (!partnerId) {
        return <div>{t('common:error.REQUEST_MISSING_PARAMETER')}</div>;
    }

    return (
        <>
            <PartnerPageHeader></PartnerPageHeader>
            <ProductAanbodList partnerId={partnerId}></ProductAanbodList>
        </>
    );
}
