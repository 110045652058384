import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';

type Props = {
    disabled?: boolean;
    dataTestId?: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export default function AddButton({ disabled, dataTestId, onClick }: Props) {
    const { t } = useTranslation();

    return (
        <Button
            variant='contained'
            color='primary'
            disabled={disabled}
            dataTestId={dataTestId ?? 'add-button'}
            onClick={onClick}>
            {t('common:add')}
        </Button>
    );
}
