import * as React from 'react';
import { IntakeMatch } from '../../../generated/softwarematching-api';
import { SummarySelectedMatch } from './SummarySelectedMatch';
import { useTranslation } from 'react-i18next';
import { UseMatchSelection } from '../hooks/useMatchSelection';
import { FaArrowRightLong } from 'react-icons/fa6';
import Button from '../../../common/button/Button';

type Props = {
    selectedMatches: IntakeMatch[];
    onRemoveMatch?: (id: string) => void;
};

export default function Summary({ selectedMatches, onRemoveMatch }: Props) {
    const { t } = useTranslation();
    const { isEnabled, handleSubmit } = UseMatchSelection(selectedMatches);

    return (
        <div className='px-6 py-5 bg-gray-200 rounded-xl' data-testid='summary'>
            <h4 className='portal-heading-4'>{t('matchSummary.header')}</h4>
            <div className='mt-5'>
                <h5 className='portal-heading-5'>{t('matchSummary.partners')}</h5>
                <p className='portal-body--small mt-3'>
                    {t('matchSummary.selected', { count: selectedMatches.length })}
                </p>
                {selectedMatches.length > 0 && (
                    <div className='flex flex-col gap-1 mt-3'>
                        {selectedMatches.map(m => (
                            <SummarySelectedMatch key={m.matchResultId} match={m} onRemoveMatch={onRemoveMatch} />
                        ))}
                    </div>
                )}
            </div>
            <p className='portal-heading-6 pt-5'>
                <strong>{t('matchSummary.advice.title')}</strong>
                {t('matchSummary.advice.adviceMessage')}
            </p>

            <Button
                type='button'
                variant='contained'
                color={isEnabled ? 'send' : 'deselect'}
                className='w-full mt-5 rounded-3xl'
                dataTestId='summary-send-button'
                onClick={handleSubmit}
                disabled={!isEnabled}>
                {t('matchSelection.selectMatchStepperTitle')}{' '}
                <FaArrowRightLong className='text-white inline ml-1'></FaArrowRightLong>
            </Button>
        </div>
    );
}
