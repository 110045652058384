import React, { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Role from '../../models/Role';
import { useAuth } from 'react-oidc-context';
import { useAuthRoles, useAuthWrapper } from '../../hooks/auth-hooks';
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter';
import { Link } from 'react-router-dom';

export default function HeaderUserProfile() {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, () => setShowMenu(false));
    const auth = useAuth();
    const { logout } = useAuthWrapper();
    const { hasRole } = useAuthRoles();
    const userProfile = auth.isAuthenticated ? auth.user?.profile : undefined;
    const isAdmin = hasRole(Role.Admin);
    const isPartner = hasRole(Role.PartnerAdmin);

    if (userProfile) {
        let menuButtons: ReactNode[] = [];
        if (!isAdmin && !isPartner) {
            menuButtons = [
                <Link key='intakes' className='block px-4 py-2 text-sm text-gray-700' to='/portal/intakes'>
                    {t('intake.intakes')}
                </Link>,
            ];
        }

        return (
            <div className='relative'>
                <span
                    className='inline-flex h-10 w-10 lg:h-12 lg:w-12 items-center justify-center rounded-full bg-primary hover:bg-primary-light cursor-pointer'
                    data-testid='user-profile'
                    onClick={() => setShowMenu(!showMenu)}>
                    <span className='font-medium leading-none text-white'>
                        {userProfile.name?.charAt(0)?.toUpperCase()}
                    </span>
                </span>

                {showMenu && (
                    <div
                        className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                        role='menu'
                        ref={wrapperRef}>
                        {menuButtons}
                        <button
                            onClick={() => logout()}
                            className='block px-4 py-2 text-sm text-gray-700 text-left w-full'
                            data-testid='logout-button'>
                            {t('common:logout')}
                        </button>
                    </div>
                )}
            </div>
        );
    }

    return null;
}
