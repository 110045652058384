import { useField } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { OnChangeValue } from 'react-select/dist/declarations/src/types';
import FormLabel from './FormLabel';
import FormFieldError from './FormFieldError';

export interface SelectOption {
    value: string;
    label: string;
}

type Props = {
    name: string;
    label: string;
    options: SelectOption[];
    autoFocus?: boolean;
    clearable?: boolean;
    disabled?: boolean;
    multiple?: boolean;
    required?: boolean;
    searchable?: boolean;
    closeMenuOnSelect?: boolean;
    minMenuHeight?: number;
    menuPosition?: 'absolute' | 'fixed';
    dataTestId?: string;
};

export default function FormFieldSelect({
    name,
    label,
    options,
    autoFocus,
    clearable,
    disabled,
    multiple,
    required,
    searchable,
    closeMenuOnSelect = true,
    minMenuHeight = 150,
    menuPosition = 'absolute',
    dataTestId,
}: Props) {
    const { t } = useTranslation();
    const [field, meta, helper] = useField(name);

    const handleChange = useCallback(
        (e: OnChangeValue<any, any>) => {
            const value = Array.isArray(e) ? e.map(i => i?.value) : e?.value ?? null;
            helper.setValue(value, true); // shouldValidate=true to trigger validation of the form (instead of onBlur)
        },
        [helper]
    );

    const value = useMemo(
        () =>
            Array.isArray(field.value)
                ? options.filter(it => field.value.includes(it.value))
                : options.find(it => it.value === field.value) ?? null,
        [options, field.value]
    );

    const noOptionsMessage = useCallback(() => t('common:noOptions'), [t]);

    return (
        <div data-testid={dataTestId}>
            <FormLabel htmlFor={name} label={label} required={required} />
            <Select
                className={`form-field form-field-select mt-1 ${
                    meta.touched && meta.error ? 'form-field-invalid' : ''
                }`}
                blurInputOnSelect={closeMenuOnSelect}
                closeMenuOnSelect={closeMenuOnSelect}
                inputId={name}
                autoFocus={autoFocus}
                isClearable={clearable}
                isDisabled={disabled}
                isMulti={multiple}
                isSearchable={searchable}
                name={field.name}
                options={options}
                placeholder={label}
                value={value}
                noOptionsMessage={noOptionsMessage}
                onBlur={() => helper.setTouched(false)} // shouldValidate=false to handle weird behaviour of wrong validation messages of valid fields
                onChange={handleChange}
                minMenuHeight={minMenuHeight}
                menuPosition={menuPosition}
            />
            <FormFieldError name={name} />
        </div>
    );
}
