import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';

type Props = {
    id?: string;
    disabled?: boolean;
    form?: string;
    label?: string;
};

export default function SubmitButton({ id, disabled, form, label }: Props) {
    const { t } = useTranslation();

    return (
        <Button
            id={id}
            type='submit'
            variant='contained'
            color='primary'
            dataTestId='submit-button'
            disabled={disabled}
            form={form}>
            {label ?? t('common:next')}
        </Button>
    );
}
