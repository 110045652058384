import { useField } from 'formik';
import React from 'react';
import FormLabel from './FormLabel';
import FormFieldError from './FormFieldError';

type Props = {
    name: string;
    label: string;
    autoFocus?: boolean;
    required?: boolean;
    disabled?: boolean;
    min?: number;
    max?: number;
};

export default function FormFieldNumber({ name, label, autoFocus, required, disabled, min, max }: Props) {
    const [field, meta] = useField(name);

    return (
        <div>
            <FormLabel htmlFor={name} label={label} required={required} />
            <input
                className={`block w-full mt-1 rounded-md shadow-sm border-0 p-2 text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-light leading-6 ${
                    meta.touched && meta.error ? 'form-field-invalid' : ''
                }`}
                id={name}
                type='number'
                autoFocus={autoFocus}
                inputMode='numeric'
                required={required}
                readOnly={disabled}
                min={min}
                max={max}
                {...field}
                value={field.value ?? ''} // Handle null values from the backend
            />
            <FormFieldError name={name} />
        </div>
    );
}
