import { useField } from 'formik';
import React from 'react';
import FormError from './FormError';
import FormLabel from './FormLabel';

type Props = {
    name: string;
    label: string;
    autocomplete?: string;
    autoFocus?: boolean;
    disabled?: boolean;
    list?: string;
    maxLength?: number;
    minLength?: number;
    required?: boolean;
    placeholder?: string;
    type: 'text' | 'email' | 'password' | 'tel' | 'url';
    inputMode?: 'none' | 'decimal' | 'numeric' | 'tel' | 'email' | 'url';
};

export default function FormFieldText({
    name,
    label,
    autocomplete,
    autoFocus,
    disabled,
    list,
    maxLength,
    minLength,
    required,
    placeholder,
    type,
    inputMode,
}: Props) {
    const [field, meta] = useField(name);

    return (
        <div>
            <FormLabel htmlFor={name} label={label} required={required} />
            <div>
                <input
                    className={`block w-full rounded-md border-0 mt-1 p-2 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 ${
                        meta.touched && meta.error ? 'form-field-invalid' : ''
                    }
                    ${disabled ? 'bg-gray-300 cursor-not-allowed' : ''}
                    `}
                    id={name}
                    autoComplete={autocomplete}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    list={list}
                    maxLength={maxLength}
                    minLength={minLength}
                    placeholder={placeholder}
                    required={required}
                    inputMode={inputMode}
                    type={type}
                    {...field}
                    value={field.value || ''} // Handle null values from the backend
                />
            </div>
            <FormError meta={meta} name={name} />
        </div>
    );
}
