import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { BrowseDirection } from '../../../../common/Constants';
import { Bedrijfsactiviteit, IntakeVoortgang, IntakeVoortgangItem } from '../../../../generated/softwarematching-api';
import { showErrorResponse } from '../../../../helpers/notify';
import { makeCancellable } from '../../../../helpers/promise';
import useIntakeService from '../../hooks/useIntakeService';
import Loader from '../../../../common/Loader';
import BedrijfsactiviteitKiezenFieldGroup from '../BedrijfsactiviteitKiezenFieldGroup';
import IntakePhase from '../IntakePhase';
import BedrijfsactiviteitenCurrentSelectionForm from '../../../../common/bedrijfsactiviteiten/BedrijfsactiviteitenCurrentSelectionForm';
import { FormikProps } from 'formik/dist/types';

export type ChooseBedrijfactiviteitenValues = {
    sector: string | null;
    branche: string | null;
    subbranches: string[];
    selectedBedrijfsactiviteiten: string[];
};

const defaultValues: ChooseBedrijfactiviteitenValues = {
    sector: null,
    branche: null,
    subbranches: [],
    selectedBedrijfsactiviteiten: [],
};

type Props = {
    intakeVoortgang: IntakeVoortgang;
    currentIntakeVoortgangItem: IntakeVoortgangItem;
    onUpdateIntakeVoortgang: (intakeVoortgang: IntakeVoortgang) => void;
    browse: (direction: BrowseDirection) => void;
};

export default function IntakeBedrijfsactiviteitenKiezen({
    intakeVoortgang,
    currentIntakeVoortgangItem,
    onUpdateIntakeVoortgang,
    browse,
}: Props) {
    const klantvraagId = intakeVoortgang.klantvraagId!!;
    const { t } = useTranslation();
    const intakeService = useIntakeService();

    const [loading, setLoading] = useState(true);
    const [bedrijfsactiviteiten, setBedrijfsactiviteiten] = useState<Bedrijfsactiviteit[]>([]);
    const [initialValues, setInitialValues] = useState<ChooseBedrijfactiviteitenValues>(defaultValues);
    const [showConfirmation, setShowConfirmation] = useState(currentIntakeVoortgangItem.state === 'COMPLETED');
    const formRef = useRef<FormikProps<ChooseBedrijfactiviteitenValues>>(null);

    const handleConfirmation = useCallback(
        (values: ChooseBedrijfactiviteitenValues) => {
            formRef.current?.setFieldValue(
                'selectedBedrijfsactiviteiten',
                formRef.current.values.selectedBedrijfsactiviteiten.concat(values.subbranches)
            );

            setShowConfirmation(true);
        },
        [setShowConfirmation]
    );

    const handleExitConfirmation = useCallback(() => {
        setShowConfirmation(false);
    }, [setShowConfirmation]);

    const equalsCheck = (arr1: string[], arr2: string[]) => {
        return JSON.stringify(arr1) === JSON.stringify(arr2);
    };

    const handleSubmit = useCallback(
        (values: ChooseBedrijfactiviteitenValues) => {
            const selectedBedrijfsactiviteitenIds = values.selectedBedrijfsactiviteiten;
            if (!selectedBedrijfsactiviteitenIds.length) {
                return;
            }

            // Check if bedrijfsactiviteit has been altered
            if (!equalsCheck(initialValues.selectedBedrijfsactiviteiten, values.selectedBedrijfsactiviteiten)) {
                intakeService
                    .postBedrijfsactiviteiten(klantvraagId, selectedBedrijfsactiviteitenIds)
                    .then(onUpdateIntakeVoortgang)
                    .catch(error => showErrorResponse(t('common:failedToAdd'), error, t))
                    .finally(() => setLoading(false));
            } else {
                onUpdateIntakeVoortgang(intakeVoortgang);
            }
        },
        [
            onUpdateIntakeVoortgang,
            klantvraagId,
            intakeService,
            t,
            intakeVoortgang,
            initialValues.selectedBedrijfsactiviteiten,
        ]
    );

    useEffect(() => {
        const [promise, cancel] = makeCancellable(intakeService.getBedrijfsactiviteiten(klantvraagId));
        promise
            .then(setBedrijfsactiviteiten)
            .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t))
            .finally(() => setLoading(false));
        return cancel;
    }, [currentIntakeVoortgangItem, klantvraagId, intakeService, t]);

    useEffect(() => {
        if (currentIntakeVoortgangItem.state === 'COMPLETED') {
            const [promise, cancel] = makeCancellable(
                intakeService.getChosenBedrijfsactiviteiten(intakeVoortgang.klantvraagId)
            );
            promise
                .then(response => {
                    setInitialValues({
                        sector: null,
                        branche: null,
                        subbranches: [],
                        selectedBedrijfsactiviteiten: response.map(value => value.id),
                    });
                })
                .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t));
            return cancel;
        }
    }, [intakeService, intakeVoortgang, t, currentIntakeVoortgangItem]);

    const kiezenValidationSchema = yup.object({
        sector: yup.string().required(),
        branche: yup.string().required(),
        subbranches: yup.array().required().min(1),
    });

    const bevestigenValidationSchema = yup.object({
        selectedBedrijfsactiviteiten: yup.array().required().min(1),
    });

    const bedrijfsActiviteitKiezen = showConfirmation ? (
        <>
            <h2 className='text-2xl font-light mb-4'>{t('intake.bedrijfsactiviteitBevestigenTitle')}</h2>
            <BedrijfsactiviteitenCurrentSelectionForm
                bedrijfsactiviteiten={bedrijfsactiviteiten}
                name='selectedBedrijfsactiviteiten'
                onAdd={() => handleExitConfirmation()}
                compactMode={true}
            />
        </>
    ) : (
        <BedrijfsactiviteitKiezenFieldGroup bedrijfsactiviteiten={bedrijfsactiviteiten ?? []} />
    );

    return (
        <IntakePhase
            title={t('intake.intakePhase.BEDRIJFSACTIVITEIT_KIEZEN')}
            intakeVoortgang={intakeVoortgang}
            currentIntakeVoortgangItem={currentIntakeVoortgangItem}
            showNextButton
            showPreviousButton
            previousCallback={() => (showConfirmation ? browse('previous') : setShowConfirmation(true))}>
            <Formik
                innerRef={formRef}
                initialValues={initialValues}
                enableReinitialize
                validationSchema={showConfirmation ? bevestigenValidationSchema : kiezenValidationSchema}
                onSubmit={showConfirmation ? handleSubmit : handleConfirmation}>
                <Form className='flex gap-4 flex-col' id='intake-form'>
                    {loading ? <Loader /> : bedrijfsActiviteitKiezen}
                </Form>
            </Formik>
        </IntakePhase>
    );
}
