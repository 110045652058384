import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { IconContext } from 'react-icons';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import App from './app/App';
import LoadingPage from './common/page/LoadingPage';
import './styles/main.css';
import './helpers/i18n';

const root = createRoot(document.getElementById('app')!);
root.render(
    <React.StrictMode>
        <Suspense fallback={<LoadingPage />}>
            <BrowserRouter>
                <IconContext.Provider value={{}}>
                    <App />
                </IconContext.Provider>
            </BrowserRouter>
            <ToastContainer position='bottom-right' />
        </Suspense>
    </React.StrictMode>
);
