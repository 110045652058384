import { Configuration, ConfigurationParameters } from '../generated/softwarematching-api';
import { BaseAPI } from '../generated/softwarematching-api/base';

export abstract class TokenizedService<T extends BaseAPI> {
    protected api: T;

    protected constructor(
        private apiType: typeof BaseAPI,
        protected language: string
    ) {
        this.api = this.buildApi(undefined);
    }

    updateToken(token: string | undefined) {
        this.api = this.buildApi(token);
    }

    private buildApi<T>(token: string | undefined): T {
        const configuration = new Configuration({
            baseOptions: {
                headers: {
                    // Content-type is automatically set by the generated client
                    Accept: 'application/json',
                    'Accept-Language': this.language,
                    Authorization: token ? `Bearer ${token}` : (undefined as any),
                },
            },
        } as ConfigurationParameters);
        return new this.apiType(configuration) as T;
    }
}
