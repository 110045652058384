import * as React from 'react';
import { IntakeMatchFunctionaliteitGroep } from '../../../generated/softwarematching-api';
import MatchDetailFunctionaliteit from './MatchDetailFunctionaliteit';

type Props = {
    functionaliteitGroep: IntakeMatchFunctionaliteitGroep;
    showRequested?: boolean;
};

export default function MatchDetailFunctionaliteitGroep({ functionaliteitGroep, showRequested = false }: Props) {
    return (
        <div>
            <div className='portal-body--small text-gray-500'>{functionaliteitGroep.naam}:</div>
            <div>
                {functionaliteitGroep.functionaliteiten.map(functionaliteit => (
                    <MatchDetailFunctionaliteit
                        key={functionaliteit.naam}
                        functionaliteit={functionaliteit}
                        showRequested={showRequested}
                    />
                ))}
            </div>
        </div>
    );
}
