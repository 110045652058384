import { Bedrijfsprofiel, IntakeVoortgang, IntakeVoortgangItem } from '../../../../generated/softwarematching-api';
import IntakePhase from '../IntakePhase';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IntakeBedrijfsprofielForm } from './IntakeBedrijfsprofielForm';
import useIntakeService from '../../hooks/useIntakeService';
import { showErrorResponse } from '../../../../helpers/notify';
import { BrowseDirection } from '../../../../common/Constants';
import { makeCancellable } from '../../../../helpers/promise';

type Props = {
    intakeVoortgang: IntakeVoortgang;
    currentIntakeVoortgangItem: IntakeVoortgangItem;
    onUpdateIntakeVoortgang: (intakeVoortgang: IntakeVoortgang) => void;
    browse: (direction: BrowseDirection) => void;
};

const initialProfiel: Bedrijfsprofiel = {
    aantalGebruikers: 0,
    aantalMedewerkers: 0,
    land: 'NETHERLANDS',
    voorkeurstalen: [],
    bedrijfsnaam: '',
    beschrijving: '',
    dna: '',
    website: '',
    adres: '',
    postcode: '',
    stad: '',
};

export function IntakeBedrijfsprofiel({
    intakeVoortgang,
    currentIntakeVoortgangItem,
    onUpdateIntakeVoortgang,
    browse,
}: Props) {
    const { t } = useTranslation();
    const [bedrijfsprofiel, setBedrijfsprofiel] = useState<Bedrijfsprofiel>(initialProfiel);
    const intakeService = useIntakeService();

    const handleSubmit = useCallback(
        (profiel: Bedrijfsprofiel) => {
            intakeService
                .postBedrijfsprofiel(intakeVoortgang.klantvraagId!!, profiel)
                .then(onUpdateIntakeVoortgang)
                .catch(error => showErrorResponse(t('common:failedToUpdate'), error, t));
        },
        [intakeService, t, intakeVoortgang, onUpdateIntakeVoortgang]
    );

    useEffect(() => {
        const [promise, cancel] = makeCancellable(intakeService.getBedrijfsprofiel(intakeVoortgang.klantvraagId));
        promise.then(setBedrijfsprofiel).catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t));
        return cancel;
    }, [intakeVoortgang, intakeService, t, intakeVoortgang.klantvraagId]);

    return (
        <IntakePhase
            title={t('intake.intakePhase.BEDRIJFSPROFIEL')}
            intakeVoortgang={intakeVoortgang}
            currentIntakeVoortgangItem={currentIntakeVoortgangItem}
            showPreviousButton
            showNextButton
            previousCallback={() => browse('previous')}>
            <IntakeBedrijfsprofielForm
                bedrijfsProfiel={bedrijfsprofiel}
                onUpdateBedrijfsprofiel={profiel => {
                    setBedrijfsprofiel(profiel);
                    handleSubmit(profiel);
                }}
            />
        </IntakePhase>
    );
}
