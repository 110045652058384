import { useAuth } from 'react-oidc-context';
import LoadingPage from './page/LoadingPage';
import { useEffect } from 'react';
import UnauthorizedPage from './page/UnauthorizedPage';
import { useTranslation } from 'react-i18next';
import { useAuthWrapper } from '../hooks/auth-hooks';

type Props = {
    children: JSX.Element;
};

export default function AppRequiredAuthentication(props: Props) {
    const auth = useAuth();
    const { login, logout } = useAuthWrapper();
    const { t } = useTranslation();

    useEffect(() => {
        if (!auth.isLoading && auth.activeNavigator == null && auth.error == null && !auth.isAuthenticated) {
            login();
        }
    }, [auth, login]);

    useEffect(() => {
        return auth.events.addSilentRenewError(cb => {
            console.error('silentRenewError', cb);
            // Logout the user if we encounter a refresh error, to prevent an invalid login state
            logout();
        });
    }, [auth, logout]);

    // Handle Automatic sign-in https://github.com/authts/react-oidc-context#automatic-sign-in
    switch (auth.activeNavigator) {
        case 'signinSilent':
        case 'signoutRedirect':
            return <LoadingPage />;
    }

    if (auth.error) {
        return (
            <div>
                {t('common:auth.error')}: {auth.error.message}
            </div>
        );
    }
    if (auth.isLoading) {
        return <LoadingPage />;
    }

    if (auth.isAuthenticated) {
        return props.children;
    } else {
        return <UnauthorizedPage />;
    }
}
