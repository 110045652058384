import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';

type Props = {
    linkText: string;
    link: string;
    dataTestId?: string;
};
export default function ExternalLink({ linkText, link, dataTestId }: Readonly<Props>) {
    const href = link.startsWith('http') || link.startsWith('mailto') ? link : 'https://' + link;

    return (
        <a className='flex items-center' href={href} rel='noreferrer' target='_blank'>
            <span className='hover:underline' data-testid={dataTestId}>
                {linkText}
            </span>
            <FaExternalLinkAlt size={20} className='pl-2 text-primary inline' />
        </a>
    );
}
