import React, { useMemo } from 'react';
import { PhaseProgress, PhaseType } from '../../../../generated/softwarematching-api';
import IntakeVoortgangItem from './IntakeVoortgangItem';

type Props = {
    phase: PhaseProgress;
};

export default function IntakeVoortgangIndicatorPhaseDetails({ phase }: Props) {
    const details = useMemo(() => {
        switch (phase.phaseType) {
            case PhaseType.BEDRIJFSACTIVITEIT_VRAAGGROEPEN:
                return <VraaggroepenPhaseDetails phase={phase} />;
            case PhaseType.FUNCTIONALITEITSGROEP_VRAAGGROEPEN:
                return <VraaggroepenPhaseDetails phase={phase} />;
            default:
                return null;
        }
    }, [phase]);

    return <>{details && <div className='mt-6 ml-4 flex flex-col gap-6'>{details}</div>}</>;
}

function VraaggroepenPhaseDetails({ phase }: { phase: PhaseProgress }) {
    return (
        <>
            {phase.vraaggroepen?.map(vraaggroep => (
                <IntakeVoortgangItem
                    key={vraaggroep.id}
                    phaseState={vraaggroep.state}
                    groupText={vraaggroep.naam}
                    phaseType={phase.phaseType}
                />
            ))}
        </>
    );
}
