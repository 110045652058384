import React from 'react';
import Loader from '../Loader';

export default function LoadingPage() {
    return (
        <div className='min-h-screen flex text-center justify-center items-center flex-col' data-testid='loading-page'>
            <main>
                <Loader />
            </main>
        </div>
    );
}
