/* tslint:disable */
/* eslint-disable */
/**
 * Software Matching Api
 * Software Matching API for the match-making questionnaire service
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Current phase of the intake
 * @export
 * @enum {string}
 */

export const PhaseType = {
    SOFTWARE_TYPE_KIEZEN: 'SOFTWARE_TYPE_KIEZEN',
    BEDRIJFSACTIVITEIT_KIEZEN: 'BEDRIJFSACTIVITEIT_KIEZEN',
    BEDRIJFSACTIVITEIT_VRAAGGROEPEN: 'BEDRIJFSACTIVITEIT_VRAAGGROEPEN',
    FUNCTIONALITEITSGROEP_VRAAGGROEPEN: 'FUNCTIONALITEITSGROEP_VRAAGGROEPEN',
    FUNCTIONALITEIT_CONTROLE: 'FUNCTIONALITEIT_CONTROLE',
    PROJECT: 'PROJECT',
    BEDRIJFSPROFIEL: 'BEDRIJFSPROFIEL',
    CONTACTGEGEVENS: 'CONTACTGEGEVENS',
    AFSLUITING: 'AFSLUITING'
} as const;

export type PhaseType = typeof PhaseType[keyof typeof PhaseType];



