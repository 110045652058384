import * as React from 'react';
import { useEffect, useState } from 'react';
import { IntakeMatch, KnockoutPercentageByAssessmentType } from '../../../generated/softwarematching-api';
import MatchModal from './MatchModal';
import Summary from './Summary';
import MatchCard from './MatchCard';
import { useFormikContext } from 'formik';
import { MatchFilterFormValues, MatchSelectionFormValues, MatchSortingFormValues } from '../pages/SelectMatches';
import { useTranslation } from 'react-i18next';
import MatchKnockoutExplanation from './MatchKnockoutExplanation';
import FormFieldSelect, { SelectOption } from '../../../common/form/FormFieldSelect';
import { PiSlidersHorizontalFill, PiSortAscendingBold } from 'react-icons/pi';

type Props = {
    matches: IntakeMatch[];
    knockoutPercentageByAssessmentType: KnockoutPercentageByAssessmentType[];
};

export default function MatchSelectionForm({ matches, knockoutPercentageByAssessmentType }: Props) {
    const { t } = useTranslation();
    const { values, setFieldValue } = useFormikContext<MatchSelectionFormValues>();
    const selectedMatches = matches.filter(m => values.selected.includes(m.matchResultId));
    const { MatchModalState, clearMatchModal, showAddMatchModal } = useMatchModalState();
    const [filteredMatches, setFilteredMatches] = useState(matches);
    const [sort, setSort] = useState(false);
    const [filter, setFilter] = useState(false);
    const sortingContext = useFormikContext<MatchSortingFormValues>();
    const filterContext = useFormikContext<MatchFilterFormValues>();

    const sortingOptions: SelectOption[] = [
        { value: 'score', label: t('domain:portal.sortingOption.score') },
        { value: 'implementatiekosten', label: t('domain:portal.sortingOption.cost') },
    ];
    const filterOptions: SelectOption[] = matches.map<SelectOption>(match => ({
        value: match.product.naam,
        label: match.product.naam,
    }));

    const removeMatch = (idToRemove: string) => {
        const selected = values.selected.filter(id => id !== idToRemove);
        return setFieldValue('selected', selected);
    };

    const selectMatch = (idToSelect: string) => {
        return setFieldValue('selected', [...values.selected, idToSelect]);
    };

    const isSelected = (id: string) => {
        return values.selected.includes(id);
    };

    const softwareType = matches.length > 0 ? matches[0].product.softwareType : '';

    const sortMatches = (sortingValue: String, matches: IntakeMatch[]): IntakeMatch[] => {
        switch (sortingValue) {
            case 'score':
                return matches.sort((a, b) => b.rating.rate - a.rating.rate);
            case 'implementatiekosten':
                return matches.sort((a, b) => a.budget.implementatiekosten - b.budget.implementatiekosten);
            default:
                return matches;
        }
    };

    useEffect(() => {
        const sortingValue = sortingContext.values.matchSorting;

        if (sortingValue) {
            setFilteredMatches(prevMatches => {
                return sortMatches(sortingValue, prevMatches);
            });
        }
    }, [sortingContext.values.matchSorting]);

    useEffect(() => {
        const filters = filterContext.values.matchFilter;
        const sortingValue = sortingContext.values.matchSorting;

        if (filters && filters.length) {
            setFilteredMatches(
                sortMatches(sortingValue, [...matches]).filter(match => filters.includes(match.product.naam))
            );
        } else {
            setFilteredMatches(sortMatches(sortingValue, [...matches]));
        }
    }, [filterContext.values.matchFilter, matches, sortingContext.values.matchSorting]);

    return (
        <>
            <div className='w-full lg:w-2/3 mb-28 lg:mb-40 lg:pr-10'>
                <h5 className='portal-heading-5 mb-3'>
                    {t('matchSelection.preHeader', { softwareType: softwareType })}
                </h5>
                <h1 className='portal-heading-1 mb-5'>{t('matchSelection.header')}</h1>
                {matches.length ? (
                    <>
                        <p className='portal-body--large pb-2'> {t('matchSelection.actionText')}</p>
                    </>
                ) : (
                    <MatchKnockoutExplanation knockoutPercentageByAssessmentType={knockoutPercentageByAssessmentType} />
                )}
                <div
                    className='portal-body--small text-gray-500 mt-5 flex justify-between items-center'
                    data-testid='match-results'>
                    <p>{t('matchSelection.results', { count: matches.length })}</p>
                    <div className='text-lg flex gap-x-4'>
                        <span
                            className='text-primary font-medium underline flex items-center gap-x-2 hover:cursor-pointer hover:text-secondary'
                            onClick={() => setSort(!sort)}
                            data-testid='sort-matches'>
                            <PiSortAscendingBold />
                            {t('common:sort')}
                        </span>
                        <span
                            className='text-primary font-medium underline flex items-center gap-x-2 hover:cursor-pointer hover:text-secondary'
                            onClick={() => setFilter(!filter)}
                            data-testid='filter-matches'>
                            <PiSlidersHorizontalFill />
                            {t('common:filter')}
                        </span>
                    </div>
                </div>
                <div className='flex gap-4 flex-col'>
                    {sort ? (
                        <FormFieldSelect
                            name='matchSorting'
                            label={t('domain:portal.sortingSelect')}
                            options={sortingOptions}
                        />
                    ) : (
                        ''
                    )}
                    {filter ? (
                        <FormFieldSelect
                            name='matchFilter'
                            label={t('domain:portal.filterSelect')}
                            multiple={true}
                            options={filterOptions}
                        />
                    ) : (
                        ''
                    )}
                </div>
                <div className='mt-5 space-y-3' data-testid='grid-matches'>
                    {filteredMatches.map(m => {
                        return (
                            <MatchCard
                                key={m.matchResultId}
                                match={m}
                                onView={() => showAddMatchModal(m)}
                                onRemoveMatch={matchResultId => removeMatch(matchResultId)}
                                onSelectMatch={matchResultId => selectMatch(matchResultId)}
                                isSelectedMatch={isSelected(m.matchResultId)}
                            />
                        );
                    })}
                </div>
            </div>
            <div className='hidden lg:block w-1/3'>
                <div className='fixed mr-10 max-w-lg'>
                    <Summary selectedMatches={selectedMatches} onRemoveMatch={id => removeMatch(id)} />
                </div>
            </div>
            {MatchModalState.item && (
                <MatchModal
                    match={MatchModalState.item}
                    isSelectedMatch={isSelected(MatchModalState.item.matchResultId)}
                    onRemoveMatch={matchResultId => removeMatch(matchResultId)}
                    onSelectMatch={matchResultId => selectMatch(matchResultId)}
                    onCancel={() => clearMatchModal()}></MatchModal>
            )}
        </>
    );
}

type MatchModalState = {
    item: IntakeMatch | null;
};

function useMatchModalState() {
    const [MatchModalState, setMatchModalState] = useState<MatchModalState>({ item: null });

    function clearMatchModal() {
        setMatchModalState({ item: null });
    }

    function showAddMatchModal(item: IntakeMatch) {
        setMatchModalState({ item: item });
    }

    return { MatchModalState, clearMatchModal, showAddMatchModal };
}
