import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/button/Button';
import { IntakeMatchMeeting, MatchStatus, MeetingDetails, TimeSlot } from '../../../generated/softwarematching-api';

type Props = {
    matchStatus: MatchStatus;
    meetingProposals: IntakeMatchMeeting;
    meetingDetails?: MeetingDetails;
    acceptProposal: (meetingId: string, timeslot: TimeSlot) => void;
    alternativeProposal: (timeslot: string) => void;
    matchResultId: string;
    rejectProposal: () => void;
};

export function MatchMeeting({
    matchStatus,
    meetingProposals,
    acceptProposal,
    meetingDetails,
    rejectProposal,
}: Readonly<Props>) {
    const { t } = useTranslation();
    const DUMMY_TIMESLOT: TimeSlot = '0900-1200';

    if (matchStatus === MatchStatus.PROPOSED) {
        return (
            <div className='p-4 rounded border border-gray-200 space-y-4'>
                <div className='flex space-x-4'>
                    <Button
                        className='flex-1 w-5'
                        variant='outlined'
                        onClick={() =>
                            acceptProposal(meetingProposals.kennismakingSlots!.shift()!.meetingId!, DUMMY_TIMESLOT)
                        }
                        dataTestId='accept_meeting'>
                        {t('partner:meeting.accept')}
                    </Button>
                    <Button
                        className='flex-1 w-5'
                        variant='contained'
                        color='warning'
                        onClick={rejectProposal}
                        dataTestId='reject-match-button'>
                        {t('partner:matches.reject')}
                    </Button>
                </div>
            </div>
        );
    } else if (matchStatus === MatchStatus.PLANNED && meetingDetails?.meetingDate == null) {
        return (
            <div className='p-4 rounded border border-gray-200 space-y-4'>
                <h2 className='text-xl'>{t('partner:meeting.rejectedTitle')}</h2>
                <div>{t('partner:meeting.rejected')}</div>
            </div>
        );
    } else {
        return null;
    }
}
