/**
 * Prevents the promise from resolving after the cancelCallback is invoked.
 * Useful for preventing React state updates after components are dismounted, by returning the cancel callback in useEffect.
 * @param promise
 */
export function makeCancellable<T>(promise: Promise<T>): [Promise<T>, () => void] {
    let isCancelled = false;

    const cancellablePromise = new Promise<T>((resolve, reject) => {
        // Suppress resolution and rejection if canceled
        promise.then(result => !isCancelled && resolve(result)).catch(error => !isCancelled && reject(error));
    });

    const cancelCallback = () => {
        isCancelled = true;
    };

    return [cancellablePromise, cancelCallback];
}
