import {
    IntakeContactgegevens,
    IntakeVoortgang,
    IntakeVoortgangItem,
} from '../../../../generated/softwarematching-api';
import { Trans, useTranslation } from 'react-i18next';
import IntakePhase from '../IntakePhase';
import React, { useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import yup from '../../../../common/yup/YupExtended';
import FormFieldText from '../../../../common/form/FormFieldText';
import FormFieldCheckbox from '../../../../common/form/FormFieldCheckbox';
import useIntakeService from '../../hooks/useIntakeService';
import { showErrorResponse } from '../../../../helpers/notify';
import { makeCancellable } from '../../../../helpers/promise';
import { BrowseDirection } from '../../../../common/Constants';
import Loader from '../../../../common/Loader';

type Props = {
    intakeVoortgang: IntakeVoortgang;
    currentIntakeVoortgangItem: IntakeVoortgangItem;
    onUpdateIntakeVoortgang: (intakeVoortgang: IntakeVoortgang) => void;
    browse: (direction: BrowseDirection) => void;
};

const initialValues: IntakeContactgegevens = {
    voornaam: '',
    achternaam: '',
    telefoonnummer: '',
    email: '',
    akkoordPrivacyvoorwaarden: false,
};

export function IntakeContact({ intakeVoortgang, currentIntakeVoortgangItem, onUpdateIntakeVoortgang, browse }: Props) {
    const klantvraagId = intakeVoortgang.klantvraagId!!;
    const { t } = useTranslation();
    const intakeService = useIntakeService();
    const validationScheme = yup.object().shape({
        voornaam: yup.string().required().max(200),
        achternaam: yup.string().required().max(200),
        telefoonnummer: yup.string().required().max(20),
        email: yup.string().max(200).validEmail().required(),
        akkoordPrivacyvoorwaarden: yup
            .bool()
            .required()
            .isTrue('Het is verplicht om akkoord te gaan met de privacyvoorwaarden'),
    });
    const [contactValue, setContactValue] = useState<IntakeContactgegevens>(initialValues);
    const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(
        (contactgegevens: IntakeContactgegevens) => {
            setLoading(true);
            intakeService
                .postContactgegevens(intakeVoortgang.klantvraagId!!, contactgegevens)
                .then(onUpdateIntakeVoortgang)
                .catch(error => showErrorResponse(t('common:failedToUpdate'), error, t))
                .finally(() => setLoading(false));
        },
        [intakeService, t, intakeVoortgang, onUpdateIntakeVoortgang]
    );

    useEffect(() => {
        const [promise, cancel] = makeCancellable(intakeService.getIntakeContactgegevens(klantvraagId));
        promise.then(setContactValue).catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t));
        return cancel;
    }, [intakeService, t, klantvraagId]);

    return (
        <IntakePhase
            title={t('intake.intakePhase.CONTACTGEGEVENS')}
            intakeVoortgang={intakeVoortgang}
            currentIntakeVoortgangItem={currentIntakeVoortgangItem}
            showNextButton
            showPreviousButton
            previousCallback={() => browse('previous')}>
            <Formik
                initialValues={contactValue}
                validationSchema={validationScheme}
                enableReinitialize
                onSubmit={values => handleSubmit(values)}>
                <Form className='flex gap-4 flex-col' id='intake-form' noValidate>
                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            <h2 className='text-2xl font-light'>{t('intake.contactgegevens.header')}</h2>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                                <FormFieldText
                                    type='text'
                                    name='voornaam'
                                    label={t('intake.contactgegevens.voornaam')}
                                    required={true}
                                    autocomplete='given-name'
                                />
                                <FormFieldText
                                    type='text'
                                    name='achternaam'
                                    label={t('intake.contactgegevens.achternaam')}
                                    required={true}
                                    autocomplete='family-name'
                                />
                            </div>
                            <FormFieldText
                                type='text'
                                name='email'
                                label={t('intake.contactgegevens.emailadresZakelijk')}
                                required={true}
                                inputMode='email'
                                autocomplete='email'
                            />
                            <FormFieldText
                                type='text'
                                name='telefoonnummer'
                                label={t('intake.contactgegevens.telefoonnummerZakelijk')}
                                required={true}
                                inputMode='tel'
                                autocomplete='tel'
                            />
                            <FormFieldCheckbox name='akkoordPrivacyvoorwaarden' required={true}>
                                <Trans i18nKey='intake.contactgegevens.privacyvoorwaardenText' t={t}>
                                    Ik heb de{' '}
                                    <a
                                        className='text-secondary hover:cursor-pointer'
                                        href='https://softwarematching.io/nl/privacy-beleid/'
                                        rel='noreferrer'
                                        target='_blank'>
                                        privacyvoorwaarden
                                    </a>{' '}
                                    gelezen en ga hier akkoord mee.
                                </Trans>
                            </FormFieldCheckbox>
                        </>
                    )}
                </Form>
            </Formik>
        </IntakePhase>
    );
}
