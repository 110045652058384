import PartnerEditForm from '../components/PartnerEditForm';
import React from 'react';

import PartnerPageHeader from '../components/PartnerPageHeader';
import { useAuthPartnerId } from '../../../hooks/auth-hooks';
import UnauthorizedPage from '../../../common/page/UnauthorizedPage';

export default function ProfielAlgemeenPage() {
    const partnerId = useAuthPartnerId();

    if (!partnerId) {
        return <UnauthorizedPage />;
    }

    return (
        <>
            <PartnerPageHeader></PartnerPageHeader>
            <PartnerEditForm partnerId={partnerId} allowBedrijfsnaamActivationStatusChange={false} />
        </>
    );
}
