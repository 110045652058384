import { useField } from 'formik';
import React, { useMemo } from 'react';
import FormError from './FormError';
import { AntwoordOptie } from '../../generated/softwarematching-api';

type Props = {
    name: string;
    antwoordOpties: AntwoordOptie[];
    disabled?: boolean;
};

export default function FormFieldRadioGroup({ name, antwoordOpties, disabled }: Props) {
    const [field, meta] = useField(name);

    const options = useMemo(
        () =>
            antwoordOpties.map(it => ({
                value: it.id,
                label: it.tekst,
            })) ?? [],
        [antwoordOpties]
    );

    return (
        <div className='flex flex-col gap-2'>
            {options.map(option => (
                <div key={option.value}>
                    <input
                        type='radio'
                        className='w-5 h-5 align-middle text-secondary hover:cursor-pointer'
                        {...field}
                        name={`${field.name}[0]`}
                        id={option.value}
                        value={option.value}
                        checked={field.value[0] === option.value}
                        disabled={disabled}
                    />
                    <label className='pl-4 align-middle hover:cursor-pointer' htmlFor={option.value}>
                        {option.label}
                    </label>
                </div>
            ))}
            <FormError meta={meta} name={name} />
        </div>
    );
}
