import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    htmlFor: string;
    label: string;
    required?: boolean;
};

export default function FormLabel({ htmlFor, label, required }: Props) {
    const { t } = useTranslation();

    return (
        <label htmlFor={htmlFor} className='font-medium text-gray-600'>
            {label}
            {!required && <span className='ml-2 align-top text-faded '>{t('common:optional')}</span>}
        </label>
    );
}
