import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    IntakeMatch,
    IntakeMatchSelectRequest,
    KnockoutPercentageByAssessmentType,
    MeetingLocation,
} from '../../../generated/softwarematching-api';
import { Form, Formik } from 'formik';
import { showErrorResponse } from '../../../helpers/notify';
import { makeCancellable } from '../../../helpers/promise';
import MatchProposalCompleted from '../components/MatchProposalCompleted';
import MatchSelectionForm from '../components/MatchSelectionForm';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../../common/Loader';
import useProtectedService from '../../intake/hooks/useProtectedService';
import MatchSelectionBottomBar from '../components/MatchSelectionBottomBar';
import Placeholder from './Placeholder';
import { FaArrowLeft } from 'react-icons/fa';

export interface MatchSelectionFormValues {
    selected: string[];
}

export interface MatchMeetingFormValues {
    meetingLocation?: MeetingLocation;
    meetingTimeslots: string[];
}

export interface MatchSortingFormValues {
    matchSorting: String;
}

export interface MatchFilterFormValues {
    matchFilter: String[];
}

export interface Step {
    stepNumber: number;
}

export default function SelectMatches() {
    const { t } = useTranslation();
    const { intakeId } = useParams();
    const protectedService = useProtectedService();
    const [loading, setLoading] = useState(false);
    const [matches, setMatches] = useState<IntakeMatch[]>([]);
    const [knockoutPercentageByAssessmentType, setKnockoutPercentageByAssessmentType] = useState<
        KnockoutPercentageByAssessmentType[]
    >([]);
    const [isMatchSelectionSubmitted, setMatchSelectionSubmitted] = useState(false);
    const [step, setStep] = useState<Step>({ stepNumber: 1 });
    const [shouldShowBackButton, setShouldShowBackButton] = useState(true);

    useEffect(() => {
        if (!intakeId) {
            return;
        }
        setLoading(true);

        const [promise, cancel] = makeCancellable(
            Promise.all([
                protectedService.getIntakeMatches(intakeId),
                protectedService.getMeetingProposal(intakeId),
                protectedService.getInProgressIntakes(),
                protectedService.getMatchedIntakes(),
            ])
        );

        promise
            .then(([matchResult, meetingProposal, inProgressIntakes, matchedIntakes]) => {
                setMatches(matchResult.intakeMatches);
                setKnockoutPercentageByAssessmentType(matchResult.knockoutPercentageByAssessmentType);
                setMatchSelectionSubmitted(true);
                setStep({ stepNumber: 1 });

                setShouldShowBackButton(inProgressIntakes.length > 0 && matchedIntakes.length > 0);
            })
            .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t))
            .finally(() => setLoading(false));

        return cancel;
    }, [protectedService, intakeId, t]);

    const handleSelectSubmit = useCallback(
        async (matchResultIds: string[]) => {
            const intakeMatchSelectRequest: IntakeMatchSelectRequest = { matchResultIds: matchResultIds };

            try {
                await protectedService.selectMatches(intakeId!, intakeMatchSelectRequest);
                await protectedService.createMeetingProposal(intakeId!, {
                    kennismakingSlots: [
                        {
                            timeSlots: ['0900-1200', '1200-1500', '1500-1700'],
                            date: nextWeekFormatted(),
                            meetingId: undefined,
                            weeknummer: undefined,
                        },
                    ],
                    location: 'DIGITAL',
                });

                await protectedService.proposeToPartners(intakeId!);
                setStep({ stepNumber: 2 });
            } catch (error) {
                showErrorResponse(t('common:failedToUpdate'), error, t);
            }
        },
        [protectedService, t, intakeId, setStep]
    );

    const backLink = () =>
        step.stepNumber === 1 ? (
            <Link className='flex portal-body--link' to={'/'}>
                <FaArrowLeft className='text-primary mt-1.5 mr-3'></FaArrowLeft>
                {t('common:back')}
            </Link>
        ) : (
            <div className='flex portal-body--link' onClick={() => setStep({ stepNumber: step.stepNumber - 1 })}>
                <FaArrowLeft className='text-primary mt-1.5 mr-3'></FaArrowLeft>
                {t('common:back')}
            </div>
        );

    const step1 = (
        <Formik<MatchSelectionFormValues>
            initialValues={{
                selected: matches.filter(match => match.selected).map(match => match.matchResultId),
            }}
            onSubmit={values => {
                handleSelectSubmit(
                    values.selected.filter(i =>
                        matches
                            .filter(m => m.status === 'MATCHED')
                            .map(m => m.matchResultId)
                            .includes(i)
                    )
                );
            }}>
            <Form className='flex'>
                <MatchSelectionForm
                    matches={matches}
                    knockoutPercentageByAssessmentType={knockoutPercentageByAssessmentType}></MatchSelectionForm>
                <MatchSelectionBottomBar matches={matches}></MatchSelectionBottomBar>
            </Form>
        </Formik>
    );

    const step2 = <MatchProposalCompleted />;

    const renderStep = (stepNumber: number) => {
        switch (stepNumber) {
            case 1:
                return step1;
            case 2:
                return step2;
        }
    };

    if (loading) {
        return <Loader />;
    }

    if (isMatchSelectionSubmitted && !window.env.REACT_APP_FEATURE_MATCH_MEETING_ENABLED) {
        return <Placeholder domain='matchSelected' />;
    }

    return step.stepNumber === 2 ? (
        step2
    ) : (
        <>
            <div className='container mx-auto px-4 gap-10'>
                <div className='flex items-baseline justify-between mb-10'>{shouldShowBackButton && backLink()}</div>
                {renderStep(step.stepNumber)}
            </div>
        </>
    );
}

function getNextWeekDate(): Date {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 7);
    return tomorrow;
}

function nextWeekFormatted(): string {
    const tomorrow = getNextWeekDate();

    const day = String(tomorrow.getDate()).padStart(2, '0');
    const month = String(tomorrow.getMonth() + 1).padStart(2, '0');
    const year = tomorrow.getFullYear();

    return `${year}-${month}-${day}`;
}
