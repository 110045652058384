import React, { ReactNode } from 'react';

type Props = {
    title: String;
    subTitle: String;
    children: ReactNode;
    onClick?: () => void;
};

export function ListItem({ title, subTitle, children, onClick }: Props) {
    return (
        <>
            <li
                onClick={onClick}
                onKeyDown={onClick}
                className={`h-20 w-full px-5 py-3 bg-white rounded-sm border-l-2 border-gray-300 hover:border-black hover:bg-gray-50 flex-col justify-start items-start inline-flex ${
                    onClick ? 'cursor-pointer' : ''
                }`}>
                <div className='self-stretch justify-start items-center inline-flex'>
                    <div className='grow shrink flex-col justify-start items-start inline-flex'>
                        <div className='portal-body--large lg:font-medium'>{title}</div>
                        <div className='portal-body--large'>{subTitle}</div>
                    </div>
                    {children}
                </div>
            </li>
        </>
    );
}
