import React, { ReactNode, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

type Props = {
    label: string;
    defaultCollapsed?: boolean;
    children: ReactNode;
};

export default function Accordion({ label, children, defaultCollapsed = true }: Props) {
    const [collapsed, setCollapsed] = useState(defaultCollapsed);

    return (
        <>
            <button
                className={
                    'flex items-center justify-between w-full p-4 font-medium text-left text-gray-500 border border-faded rounded-t-xl hover:bg-gray-100 ' +
                    (collapsed ? '' : 'border-b-0')
                }
                type='button'
                data-testid={`accordion-${label}`}
                aria-expanded={!collapsed}
                onClick={() => setCollapsed(!collapsed)}>
                <span className=''>{label}</span>
                {collapsed ? <FaChevronDown /> : <FaChevronUp />}
            </button>
            <div className={'border border-t-0 border-faded ' + (collapsed ? 'hidden' : '')}>{children}</div>
        </>
    );
}
