import React from 'react';
import { Outlet } from 'react-router-dom';
import PortalHeader from '../components/PortalHeader';

export function PortalLayout() {
    return (
        <div className='flex flex-col min-h-screen min-w-screen' data-testid='portal-page'>
            <PortalHeader />
            <main className='flex-grow bg-background text-gray-800 w-full py-10'>
                <Outlet />
            </main>
        </div>
    );
}
