import React, { useMemo } from 'react';
import { Bedrijfsactiviteit } from '../../generated/softwarematching-api';
import Button from '../button/Button';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';
import FormError from '../form/FormError';
import { useTranslation } from 'react-i18next';
import { flattenBedrijfsactiviteiten } from './bedrijfsactiviteitenHelper';
import { FaTrash } from 'react-icons/fa';

type Props = {
    name: string;
    bedrijfsactiviteiten: Bedrijfsactiviteit[];
    onAdd: () => void;
    compactMode: boolean;
};

export default function BedrijfsactiviteitenCurrentSelectionForm({
    name,
    bedrijfsactiviteiten,
    onAdd,
    compactMode,
}: Props) {
    const { t } = useTranslation();
    const [, meta] = useField<string[] | undefined>(name);

    const currentSelected = meta.value || [];

    const bedrijfsactiviteitenFlat = useMemo(() => {
        return flattenBedrijfsactiviteiten(bedrijfsactiviteiten);
    }, [bedrijfsactiviteiten]);

    function getParents(id: string, foundParents: Bedrijfsactiviteit[] = []): Bedrijfsactiviteit[] {
        for (let bedrijfsactiviteit of bedrijfsactiviteitenFlat) {
            if (bedrijfsactiviteit.children.some(child => child.id === id)) {
                foundParents.unshift(bedrijfsactiviteit);
                getParents(bedrijfsactiviteit.id, foundParents);
            }
        }

        return foundParents;
    }

    return (
        <>
            <FieldArray
                name={name}
                render={(arrayHelpers: FieldArrayRenderProps) => (
                    <>
                        <div
                            data-testid='bedrijfsactiviteit-container'
                            className='rounded-md border-2 border-solid border-gray-100'>
                            {currentSelected.length ? (
                                currentSelected.map((bedrijfsactiviteitId, index) => {
                                    const selected = bedrijfsactiviteitenFlat.find(
                                        ba => ba.id === bedrijfsactiviteitId
                                    )!;
                                    const bedrijfsactiviteiten = compactMode
                                        ? [selected]
                                        : getParents(bedrijfsactiviteitId, [selected]);
                                    return (
                                        <BedrijfsactiviteitBevestigenSubItem
                                            bedrijfsactiviteitIncludingParents={bedrijfsactiviteiten}
                                            arrayHelpers={arrayHelpers}
                                            key={bedrijfsactiviteitId}
                                            index={index}
                                        />
                                    );
                                })
                            ) : (
                                <span className='block p-4 text-lg'>{t('intake.bedrijfsactiviteitNoSelect')}</span>
                            )}
                        </div>
                        <FormError meta={meta} name={name} />
                    </>
                )}
            />
            <Button
                className='block mt-2 ml-auto mr-0'
                variant='outlined'
                onClick={() => onAdd()}
                dataTestId='toevoegen-bedrijfsactiviteiten'>
                {t('common:addMore')}
            </Button>
        </>
    );
}

function BedrijfsactiviteitBevestigenSubItem({
    bedrijfsactiviteitIncludingParents,
    index,
    arrayHelpers,
}: {
    bedrijfsactiviteitIncludingParents: Bedrijfsactiviteit[];
    index: number;
    arrayHelpers: FieldArrayRenderProps;
}) {
    const { t } = useTranslation();
    const bedrijfsactiviteitLeaf = bedrijfsactiviteitIncludingParents[bedrijfsactiviteitIncludingParents.length - 1];

    return (
        <div
            className='p-4 flex items-center justify-between border-b-2 last:border-0 border-solid border-gray-100 gap-2'
            data-testid='bedrijfsactiviteit-item'>
            <div>
                <span className='font-light'>
                    {bedrijfsactiviteitIncludingParents.map((ba, index) => (
                        <React.Fragment key={ba.id}>
                            {!!index && <span className='font-bold px-2'>/</span>}
                            {<span>{ba.naam}</span>}
                        </React.Fragment>
                    ))}
                </span>
                {bedrijfsactiviteitLeaf.deprecated && (
                    <span className='mx-4 badge badge-yellow'>{t('common:deprecated')}</span>
                )}
            </div>
            <FaTrash
                className='admin-icon fill-warning hover:fill-red-800 h-4 w-4 flex-none'
                data-testid='remove-bedrijfsactiviteit'
                onClick={() => arrayHelpers.remove(index)}></FaTrash>
        </div>
    );
}
