import React from 'react';
import { NavLink } from 'react-router-dom';

type Tab = {
    title: string;
    link: string;
};

type Props = {
    tabs: Tab[];
};

export default function Tabs({ tabs }: Props) {
    return (
        <div className='mb-8 pb-2 border-b border-gray-200'>
            <nav className='space-x-8' aria-label='Tabs'>
                {tabs.map(tab => {
                    const cssClasses = ({ isActive }: { isActive: boolean }): string => {
                        return `font-medium text-lg hover:pb-3 hover:border-primary hover:border-b-2 ${
                            isActive ? 'text-primary border-b-2 border-primary pb-3' : ''
                        }`;
                    };
                    return (
                        <NavLink
                            key={tab.title}
                            data-testid={'tab-' + tab.title}
                            to={tab.link}
                            relative='path'
                            className={cssClasses}>
                            {tab.title}
                        </NavLink>
                    );
                })}
            </nav>
        </div>
    );
}
