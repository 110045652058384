import React from 'react';
import { Route, Routes } from 'react-router-dom';
import IntakePage from './pages/IntakePage';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { User } from 'oidc-client-ts';
import AppAuthentication from '../../common/AppAuthentication';
import ResetKlantvraagId from './pages/ResetKlantvraagId';

const oidcConfig: AuthProviderProps = {
    authority: '/auth/realms/' + window.env.REACT_APP_KEYCLOAK_REALM,
    client_id: window.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    redirect_uri: window.location.href,
    onSigninCallback: (user: User | void) => {
        window.history.replaceState({}, document.title, window.location.pathname);
    },
};

export default function IntakeRouting() {
    return (
        <AuthProvider {...oidcConfig}>
            <AppAuthentication>
                <Routes>
                    <Route path='/' element={<IntakePage />} />
                    <Route path='/new' element={<ResetKlantvraagId />} />
                </Routes>
            </AppAuthentication>
        </AuthProvider>
    );
}
