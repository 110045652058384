import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ModalFooterPlain from '../../../common/modal/ModalFooterPlain';
import ModalHeaderPlain from '../../../common/modal/ModalHeaderPlain';
import ModalPlain from '../../../common/modal/ModalPlain';
import { Size } from '../../../common/Size';
import { IntakeMatch } from '../../../generated/softwarematching-api';
import ModalContentPlain from '../../../common/modal/ModalContentPlain';
import MatchDetails from './MatchDetails';
import PartnerActionButton from './PartnerActionButton';
import { FaChevronRight } from 'react-icons/fa';

type Props = {
    match: IntakeMatch;
    isSelectedMatch: boolean;
    onRemoveMatch: (matchResultId: string) => void;
    onSelectMatch: (matchResultId: string) => void;
    onCancel: () => void;
};

export default function MatchModal({ match, isSelectedMatch, onSelectMatch, onRemoveMatch, onCancel }: Props) {
    const { t } = useTranslation();

    const rating = () => (
        <div className={'flex items-center'}>
            <span className='portal-body--small pr-1'>{t('matchSelection.match')}:</span>
            <span className='portal-heading-4 text-primary' data-testid='matchdetail-score'>
                {Math.trunc(match.rating.rate)}%
            </span>
        </div>
    );

    const partnerInfo = () => (
        <div className={'lg:grid grid-cols-4 gap-y-3 items-center block portal-body--small text-gray-500 flex-grow'}>
            <div className={'mb-3'}>{t('matchSelection.partner')}</div>
            <div className={'col-span-3 text-primary portal-heading-5 mb-3'} data-testid='matchdetail-partner'>
                {match.partner.name}
            </div>
            <div className={'mb-3'}>{t('matchSelection.software')}</div>
            <div className={'col-span-3 text-primary portal-heading-5 mb-3'} data-testid='matchdetail-product'>
                {match.product.naam !== match.product.vendor
                    ? match.product.vendor + ' - ' + match.product.naam
                    : match.product.vendor}
            </div>
            {match.partner.description && (
                <>
                    <div className='col-span-4'>{t('matchSelection.aboutPartner')}</div>
                    <div className='col-span-4 text-primary portal-heading-6 mb-3' data-testid='matchdetail-over'>
                        {match.partner.description}
                    </div>
                </>
            )}
            {match.partner.website && (
                <>
                    <a
                        className='col-span-4 underline text-primary mb-3'
                        href={
                            match.partner.website.startsWith('http://') || match.partner.website.startsWith('https://')
                                ? match.partner.website
                                : 'https://' + match.partner.website
                        }
                        target='_blank'
                        rel='noreferrer'
                        data-testid='matchdetail-website'>
                        <div className='flex items-center'>
                            {t('matchSelection.website')}
                            <FaChevronRight className='text-primary cursor-pointer ml-1'></FaChevronRight>
                        </div>
                    </a>
                </>
            )}
        </div>
    );

    return (
        <ModalPlain size={Size.Large} dataTestId='matchdetail-modal'>
            <ModalHeaderPlain onClose={onCancel} />
            <ModalContentPlain>
                <div className='md:flex justify-between'>
                    <div className='flex flex-col order-1 items-end'>{rating()}</div>
                    {partnerInfo()}
                </div>
                <div className='pt-5 pb-5 sm:w-full'>
                    <PartnerActionButton
                        isSelectedMatch={isSelectedMatch}
                        onRemoveMatch={onRemoveMatch}
                        onSelectMatch={onSelectMatch}
                        match={match}
                    />
                </div>
                <MatchDetails match={match} />
            </ModalContentPlain>
            <ModalFooterPlain />
        </ModalPlain>
    );
}
