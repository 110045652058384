import { Partnerprofiel } from '../../../generated/softwarematching-api';
import { useTranslation } from 'react-i18next';
import usePartnerService from '../hooks/usePartnerService';
import React, { useCallback, useEffect, useState } from 'react';
import yup from '../../../common/yup/YupExtended';
import { showErrorResponse, showSuccess } from '../../../helpers/notify';
import { Form, Formik } from 'formik';
import FormFieldText from '../../../common/form/FormFieldText';
import FormFieldTextArea from '../../../common/form/FormFieldTextArea';
import SubmitButton from '../../../common/button/SubmitButton';
import FormFieldCheckbox from '../../../common/form/FormFieldCheckbox';

type Props = {
    partnerId: string;
    allowBedrijfsnaamActivationStatusChange: boolean;
};

const initialValues: Partnerprofiel = {
    id: '',
    bedrijfsnaam: '',
    beschrijving: '',
    dna: '',
    website: '',
    telefoonnummer: '',
    adres: '',
    postcode: '',
    stad: '',
    btwnummer: '',
    kvknummer: '',
    billingcode: '',
    financialContact: {
        voornaam: '',
        achternaam: '',
        telefoonnummer: '',
        email: '',
    },
    salesContact: {
        voornaam: '',
        achternaam: '',
        telefoonnummer: '',
        email: '',
    },
    actief: false,
};

export default function PartnerEditForm({ partnerId, allowBedrijfsnaamActivationStatusChange }: Props) {
    const { t } = useTranslation();
    const partnerService = usePartnerService();
    const [currentValues, setCurrentValues] = useState<Partnerprofiel>(initialValues);
    const validationScheme = yup.object().shape({
        bedrijfsnaam: yup.string().required().max(200),
        beschrijving: yup.string().required().max(5000),
        website: yup.string().required().max(200),
        telefoonnummer: yup.string().required().max(20),
        adres: yup.string().required().max(200),
        postcode: yup.string().required().max(20),
        stad: yup.string().required().max(200),
        btwnummer: yup.string().required().max(200),
        kvknummer: yup
            .string()
            .nullable()
            .max(200)
            .matches(/^\d{4}\s?\d{4}$/)
            .transform(value => (!value ? null : value)),
        billingcode: yup
            .string()
            .nullable()
            .max(200)
            .transform(value => (!value ? null : value)),
        financialContact: yup.object().shape({
            voornaam: yup.string().required().max(200),
            achternaam: yup.string().required().max(200),
            telefoonnummer: yup.string().required().max(200),
            email: yup.string().max(200).validEmail().required(),
        }),
        salesContact: yup.object().shape({
            voornaam: yup.string().required().max(200),
            achternaam: yup.string().required().max(200),
            telefoonnummer: yup.string().required().max(200),
            email: yup.string().max(200).validEmail().required(),
        }),
    });

    useEffect(() => {
        partnerService
            .getPartnerprofiel(partnerId)
            .then(result => {
                setCurrentValues(result);
            })
            .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t));
    }, [partnerService, t, partnerId]);

    const handleSubmit = useCallback(
        (parnerProfiel: Partnerprofiel) => {
            parnerProfiel.dna = '';
            partnerService
                .updatePartnerprofiel(partnerId, parnerProfiel)
                .then(result => {
                    setCurrentValues(result);
                    showSuccess(t('partner:profile.saveSuccess'));
                })
                .catch(error => showErrorResponse(t('common:failedToUpdate'), error, t));
        },
        [partnerService, t, partnerId]
    );

    return (
        <Formik
            initialValues={currentValues}
            enableReinitialize={true}
            validationSchema={validationScheme}
            onSubmit={values => {
                const castValues = validationScheme.cast(values);
                handleSubmit({ ...currentValues, ...castValues });
            }}>
            <Form noValidate>
                <div className='flex gap-10 mt-4'>
                    <div className='w-1/2 flex gap-4 flex-col'>
                        <h2 className='font-header text-xl'>{t('partner:profile.headerGeneral')}</h2>
                        <FormFieldText
                            type='text'
                            name='bedrijfsnaam'
                            label={t('partner:profile.bedrijfsnaam')}
                            required={true}
                            disabled={!allowBedrijfsnaamActivationStatusChange}
                        />
                        <FormFieldCheckbox name='actief' disabled={!allowBedrijfsnaamActivationStatusChange}>
                            {t('partner:profile.activeer')}
                        </FormFieldCheckbox>
                        {allowBedrijfsnaamActivationStatusChange && (
                            <p className='italic'>{t('partner:profile.uitlegActiveren')}</p>
                        )}
                        <FormFieldTextArea
                            name='beschrijving'
                            label={t('partner:profile.beschrijving')}
                            required={true}
                            placeholder={t('partner:profile.beschrijvingPlaceholder')}
                        />
                        <FormFieldText
                            type='text'
                            name='website'
                            label={t('partner:profile.website')}
                            required={true}
                            inputMode='url'
                        />
                        <FormFieldText
                            type='text'
                            name='telefoonnummer'
                            label={t('partner:profile.telefoonnummer')}
                            required={true}
                            inputMode='tel'
                        />
                        <FormFieldText type='text' name='adres' label={t('partner:profile.adres')} required={true} />
                        <div className='grid grid-cols-2 gap-2'>
                            <FormFieldText
                                type='text'
                                name='postcode'
                                label={t('partner:profile.postcode')}
                                required={true}
                            />
                            <FormFieldText type='text' name='stad' label={t('partner:profile.stad')} required={true} />
                        </div>
                        <FormFieldText
                            type='text'
                            name='btwnummer'
                            label={t('partner:profile.btwnummer')}
                            required={true}
                        />
                        <FormFieldText
                            type='text'
                            name='kvknummer'
                            label={t('partner:profile.kvknummer')}
                            required={true}
                        />
                        <FormFieldText
                            type='text'
                            name='billingcode'
                            label={t('partner:profile.billingcode')}
                            required={true}
                        />
                    </div>
                    <div className='w-1/2 flex gap-4 flex-col'>
                        <h2 className='font-header text-xl'>{t('partner:profile.headerFinancialContact')}</h2>
                        <div className='grid grid-cols-2 gap-2'>
                            <FormFieldText
                                type='text'
                                name='financialContact.voornaam'
                                label={t('partner:profile.voornaam')}
                                required={true}
                            />
                            <FormFieldText
                                type='text'
                                name='financialContact.achternaam'
                                label={t('partner:profile.achternaam')}
                                required={true}
                            />
                        </div>
                        <FormFieldText
                            type='text'
                            name='financialContact.email'
                            label={t('partner:profile.email')}
                            required={true}
                            inputMode='email'
                        />
                        <FormFieldText
                            type='text'
                            name='financialContact.telefoonnummer'
                            label={t('partner:profile.telefoonnummer')}
                            required={true}
                            inputMode='tel'
                        />
                        <h2 className='font-header text-xl mt-6'>{t('partner:profile.headerSalesContact')}</h2>
                        <div className='grid grid-cols-2 gap-2'>
                            <FormFieldText
                                type='text'
                                name='salesContact.voornaam'
                                label={t('partner:profile.voornaam')}
                                required={true}
                            />
                            <FormFieldText
                                type='text'
                                name='salesContact.achternaam'
                                label={t('partner:profile.achternaam')}
                                required={true}
                            />
                        </div>
                        <FormFieldText
                            type='text'
                            name='salesContact.email'
                            label={t('partner:profile.email')}
                            required={true}
                            inputMode='email'
                        />
                        <FormFieldText
                            type='text'
                            name='salesContact.telefoonnummer'
                            label={t('partner:profile.telefoonnummer')}
                            required={true}
                            inputMode='tel'
                        />
                    </div>
                </div>
                <div className='mt-4 flex justify-end'>
                    <SubmitButton label={t('common:save')} />
                </div>
            </Form>
        </Formik>
    );
}
