import { useField } from 'formik';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AntwoordOptie } from '../../generated/softwarematching-api';
import FormError from './FormError';

type Props = {
    name: string;
    antwoordOpties: AntwoordOptie[];
    disabled?: boolean;
};

export default function FormFieldCheckboxGroup({ name, antwoordOpties, disabled }: Props) {
    const [field, meta] = useField(name);
    const { t } = useTranslation();

    const options = useMemo(
        () =>
            antwoordOpties.map(it => ({
                value: it.id,
                label: it.tekst,
            })) ?? [],
        [antwoordOpties]
    );

    return (
        <div className='flex flex-col gap-2 font-light'>
            <p className='text-faded -mt-4 mb-4'>{t('common:multipleAnswersPossible')}</p>
            {options.map(option => (
                <div key={option.value}>
                    <input
                        type='checkbox'
                        className='w-5 h-5 align-middle hover:cursor-pointer rounded-md text-secondary'
                        {...field}
                        id={option.value}
                        value={option.value}
                        checked={field.value.includes(option.value)}
                        disabled={disabled}
                    />
                    <label className='pl-4 align-middle hover:cursor-pointer' htmlFor={option.value}>
                        {option.label}
                    </label>
                </div>
            ))}
            <FormError meta={meta} name={name} />
        </div>
    );
}
