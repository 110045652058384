import i18n, { TFunction } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { setLocale } from 'yup';
import { enUS, nl } from 'date-fns/locale';
import { format as formatDate, Locale } from 'date-fns';

const localeMap: { [key: string]: Locale } = {
    en: enUS,
    nl: nl,
};

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        backend: {
            queryStringParams: { v: window.env.REACT_APP_VERSION },
        },
        fallbackLng: 'nl',
        debug: process.env.NODE_ENV === 'development',
        ns: ['domain', 'common', 'partner', 'validation'],
        defaultNS: 'domain',
        interpolation: {
            formatSeparator: ',',
            format: function (value, formatting, lng) {
                const locale = localeMap[lng!];
                return formatDate(value, formatting!, { locale });
            },
        },
    })
    .then();

export function setYupLocale(t: TFunction) {
    const formatParams = {
        date: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' },
    };
    setLocale({
        mixed: {
            default: t('validation:mixed.invalid'),
            defined: t('validation:mixed.defined'),
            notOneOf: it => t('validation:mixed.notOneOf', { values: it.values }),
            oneOf: it => t('validation:mixed.oneOf', { values: it.values }),
            required: t('validation:mixed.required'),
        },
        string: {
            email: t('validation:string.email'),
            length: it => t('validation:string.length', { count: it.length }),
            lowercase: t('validation:string.lowercase'),
            matches: t('validation:string.matches'),
            max: it => t('validation:string.max', { count: it.max }),
            min: it => t('validation:string.min', { count: it.min }),
            trim: t('validation:string.trim'),
            uppercase: t('validation:string.uppercase'),
            url: t('validation:string.url'),
            uuid: t('validation:string.uuid'),
        },
        number: {
            integer: t('validation:number.integer'),
            lessThan: it => t('validation:number.lessThan', { count: it.less }),
            max: it => t('validation:number.max', { count: it.max }),
            min: it => t('validation:number.min', { count: it.min }),
            moreThan: it => t('validation:number.moreThan', { count: it.more }),
            negative: t('validation:number.negative'),
            positive: t('validation:number.positive'),
        },
        date: {
            max: it => t('validation:date.max', { date: new Date(it.max), formatParams }),
            min: it => t('validation:date.min', { date: new Date(it.min), formatParams }),
        },
        object: {
            noUnknown: t('validation:object.noUnknown'),
        },
        array: {
            length: it => t('validation:array.length', { count: it.length }),
            max: it => t('validation:array.max', { count: it.max }),
            min: it => t('validation:array.min', { count: it.min }),
        },
        boolean: {
            isValue: it => it.value,
        },
    });
}
