import { TFunction } from 'i18next';
import {
    IntakeVoortgang,
    IntakeVoortgangItem,
    IntakeVraagAntwoord,
    PhaseState,
    PhaseType,
} from '../../../generated/softwarematching-api';

export function hasAntwoorden(intakeVraag?: IntakeVraagAntwoord | null): boolean {
    return !!intakeVraag?.antwoorden && intakeVraag.antwoorden.length > 0;
}

export function findCurrentIntakeVoortgangItem(intakeVoortgang: IntakeVoortgang): IntakeVoortgangItem {
    return intakeVoortgang.items.find(item => item.state === PhaseState.IN_PROGRESS)!!;
}

export function findBedrijfsactiviteitVraaggroepById(intakeVoortgang: IntakeVoortgang, id: string) {
    return intakeVoortgang.phases
        ?.find(phase => phase.phaseType === PhaseType.BEDRIJFSACTIVITEIT_VRAAGGROEPEN)
        ?.vraaggroepen?.find(vg => vg.id === id);
}

export function findFunctionaliteitVraaggroepById(intakeVoortgang: IntakeVoortgang, id: string) {
    return intakeVoortgang.phases
        ?.find(phase => phase.phaseType === PhaseType.FUNCTIONALITEITSGROEP_VRAAGGROEPEN)
        ?.vraaggroepen?.find(vg => vg.id === id);
}

export function phaseTypeToText(phaseType: PhaseType, t: TFunction): string {
    switch (phaseType) {
        case 'SOFTWARE_TYPE_KIEZEN':
            return t('intake.intakePhase.SOFTWARE_TYPE_KIEZEN');
        case 'CONTACTGEGEVENS':
            return t('intake.intakePhase.CONTACTGEGEVENS');
        case 'BEDRIJFSACTIVITEIT_VRAAGGROEPEN':
            return t('intake.intakePhase.BEDRIJFSACTIVITEIT_VRAAGGROEPEN');
        case 'BEDRIJFSACTIVITEIT_KIEZEN':
            return t('intake.intakePhase.BEDRIJFSACTIVITEIT_KIEZEN');
        case 'PROJECT':
            return t('intake.intakePhase.PROJECT');
        case 'BEDRIJFSPROFIEL':
            return t('intake.intakePhase.BEDRIJFSPROFIEL');
        case 'FUNCTIONALITEITSGROEP_VRAAGGROEPEN':
            return t('intake.intakePhase.FUNCTIONALITEITSGROEP_VRAAGGROEPEN');
        case 'AFSLUITING':
            return t('intake.intakePhase.AFSLUITING');
        case 'FUNCTIONALITEIT_CONTROLE':
            return t('intake.intakePhase.FUNCTIONALITEIT_CONTROLE');
    }
}
