import { useField } from 'formik';
import React from 'react';
import FormError from './FormError';
import FormLabel from './FormLabel';

type Props = {
    name: string;
    label: string;
    autoComplete?: 'on' | 'off';
    autoFocus?: boolean;
    disabled?: boolean;
    maxLength?: number;
    minLength?: number;
    required?: boolean;
    rows?: number;
    spellCheck?: boolean;
    placeholder?: string;
};

export default function FormFieldTextArea({
    name,
    label,
    autoComplete,
    autoFocus,
    disabled,
    maxLength,
    minLength,
    required,
    rows,
    spellCheck,
    placeholder,
}: Props) {
    const [field, meta] = useField(name);

    return (
        <div>
            <FormLabel htmlFor={name} label={label} required={required} />
            <textarea
                className={`block w-full rounded-md border-0 mt-2 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary ${
                    meta.touched && meta.error ? 'form-field-invalid' : ''
                }`}
                id={name}
                autoComplete={autoComplete}
                autoFocus={autoFocus}
                disabled={disabled}
                maxLength={maxLength}
                minLength={minLength}
                placeholder={placeholder}
                required={required}
                rows={rows}
                spellCheck={spellCheck}
                {...field}
                value={field.value || ''} // Handle null values from the backend
            />
            <FormError meta={meta} name={name} />
        </div>
    );
}
