import LoadingPage from './page/LoadingPage';
import { useEffect } from 'react';
import { useAuthWrapper } from '../hooks/auth-hooks';
import { useAuth } from 'react-oidc-context';

type Props = {
    children: JSX.Element;
};

export default function AppAuthentication(props: Props) {
    const auth = useAuth();
    const { logout } = useAuthWrapper();

    useEffect(() => {
        return auth.events.addSilentRenewError(cb => {
            console.error('silentRenewError', cb);
            // Logout the user if we encounter a refresh error, to prevent an invalid login state
            logout();
        });
    }, [auth, logout]);

    // Handle Automatic sign-in https://github.com/authts/react-oidc-context#automatic-sign-in
    switch (auth.activeNavigator) {
        case 'signinSilent':
        case 'signoutRedirect':
            return <LoadingPage />;
    }
    if (auth.error) {
        // Just log the error, we don't want to interrupt the intake
        console.error(auth.error);
    }
    if (auth.isLoading) {
        return <LoadingPage />;
    }

    return props.children;
}
