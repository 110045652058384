import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { IntakeVoortgang, IntakeVoortgangItem } from '../../../../generated/softwarematching-api';
import IntakePhase from '../IntakePhase';
import { useAuthWrapper } from '../../../../hooks/auth-hooks';
import { Link } from 'react-router-dom';

type Props = {
    intakeVoortgang: IntakeVoortgang;
    currentIntakeVoortgangItem: IntakeVoortgangItem;
};

export default function IntakeAfsluiting({ intakeVoortgang, currentIntakeVoortgangItem }: Props) {
    const { t } = useTranslation();
    const { isAuthenticated } = useAuthWrapper();

    return (
        <IntakePhase
            title={t('intake.intakePhase.AFSLUITING')}
            intakeVoortgang={intakeVoortgang}
            currentIntakeVoortgangItem={currentIntakeVoortgangItem}
            showNextButton={false}>
            <p>{t('domain:intake.afsluiting.verzondenTekst')}</p>
            {isAuthenticated && (
                <p className='pt-5'>
                    <Trans i18nKey='intake.afsluiting.loggedInTekst' t={t}>
                        In{' '}
                        <Link to='/portal/intakes' className='text-secondary'>
                            het intake overzicht
                        </Link>{' '}
                        kunt u de status van de intake bekijken
                    </Trans>
                </p>
            )}
            {!isAuthenticated && (
                <div>
                    <p className={'my-4'}>{t('domain:intake.afsluiting.callToActionTekst')}</p>
                </div>
            )}
        </IntakePhase>
    );
}
