import React from 'react';
import ProductAanbodBeherenForm from '../components/ProductAanbodBeherenForm';
import { Aanbieding } from '../../../generated/softwarematching-api';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../common/button/Button';
import Role from '../../../models/Role';
import { useAuthRoles } from '../../../hooks/auth-hooks';

export default function AanbodAddPage() {
    const { hasRole } = useAuthRoles();
    const { partnerId } = useParams();
    const { t } = useTranslation();

    const aanbieding: Aanbieding = {
        productId: '',
        naam: '',
        gebruikersVan: 0,
        medewerkersVan: 0,
        implementatiekostenVan: 0,
        valuta: 'EURO',
        talen: [],
        landen: [],
        bedrijfsactiviteitIds: [],
        addons: [],
        standaardaddons: [],
    };

    if (!partnerId) {
        return <div>{t('common:error.REQUEST_MISSING_PARAMETER')}</div>;
    }
    return (
        <>
            <Link relative={'path'} to={hasRole(Role.PartnerAdmin) ? '/admin/profiel/aanbod' : '../'}>
                <Button className='mb-4' variant='contained' color='primary' type='button'>
                    {t('common:back')}
                </Button>
            </Link>
            <h1 className='font-header text-3xl mb-5'>{t('partner:aanbod.toevoegenTitle')}</h1>
            <ProductAanbodBeherenForm
                partnerId={partnerId}
                aanbiedingId={null}
                aanbieding={aanbieding}
                nieuweAanbieding={true}></ProductAanbodBeherenForm>
        </>
    );
}
