import React from 'react';
import { useTranslation } from 'react-i18next';
import { FaBan, FaPlus, FaTimes } from 'react-icons/fa';
import Button from '../../../common/button/Button';
import { IntakeMatch } from '../../../generated/softwarematching-api';

type Props = {
    match: IntakeMatch;
    onRemoveMatch: (matchResultId: string) => void;
    onSelectMatch: (matchResultId: string) => void;
    isSelectedMatch: boolean;
};

const PartnerActionButton: React.FC<Props> = ({ isSelectedMatch, onRemoveMatch, onSelectMatch, match }) => {
    const { t } = useTranslation();
    const isPreviouslySelected = !(match.status === 'MATCHED');

    return isPreviouslySelected ? (
        <Button
            type='button'
            variant='contained'
            color='secondary'
            className='sm:w-72 w-full rounded-3xl flex items-center justify-center h-fit'
            disabled>
            {t('matchSelection.previouslySelected')} <FaBan className='ml-2' />
        </Button>
    ) : isSelectedMatch ? (
        <Button
            type='button'
            variant='contained'
            color='deselect'
            className='sm:w-72 w-full rounded-3xl flex items-center justify-center h-fit'
            dataTestId='match-deselect'
            onClick={() => onRemoveMatch(match.matchResultId)}>
            {t('matchSelection.partnerDeselect')} <FaTimes className='ml-2' />
        </Button>
    ) : (
        <Button
            type='button'
            variant='contained'
            color='secondary'
            className='sm:w-72 w-full rounded-3xl flex items-center justify-center h-fit'
            dataTestId='match-select'
            onClick={() => onSelectMatch(match.matchResultId)}>
            {t('matchSelection.partnerSelect')} <FaPlus className='ml-2' />
        </Button>
    );
};

export default PartnerActionButton;
