import { useField } from 'formik';
import React, { useMemo } from 'react';
import FormError from './FormError';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

type Props = {
    name: string;
    antwoordOpties: AntwoordOptieLink[];
    disabled?: boolean;
};

type AntwoordOptieLink = {
    id: string;
    tekst: string;
    beeindigtVraaggroep: boolean;
    volgnummer: number;
    link: string;
};

export default function FormFieldRadioGroupLink({ name, antwoordOpties, disabled }: Props) {
    const [field, meta] = useField(name);
    const { t } = useTranslation();

    const options = useMemo(
        () =>
            antwoordOpties.map(it => ({
                value: it.id,
                label: it.tekst,
                link: it.link,
            })) ?? [],
        [antwoordOpties]
    );

    return (
        <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
            {options.map(option => (
                <label key={option.value} className='p-4 flex border border-faded rounded cursor-pointer bg-gray-50'>
                    <input
                        type='radio'
                        className='w-5 h-5 text-secondary mt-1 mr-6'
                        {...field}
                        name={`${field.name}[0]`}
                        id={option.value}
                        value={option.value}
                        checked={field.value[0] === option.value}
                        disabled={disabled}
                    />
                    <div>
                        <span className='font-medium text-lg'>{option.label}</span>
                        <a className='flex items-center pt-2' href={option.link} rel='noreferrer' target='_blank'>
                            <span className='text-gray-500 hover:underline'>
                                {t('domain:intake.softwaretype.linkText', { softwaretype: option.label })}
                            </span>
                            <FaExternalLinkAlt size={20} className='pl-2 text-primary inline' />
                        </a>
                    </div>
                </label>
            ))}
            <FormError meta={meta} name={name} />
        </div>
    );
}
