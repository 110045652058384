import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import HeaderButton from '../../../common/header/HeaderButton';
import HeaderHamburger from '../../../common/header/HeaderHamburger';
import HeaderUserProfile from '../../../common/header/HeaderUserProfile';
import LoginButton from '../../../common/button/LoginButton';

export default function IntakeHeader() {
    const { t } = useTranslation();

    const menuItems = useMemo(
        () => (
            <>
                <HeaderButton to='/intake' children={t('intake.intake')} />
                <HeaderButton to='https://softwarematching.io/nl/erp-systeem/' children={'ERP'} openInNewTab={true} />
                <HeaderButton to='https://softwarematching.io/nl/crm-systeem/' children={'CRM'} openInNewTab={true} />
                <HeaderButton
                    to='https://softwarematching.io/nl/over-ons/'
                    children={t('overOns')}
                    openInNewTab={true}
                />
                <HeaderButton to='https://softwarematching.io/nl/nieuws/' children={t('nieuws')} openInNewTab={true} />
                <HeaderButton
                    to='https://softwarematching.io/nl/neem-contact-op-met/'
                    children={t('contact')}
                    openInNewTab={true}
                />
            </>
        ),
        [t]
    );

    return (
        <header>
            <nav className='h-24 px-4 container mx-auto flex justify-between items-stretch'>
                <HeaderHamburger>{menuItems}</HeaderHamburger>
                <div className='justify-start lg:w-full flex items-center'>
                    <Link to='https://softwarematching.io' target='_blank'>
                        <img src='/SoftwareMatchingLogo.svg' className='max-h-12' alt={t('logoSoftwareMatchingIo')} />
                    </Link>
                </div>
                <div className='hidden lg:flex items-stretch justify-center text-white text-xl font-light whitespace-nowrap py-2'>
                    {menuItems}
                </div>
                <div className='flex items-center justify-end lg:w-full py-2'>
                    <HeaderUserProfile />
                    <LoginButton />
                </div>
            </nav>
        </header>
    );
}
