import { Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Functionaliteitwens, KlantvraagFunctionaliteitGroep, Moscow } from '../../../generated/softwarematching-api';
import { showErrorResponse } from '../../../helpers/notify';
import { RadioMatrixOption } from '../../../common/form/FormFieldRadioMatrixGroup';
import FormFieldCollapsableRadioMatrixGroup, {
    RadioMatrixGroup,
} from '../../../common/form/FormFieldCollapsableRadioMatrixGroup';

type VraagValues = {
    [key: string]: Moscow | undefined;
};

type Props = {
    functionaliteitGroepen: KlantvraagFunctionaliteitGroep[];
    onSubmit: (wensen: Functionaliteitwens[]) => Promise<void>;
};

export default function FormControleVraag({ functionaliteitGroepen, onSubmit }: Props) {
    const { t } = useTranslation();

    const handleSubmit = useCallback(
        (values: VraagValues, { resetForm }: FormikHelpers<VraagValues>) => {
            const wensen: Functionaliteitwens[] = Object.keys(values).map(id => ({
                functionaliteitId: id,
                moscow: values[id]!,
            }));

            onSubmit(wensen)
                .then(() => resetForm())
                .catch(error => showErrorResponse(t('common:failedToSubmit'), error, t));
        },
        [onSubmit, t]
    );

    const initialValues = useMemo<VraagValues>(() => {
        return functionaliteitGroepen
            .flatMap(groep => groep.functionaliteiten)
            .reduce(
                (o, key) => ({
                    ...o,
                    [key.id]: key.moscow,
                }),
                {}
            );
    }, [functionaliteitGroepen]);

    const validationSchema = useMemo(
        () =>
            yup
                .object()
                .shape(
                    functionaliteitGroepen
                        .flatMap(groep => groep.functionaliteiten)
                        .reduce(
                            (o, key) => ({ ...o, [key.id]: yup.string().required(t('intake.youHaveToAnswer')) }),
                            {}
                        )
                ),
        [t, functionaliteitGroepen]
    );

    const radioMatrixGroups = useMemo<RadioMatrixGroup[]>(() => {
        return functionaliteitGroepen.map(groep => ({
            label: groep.naam,
            rows: groep.functionaliteiten.map(it => ({ label: it.naam, id: it.id })),
        }));
    }, [functionaliteitGroepen]);

    const options = useMemo<RadioMatrixOption[]>(
        () => [
            {
                value: Moscow.MUST,
                label: t('moscow.MUST'),
            },
            {
                value: Moscow.SHOULD,
                label: t('moscow.SHOULD'),
            },
            {
                value: Moscow.WONT,
                label: t('moscow.WONT'),
            },
        ],
        [t]
    );

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}>
            <Form className='w-full' id='intake-form'>
                <h2 className='text-2xl font-light'>{t('intake.whichFunctionalitiesDoYouNeed')}</h2>
                <p className='mt-4'>{t('intake.functionalitiesHelperText')}</p>
                {radioMatrixGroups.map((group, index) => {
                    const validValues = group.rows.map(r => initialValues[r.id]).filter(value => value);
                    return (
                        <FormFieldCollapsableRadioMatrixGroup
                            key={index}
                            group={group}
                            defaultCollapsed={validValues.length === group.rows.length}
                            options={options}
                        />
                    );
                })}
            </Form>
        </Formik>
    );
}
