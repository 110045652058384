import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';

type Props = {
    disabled?: boolean;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    dataTestId?: string;
};

export default function DeleteButton({ disabled, onClick, dataTestId = 'delete-button' }: Props) {
    const { t } = useTranslation();

    return (
        <Button variant='contained' color='warning' disabled={disabled} dataTestId={dataTestId} onClick={onClick}>
            {t('common:delete')}
        </Button>
    );
}
