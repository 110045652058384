import React, { ReactNode } from 'react';
import { MenuItem, NavigationMenu } from '../../../common/layout/NavigationMenu';

type Props = {
    header: ReactNode;
    children: ReactNode;
    dataTestId?: string;
    menuItems: MenuItem[];
};

export default function AdminPageWithMenu({ header, children, dataTestId, menuItems }: Props) {
    return (
        <div className='flex flex-col min-max-h-dynamic-screen' data-testid={dataTestId}>
            {header}
            <div className='flex flex-row grow container mx-auto px-4 pb-4'>
                <NavigationMenu menuItems={menuItems} />
                {children}
            </div>
        </div>
    );
}
