import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Product, VendorApi } from '../../../generated/softwarematching-api';
import { useToken } from '../../../hooks/auth-hooks';
import { TokenizedService } from '../../../hooks/TokenizedService';

export default function useVendorService() {
    const token = useToken();
    const { i18n } = useTranslation();
    const vendorService = useMemo(() => new VendorService(i18n.language), [i18n.language]);
    useEffect(() => vendorService.updateToken(token), [token, vendorService]);
    return vendorService;
}

export class VendorService extends TokenizedService<VendorApi> {
    public constructor(language: string) {
        super(VendorApi, language);
    }

    getProductenFromAllVendors(): Promise<Product[]> {
        return this.api.getProductenFromAllVendors().then(response => response.data);
    }

    getVendors() {
        return this.api.getVendors().then(response => response.data);
    }
}
