import React from 'react';
import UnauthorizedPage from '../../../common/page/UnauthorizedPage';
import Gebruikerslist from '../components/Gebruikerslist';
import PartnerPageHeader from '../components/PartnerPageHeader';
import { useAuthPartnerId } from '../../../hooks/auth-hooks';

export default function ProfielGebruikersPage() {
    const partnerId = useAuthPartnerId();

    if (!partnerId) {
        return <UnauthorizedPage />;
    }
    return (
        <>
            <PartnerPageHeader></PartnerPageHeader>
            <Gebruikerslist partnerId={partnerId} />
        </>
    );
}
