import FormModal from '../../../common/modal/FormModal';
import { Size } from '../../../common/Size';
import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { LicenseCostUnit, Standaardaddon, StandaardaddonDefinitie } from '../../../generated/softwarematching-api';
import FormFieldSelect, { SelectOption } from '../../../common/form/FormFieldSelect';
import FormFieldCurrency from '../../../common/form/FormFieldCurrency';
import { MAX_BUDGET, MIN_BUDGET } from '../../../common/Constants';

type Props = {
    isEditing: boolean;
    standaardaddon: Standaardaddon;
    standaardaddonDefinities: StandaardaddonDefinitie[];
    onAdd: (standaardaddon: Standaardaddon) => void;
    onCancel: () => void;
};

export default function StandaardaddonFormModal({
    isEditing,
    standaardaddon,
    standaardaddonDefinities,
    onAdd,
    onCancel,
}: Props) {
    const { t } = useTranslation();

    const validationScheme = yup.object().shape({
        standaardaddonDefinitieId: yup.string().required().min(1),
        implementatiekostenVan: yup.number().required().min(MIN_BUDGET).max(MAX_BUDGET),
        implementatiekostenTot: yup
            .number()
            .min(MIN_BUDGET)
            .max(MAX_BUDGET)
            .moreThan(yup.ref('implementatiekostenVan'))
            .transform(value => (isNaN(value) ? undefined : value))
            .nullable(),
        licentiekosten: yup.number().required().min(0).max(MAX_BUDGET),
        licentiekostenEenheid: yup.string().required(),
    });

    const eenheidOptions: SelectOption[] = Object.keys(LicenseCostUnit).map<SelectOption>(lcu => ({
        value: lcu,
        label: t(`partner:licenseCostUnit.${lcu}`),
    }));

    const standaardaddonDefinitieOptions: SelectOption[] = standaardaddonDefinities.map<SelectOption>(
        standaadAddonDefinitie => ({
            value: standaadAddonDefinitie.id,
            label: standaadAddonDefinitie.naam,
        })
    );

    return (
        <FormModal
            title={isEditing ? t('partner:standaardaddon.edit') : t('partner:standaardaddon.add')}
            size={Size.Large}
            initialValues={standaardaddon}
            validationSchema={validationScheme}
            submitLabel={isEditing ? t('common:save') : t('common:add')}
            onSubmit={addon => onAdd(addon)}
            onCancel={() => onCancel()}
            dataTestId='standaardaddon-modal'>
            <div className='flex gap-5 flex-col'>
                <FormFieldSelect
                    name='standaardaddonDefinitieId'
                    label={t('partner:standaardaddon.naam')}
                    options={standaardaddonDefinitieOptions}
                    required
                    clearable
                    menuPosition='fixed'
                    dataTestId='standaardaddonDefinitie'
                />
                <div>
                    <h3 className='font-medium text-gray-600 mb-1'>
                        {t('partner:standaardaddon.implementatieKosten')}
                    </h3>
                    <div className='grid grid-cols-2 gap-4'>
                        <FormFieldCurrency
                            name='implementatiekostenVan'
                            label={t('partner:standaardaddon.min')}
                            required
                            min={0}
                        />
                        <FormFieldCurrency
                            name='implementatiekostenTot'
                            label={t('partner:standaardaddon.max')}
                            min={0}
                        />
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-4'>
                    <FormFieldCurrency
                        name='licentiekosten'
                        label={t('partner:standaardaddon.licentiekosten')}
                        required
                        min={0}
                    />
                    <FormFieldSelect
                        name='licentiekostenEenheid'
                        label={t('partner:standaardaddon.licentiekostenEenheid')}
                        options={eenheidOptions}
                        required
                        menuPosition='fixed'
                    />
                </div>
            </div>
        </FormModal>
    );
}
