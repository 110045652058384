import React from 'react';
import { IntakeVoortgang } from '../../../../generated/softwarematching-api';
import IntakeVoortgangIndicatorPhase from './IntakeVoortgangIndicatorPhase';

type Props = {
    intakeVoortgang: IntakeVoortgang;
};

export default function IntakeVoortgangIndicator({ intakeVoortgang }: Props) {
    return (
        <div className='flex flex-col gap-6'>
            {intakeVoortgang.phases.map(phase => (
                <IntakeVoortgangIndicatorPhase key={phase.phaseType} intakePhase={phase} />
            ))}
        </div>
    );
}
