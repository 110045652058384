import moment from 'moment';
/**
 * Returns a string matching the required format for input[date] elements
 */
export function dateToInputDate(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
}

export function stringDateToInputDate(date: string): string {
    return dateToInputDate(new Date(date));
}
