import React from 'react';
import { useTranslation } from 'react-i18next';

export default function NotFoundPage() {
    const { t } = useTranslation();
    return (
        <div className='min-h-screen flex text-center justify-center items-center flex-col bg-white'>
            <main>
                <h1 className='text-2xl pb-5'>{t('common:pageNotFound')}</h1>
            </main>
        </div>
    );
}
