import FormModal from '../../../common/modal/FormModal';
import { Size } from '../../../common/Size';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import FormFieldText from '../../../common/form/FormFieldText';
import {
    Addon,
    Functionaliteit,
    Functionaliteitgroep,
    FunctionaliteitOndersteuning,
    LicenseCostUnit,
} from '../../../generated/softwarematching-api';
import FormFieldSelect, { SelectOption } from '../../../common/form/FormFieldSelect';
import useFunctionaliteitService from '../hooks/useFunctionaliteitService';
import { makeCancellable } from '../../../helpers/promise';
import { showErrorResponse } from '../../../helpers/notify';
import AddonFunctionaliteitFieldArray from './AddonFunctionaliteitFieldArray';
import FormFieldCurrency from '../../../common/form/FormFieldCurrency';
import { TFunction } from 'i18next';
import { MAX_BUDGET, MIN_BUDGET } from '../../../common/Constants';

type Props = {
    isEditing: boolean;
    addon: Addon;
    onAdd: (addon: Addon) => void;
    onCancel: () => void;
};

export default function AddonFormModal({ isEditing, addon, onAdd, onCancel }: Props) {
    const functionaliteitService = useFunctionaliteitService();
    const { t } = useTranslation();
    const [functionaliteitenOptions, setFunctionaliteitenOptions] = useState<SelectOption[]>([]);
    const [functionaliteiten, setFunctionaliteiten] = useState<Functionaliteit[]>([]);

    useEffect(() => {
        const [promise, cancel] = makeCancellable(functionaliteitService.getFunctionaliteiten());
        promise
            .then(functionaliteiten => {
                setFunctionaliteiten(functionaliteiten.flatMap(group => group.functionaliteiten));
                setFunctionaliteitenOptions(functionaliteitenToSelectOptions(functionaliteiten, t));
            })
            .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t));
        return cancel;
    }, [functionaliteitService, t]);

    const validationScheme = yup.object().shape({
        naam: yup.string().required().min(1),
        implementatiekostenVan: yup.number().required().min(MIN_BUDGET).max(MAX_BUDGET),
        implementatiekostenTot: yup
            .number()
            .min(MIN_BUDGET)
            .max(MAX_BUDGET)
            .moreThan(yup.ref('implementatiekostenVan'))
            .transform(value => (isNaN(value) ? undefined : value))
            .nullable(),
        licentiekosten: yup.number().required().min(0).max(MAX_BUDGET),
        licentiekostenEenheid: yup.string().required(),
        functionaliteiten: yup
            .array(
                yup.object().shape({
                    functionaliteitId: yup.string().required(),
                    ondersteuning: yup
                        .string()
                        .required()
                        .when(['functionaliteitId'], ([functionaliteitId], schema) => {
                            const functionaliteit = functionaliteiten.find(f => f.id === functionaliteitId);
                            return functionaliteit?.isKnockout
                                ? schema.notOneOf(
                                      [FunctionaliteitOndersteuning.DEELS],
                                      t('partner:addon.DeelsNotAllowed')
                                  )
                                : schema;
                        }),
                })
            )
            .required()
            .min(1),
    });

    const eenheidOptions: SelectOption[] = Object.keys(LicenseCostUnit).map<SelectOption>(lcu => ({
        value: lcu,
        label: t(`partner:licenseCostUnit.${lcu}`),
    }));

    return (
        <FormModal
            title={isEditing ? t('partner:addon.edit') : t('partner:addon.add')}
            size={Size.Large}
            initialValues={addon}
            validationSchema={validationScheme}
            submitLabel={isEditing ? t('common:save') : t('common:add')}
            onSubmit={addon => onAdd(addon)}
            onCancel={() => onCancel()}
            dataTestId='addon-modal'>
            <div className='flex gap-5 flex-col'>
                <FormFieldText name='naam' label={t('partner:addon.naam')} type='text' required />
                <div>
                    <h3 className='font-medium text-gray-600 mb-1'>{t('partner:addon.implementatieKosten')}</h3>
                    <div className='grid grid-cols-2 gap-4'>
                        <FormFieldCurrency
                            name='implementatiekostenVan'
                            label={t('partner:addon.min')}
                            required
                            min={0}
                        />
                        <FormFieldCurrency name='implementatiekostenTot' label={t('partner:addon.max')} min={0} />
                    </div>
                </div>
                <div className='grid grid-cols-2 gap-4'>
                    <FormFieldCurrency
                        name='licentiekosten'
                        label={t('partner:addon.licentiekosten')}
                        required
                        min={0}
                    />
                    <FormFieldSelect
                        name='licentiekostenEenheid'
                        label={t('partner:addon.licentiekostenEenheid')}
                        options={eenheidOptions}
                        required
                        menuPosition='fixed'
                    />
                </div>
                <AddonFunctionaliteitFieldArray
                    name='functionaliteiten'
                    functionaliteitenOptions={functionaliteitenOptions}
                />
            </div>
        </FormModal>
    );
}

function functionaliteitenToSelectOptions(functionaliteiten: Functionaliteitgroep[], t: TFunction): SelectOption[] {
    return functionaliteiten.flatMap(group =>
        group.functionaliteiten.map<SelectOption>(f => {
            const groupDeprecated = group.deprecated ? ` (${t('common:deprecated')})` : '';
            const functionDeprecated = f.deprecated ? ` (${t('common:deprecated')})` : '';
            return {
                label: `${group.naam}${groupDeprecated} / ${f.naam}${functionDeprecated}`,
                value: f.id,
            };
        })
    );
}
