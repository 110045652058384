import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Aanbieding,
    AanbiedingRequest,
    Bedrijfsactiviteit,
    Contactgegevens,
    IntakeMatchMeeting,
    MatchDetail,
    MeetingAcceptRequest,
    MeetingAlternativeProposeRequest,
    MeetingDate,
    PartnerApi,
    PartnerGebruiker,
    PartnerMatch,
    Partnerprofiel,
    PartnerprofielRequest,
    StandaardaddonDefinitie,
} from '../../../generated/softwarematching-api';
import { useToken } from '../../../hooks/auth-hooks';
import { TokenizedService } from '../../../hooks/TokenizedService';

export default function usePartnerService() {
    const token = useToken();
    const { i18n } = useTranslation();
    const partnerService = useMemo(() => new PartnerService(i18n.language), [i18n.language]);
    useEffect(() => partnerService.updateToken(token), [token, partnerService]);
    return partnerService;
}

export class PartnerService extends TokenizedService<PartnerApi> {
    public constructor(language: string) {
        super(PartnerApi, language);
    }

    enrollPartner(partnerBedrijfsnaam: string, gebruikergegevens: Contactgegevens): Promise<Partnerprofiel> {
        return this.api
            .enrollPartnerprofiel({
                bedrijfsnaam: partnerBedrijfsnaam,
                gebruikergegevens: gebruikergegevens,
            })
            .then(response => response.data);
    }

    getAllPartnerprofielen(): Promise<Array<Partnerprofiel>> {
        return this.api.getPartnerprofielen().then(response => response.data);
    }

    getPartnerprofiel(partnerId: string): Promise<Partnerprofiel> {
        return this.api.getPartnerprofiel(partnerId).then(response => response.data);
    }

    updatePartnerprofiel(partnerId: string, profiel: PartnerprofielRequest): Promise<Partnerprofiel> {
        return this.api.updatePartnerprofiel(partnerId, profiel).then(response => response.data);
    }

    getGebruiker(partnerId: string, gebruikerId: string): Promise<PartnerGebruiker> {
        return this.api.getPartnerGebruiker(partnerId, gebruikerId).then(response => response.data);
    }

    getGebruikersForPartner(partnerId: string): Promise<Array<PartnerGebruiker>> {
        return this.api.getPartnerGebruikers(partnerId).then(response => response.data);
    }

    addGebruiker(partnerId: string, gebruikergegevens: Contactgegevens): Promise<PartnerGebruiker> {
        return this.api.postPartnerGebruiker(partnerId, gebruikergegevens).then(response => response.data);
    }

    resetGebruiker(partnerId: string, gebruikerId: string): Promise<void> {
        return this.api.resetPartnerGebruiker(partnerId, gebruikerId).then(response => response.data);
    }

    updateGebruiker(partnerId: string, gebruikerId: string, gegevens: Contactgegevens): Promise<PartnerGebruiker> {
        return this.api.putPartnerGebruiker(partnerId, gebruikerId, gegevens).then(response => response.data);
    }

    deleteGebruiker(partnerId: string, gebruikerId: string): Promise<void> {
        return this.api.deletePartnerGebruiker(partnerId, gebruikerId).then(response => response.data);
    }

    getAanbieding(partnerId: string, aanbiedingId: string): Promise<Aanbieding> {
        return this.api.getAanbieding(partnerId, aanbiedingId).then(response => response.data);
    }

    getAanbiedingen(partnerId: string): Promise<Aanbieding[]> {
        return this.api.getAanbiedingen(partnerId).then(response => response.data);
    }

    createAanbieding(partnerId: string, aanbiedingRequest: AanbiedingRequest): Promise<Aanbieding> {
        return this.api.addAanbieding(partnerId, aanbiedingRequest).then(response => response.data);
    }

    updateAanbieding(
        partnerId: string,
        aanbiedingId: string,
        aanbiedingRequest: AanbiedingRequest
    ): Promise<Aanbieding> {
        return this.api.updateAanbieding(partnerId, aanbiedingId, aanbiedingRequest).then(response => response.data);
    }

    deleteAanbieding(partnerId: string, aanbiedingId: string): Promise<void> {
        return this.api.deleteAanbieding(partnerId, aanbiedingId).then(response => response.data);
    }

    getBedrijfsactiviteiten(): Promise<Bedrijfsactiviteit[]> {
        return this.api.getBedrijfsactiviteiten().then(response => response.data);
    }

    getStandaardaddonDefinities(): Promise<StandaardaddonDefinitie[]> {
        return this.api.getStandaardaddonDefinities().then(response => response.data);
    }

    getPartnerMatches(partnerId: string): Promise<PartnerMatch[]> {
        return this.api.getPartnerMatches(partnerId).then(response => response.data);
    }

    getMatchDetail(matchResultId: string): Promise<MatchDetail> {
        return this.api.getMatchDetail(matchResultId).then(response => response.data);
    }

    getMeetingProposals(matchResultId: string): Promise<IntakeMatchMeeting> {
        return this.api.getMeetingProposals(matchResultId).then(response => response.data);
    }

    acceptMeeting(matchResultId: string, meetingAcceptRequest: MeetingAcceptRequest): Promise<MatchDetail> {
        return this.api.acceptMeeting(matchResultId, meetingAcceptRequest).then(response => response.data);
    }

    rejectMatch(matchResultId: string): Promise<void> {
        return this.api.rejectMatch(matchResultId).then(response => response.data);
    }

    getMeetingDateOptions(matchResultId: string): Promise<MeetingDate[]> {
        return this.api.getMeetingDateOptionsPartner(matchResultId).then(response => response.data);
    }

    createMeetingAlternativeProposal(
        matchResultId: string,
        meetingAlternativeProposeRequest: MeetingAlternativeProposeRequest
    ): Promise<MatchDetail> {
        return this.api
            .createMeetingAlternativeProposal(matchResultId, meetingAlternativeProposeRequest)
            .then(response => response.data);
    }
}
