import { useEffect, useMemo } from 'react';
import { SelectOption } from '../form/FormFieldSelect';
import { bedrijfsactiviteitenToSelectOptions } from './bedrijfsactiviteitenHelper';
import { useFormikContext } from 'formik';
import { Bedrijfsactiviteit } from '../../generated/softwarematching-api';
import { useTranslation } from 'react-i18next';

export function useBedrijfsactiviteitenSelection(
    bedrijfsactiviteiten: Bedrijfsactiviteit[],
    selectedSectorId: string | null,
    selectedBrancheId: string | null,
    selectedBedrijfsactiviteitenIds: string[]
) {
    const { setFieldValue } = useFormikContext();
    const { t } = useTranslation();

    const selectedSector = useMemo(
        () => bedrijfsactiviteiten.find(it => it.id === selectedSectorId),
        [bedrijfsactiviteiten, selectedSectorId]
    );

    const selectedBranche = useMemo(
        () => bedrijfsactiviteiten.flatMap(it => it.children).find(it => it.id === selectedBrancheId),
        [bedrijfsactiviteiten, selectedBrancheId]
    );

    const sectorOptions: SelectOption[] = useMemo(
        () => bedrijfsactiviteitenToSelectOptions(bedrijfsactiviteiten ?? [], t),
        [bedrijfsactiviteiten, t]
    );

    const brancheOptions: SelectOption[] = useMemo(
        () => bedrijfsactiviteitenToSelectOptions(selectedSector?.children ?? [], t),
        [selectedSector, t]
    );

    const subbrancheOptions: SelectOption[] = useMemo(() => {
        const subBranchesWithoutPreviousSelection = selectedBranche?.children.filter(subBranche => {
            return !selectedBedrijfsactiviteitenIds.includes(subBranche.id);
        });
        return bedrijfsactiviteitenToSelectOptions(subBranchesWithoutPreviousSelection ?? [], t);
    }, [selectedBedrijfsactiviteitenIds, selectedBranche, t]);

    useEffect(() => {
        setFieldValue('branche', null);
        setFieldValue('subbranches', []);
    }, [selectedSectorId, setFieldValue]);

    useEffect(() => {
        setFieldValue('subbranches', []);
    }, [selectedBrancheId, setFieldValue]);

    return { sectorOptions, brancheOptions, subbrancheOptions };
}
