import React, { ReactNode } from 'react';
import { Size } from '../Size';

type Props = {
    size: Size;
    children: ReactNode;
    dataTestId?: string;
    onBgClick?: () => void;
};

ModalPlain.defaultProps = {
    size: Size.Medium,
};

export default function ModalPlain({ children, size, dataTestId, onBgClick }: Props) {
    const sizeClasses = {
        large: 'max-w-5xl',
        medium: 'max-w-md',
        small: 'max-w-sm',
    }[size];

    return (
        <div
            className='fixed top-0 left-0 w-screen h-screen max-h-screen bg-black bg-opacity-50 z-50'
            onClick={onBgClick}>
            <div className='w-full h-screen flex flex-col justify-center'>
                <div
                    className={`mx-auto container bg-white rounded-lg flex flex-col max-h-full ${sizeClasses}`}
                    data-testid={dataTestId ?? 'modal'}
                    onClick={e => e.stopPropagation()}>
                    {children}
                </div>
            </div>
        </div>
    );
}
