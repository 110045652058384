import React, { useCallback, useEffect, useState } from 'react';
import usePartnerService from '../hooks/usePartnerService';
import { PartnerMatch } from '../../../generated/softwarematching-api';
import { useNavigate } from 'react-router-dom';
import { showErrorResponse } from '../../../helpers/notify';
import { useTranslation } from 'react-i18next';
import { makeCancellable } from '../../../helpers/promise';
import { stringDateToInputDate } from '../../../helpers/date';
import Loader from '../../../common/Loader';

type Props = {
    partnerId: string;
};

export default function MatchesList({ partnerId }: Props) {
    const { t } = useTranslation();
    const partnerService = usePartnerService();
    const [matches, setMatches] = useState<PartnerMatch[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const retrieveData = useCallback(() => {
        setLoading(true);

        const [promise, cancel] = makeCancellable(partnerService.getPartnerMatches(partnerId));
        promise
            .then(setMatches)
            .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t))
            .finally(() => setLoading(false));
        return cancel;
    }, [partnerService, partnerId, t]);

    useEffect(retrieveData, [retrieveData]);

    const matchesData = matches?.length ? (
        <table className='admin-table' data-testid='partner-matches-table'>
            <thead>
                <tr className='admin-table--header-row'>
                    <th className='admin-table--header-cell pl-4'>Product</th>
                    <th className='admin-table--header-cell'>Vendor</th>
                    <th className='admin-table--header-cell'>Software type</th>
                    <th className='admin-table--header-cell'>Datum van intake</th>
                    <th className='admin-table--header-cell'>Status</th>
                </tr>
            </thead>
            <tbody>
                {matches?.map((match, index) => (
                    <tr
                        key={index}
                        className='admin-table--body-row cursor-pointer'
                        onClick={() => navigate(match.matchResultId)}
                        data-testid={`view-${match.productNaam}`}>
                        <td className='admin-table--body-cell pl-4'>
                            <span className='block'>{match.productNaam}</span>
                            {match.productEigenNaam ? <span className='text-sm'>{match.productEigenNaam}</span> : ''}
                        </td>
                        <td className='admin-table--body-cell'>{match.vendor}</td>
                        <td className='admin-table--body-cell'>{match.softwareType.naam}</td>
                        <td className='admin-table--body-cell'>{stringDateToInputDate(match.datum)}</td>
                        <td className='admin-table--body-cell'>
                            <span className='badge badge-gray'>{t('partner:matchStatus.' + match.status)}</span>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    ) : (
        <p>{t('partner:matches.noMatches')}</p>
    );

    return (
        <>
            <h1 className='font-header text-3xl'>{t('partner:matches.title')}</h1>
            {loading ? <Loader /> : matchesData}
        </>
    );
}
