import {
    Functionaliteitwens,
    IntakeVoortgang,
    IntakeVoortgangItem,
    KlantvraagFunctionaliteitGroep,
} from '../../../../generated/softwarematching-api';
import { BrowseDirection } from '../../../../common/Constants';
import React, { useEffect, useState } from 'react';
import { makeCancellable } from '../../../../helpers/promise';
import useIntakeService from '../../hooks/useIntakeService';
import { showErrorResponse } from '../../../../helpers/notify';
import { useTranslation } from 'react-i18next';
import IntakePhase from '../IntakePhase';
import FormControleVraag from '../FormControleVraag';
import Loader from '../../../../common/Loader';

type Props = {
    intakeVoortgang: IntakeVoortgang;
    onUpdateIntakeVoortgang: (intakeVoortgang: IntakeVoortgang) => void;
    browse: (direction: BrowseDirection) => void;
    currentIntakeVoortgangItem: IntakeVoortgangItem;
};

export default function IntakeFunctionaliteitControle({
    browse,
    intakeVoortgang,
    onUpdateIntakeVoortgang,
    currentIntakeVoortgangItem,
}: Props) {
    const { t } = useTranslation();
    const intakeService = useIntakeService();
    const [loading, setLoading] = useState(true);
    const [functionaliteiten, setFunctionaliteiten] = useState<KlantvraagFunctionaliteitGroep[]>([]);
    const klantvraagId = intakeVoortgang.klantvraagId!!;

    useEffect(() => {
        setLoading(true);
        const [promise, cancel] = makeCancellable(intakeService.getFunctionaliteitwensen(klantvraagId));
        promise
            .then(setFunctionaliteiten)
            .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t))
            .finally(() => setLoading(false));
        return cancel;
    }, [intakeService, t, klantvraagId]);

    function handleSubmitControleVraag(wensen: Functionaliteitwens[]) {
        if (currentIntakeVoortgangItem.state === 'COMPLETED') {
            return intakeService.putFunctionaliteitwensen(klantvraagId, wensen).then(onUpdateIntakeVoortgang);
        } else {
            return intakeService.postFunctionaliteitwensen(klantvraagId, wensen).then(onUpdateIntakeVoortgang);
        }
    }

    return (
        <IntakePhase
            title={t('intake.intakePhase.FUNCTIONALITEIT_CONTROLE')}
            intakeVoortgang={intakeVoortgang}
            currentIntakeVoortgangItem={currentIntakeVoortgangItem}
            showPreviousButton
            showNextButton
            previousCallback={() => browse('previous')}>
            {loading ? (
                <Loader />
            ) : (
                <FormControleVraag functionaliteitGroepen={functionaliteiten} onSubmit={handleSubmitControleVraag} />
            )}
        </IntakePhase>
    );
}
