import { useField } from 'formik';
import React from 'react';

export type RadioMatrixOption = {
    value: string;
    label: string;
};

export type RadioMatrixRow = {
    id: string;
    label: string;
};

type Props = {
    options: RadioMatrixOption[];
    rows: RadioMatrixRow[];
};

export default function FormFieldRadioMatrixGroup({ options, rows }: Props) {
    return (
        <div className='sm:table sm:table-fixed w-full'>
            <div className='hidden sm:table-row text-center'>
                <div className='sm:table-cell sm:w-1/2 pb-2' />
                {options.map(option => (
                    <div key={option.value} className='sm:table-cell py-2'>
                        {option.label}
                    </div>
                ))}
            </div>
            {rows.map(row => (
                <FormFieldRadioMatrixRow key={row.id} options={options} row={row} />
            ))}
        </div>
    );
}

type FormFieldRadioMatrixRowProps = {
    options: RadioMatrixOption[];
    row: RadioMatrixRow;
};

function FormFieldRadioMatrixRow({ options, row }: FormFieldRadioMatrixRowProps) {
    const [field, meta] = useField(row.id);
    const error = meta.touched && meta.error;

    return (
        <>
            <div className='sm:hidden pt-5 pb-2 px-4 font-bold'>{row.label}</div>
            <div
                className={`${
                    error ? 'bg-warning bg-opacity-25' : ''
                } flex flex-col sm:table-row font-light sm:shadow-sm sm:shadow-faded border border-faded border-x-0 sm:border-none sm:rounded-none`}
                role='radiogroup'
                aria-label={row.label}>
                <span className='hidden sm:table-cell p-4'>{row.label}</span>
                {options.map(option => (
                    <label
                        key={option.value}
                        className='sm:table-cell px-4 sm:px-0 py-4 text-center flex flex-row-reverse gap-4 justify-end border-b sm:border-b-0 border-faded last:border-b-0 sm:bg-transparent hover:cursor-pointer'>
                        <span className='sm:hidden'>{option.label}</span>
                        <input
                            type='radio'
                            className='w-5 h-5 align-middle text-secondary hover:cursor-pointer'
                            {...field}
                            name={field.name}
                            id={option.value}
                            value={option.value}
                            checked={field.value === option.value}
                        />
                    </label>
                ))}
            </div>
        </>
    );
}
