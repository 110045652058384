import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FormFieldText from '../../../common/form/FormFieldText';
import useVendorService from '../hooks/useVendorService';
import { makeCancellable } from '../../../helpers/promise';
import { showErrorResponse } from '../../../helpers/notify';
import {
    AanbiedingRequest,
    Addon,
    Bedrijfsactiviteit,
    Country,
    FunctionaliteitOndersteuning,
    Language,
    Product,
    Standaardaddon,
    StandaardaddonDefinitie,
    Vendor,
} from '../../../generated/softwarematching-api';
import { useTranslation } from 'react-i18next';
import usePartnerService from '../hooks/usePartnerService';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import Loader from '../../../common/Loader';
import FormFieldSelect, { SelectOption } from '../../../common/form/FormFieldSelect';
import SubmitButton from '../../../common/button/SubmitButton';
import FormFieldNumber from '../../../common/form/FormFieldNumber';
import BedrijfsactiviteitenCurrentSelectionForm from '../../../common/bedrijfsactiviteiten/BedrijfsactiviteitenCurrentSelectionForm';
import BedrijfsactiviteitenSelectieFormModal from './BedrijfsactiviteitenSelectieFormModal';
import { FormikProps } from 'formik/dist/types';
import { useNavigate } from 'react-router-dom';
import AddonFormModal from './AddonFormModal';
import AddonListField from './AddonListField';
import FormFieldCurrency from '../../../common/form/FormFieldCurrency';
import StandaardaddonFormModal from './StandaardaddonFormModal';
import StandaardaddonListField from './StandaardaddonListField';
import { MAX_BUDGET, MIN_BUDGET } from '../../../common/Constants';
import DeleteButton from '../../../common/button/DeleteButton';
import PartnerConfirmationModal from '../modals/PartnerConfirmationModal';

type Props = {
    partnerId: string;
    aanbieding: AanbiedingRequest;
    aanbiedingId: string | null;
    nieuweAanbieding: boolean;
};

export default function ProductAanbodBeherenForm({ partnerId, aanbiedingId, aanbieding, nieuweAanbieding }: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const vendorService = useVendorService();
    const partnerService = usePartnerService();

    const [producten, setProducten] = useState<Product[]>([]);
    const [standaardaddonDefinities, setStandaardaddonDefinities] = useState<StandaardaddonDefinitie[]>([]);
    const [vendors, setVendors] = useState<Vendor[]>([]);
    const [loading, setLoading] = useState(true);
    const [bedrijfsactiviteiten, setBedrijfsactiviteiten] = useState<Bedrijfsactiviteit[]>([]);
    const [showBedrijfsactiviteitModal, setShowBedrijfsactiviteitModal] = useState(false);
    const { addonModalState, clearAddonModal, showAddAddonModal, showEditAddonModal } = useAddonModalState();
    const {
        standaardaddonModalState,
        clearStandaardaddonModal,
        showAddStandaardaddonModal,
        showEditStandaardaddonModal,
    } = useStandaardaddonModalState();
    const { partnerConfirmationModal, clearPartnerConfirmationModal, showPartnerConfirmationModal } =
        usePartnerConfirmationModalState();
    const formRef = useRef<FormikProps<AanbiedingRequest>>(null);

    const validationScheme = yup.object().shape({
        productId: yup.string().required(),
        naam: yup.string().optional(),
        landen: yup.array().required().of(yup.string()).min(1),
        talen: yup.array().required().of(yup.string()).min(1),
        gebruikersVan: yup.number().min(0).required(),
        gebruikersTot: yup
            .number()
            .min(0)
            .moreThan(yup.ref('gebruikersVan'))
            .transform(value => (isNaN(value) ? undefined : value))
            .nullable(),
        medewerkersVan: yup.number().min(0).required(),
        medewerkersTot: yup
            .number()
            .min(0)
            .moreThan(yup.ref('medewerkersVan'))
            .transform(value => (isNaN(value) ? undefined : value))
            .nullable(),
        implementatiekostenVan: yup.number().min(MIN_BUDGET).max(MAX_BUDGET).required(),
        implementatiekostenTot: yup
            .number()
            .min(MIN_BUDGET)
            .max(MAX_BUDGET)
            .moreThan(yup.ref('implementatiekostenVan'))
            .transform(value => (isNaN(value) ? undefined : value))
            .nullable(),
        bedrijfsactiviteitIds: yup.array().of(yup.string()).required().min(1),
        addons: yup.array(),
        standaardaddons: yup.array(),
    });

    useEffect(() => {
        const [promise, cancel] = makeCancellable(
            Promise.all([
                partnerService.getBedrijfsactiviteiten(),
                vendorService.getProductenFromAllVendors(),
                vendorService.getVendors(),
                partnerService.getStandaardaddonDefinities(),
            ])
        );
        promise
            .then(([bedrijfsactiviteiten, producten, vendors, standaardaddonDefinities]) => {
                setBedrijfsactiviteiten(bedrijfsactiviteiten);
                setProducten(producten);
                setVendors(vendors);
                setStandaardaddonDefinities(standaardaddonDefinities);
            })
            .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t))
            .finally(() => setLoading(false));
        return cancel;
    }, [partnerService, vendorService, t]);

    const handleSubmit = useCallback(
        (aanbiedingRequest: AanbiedingRequest) => {
            const promise = aanbiedingId
                ? partnerService.updateAanbieding(partnerId, aanbiedingId, aanbiedingRequest)
                : partnerService.createAanbieding(partnerId, aanbiedingRequest);

            promise.then(() => navigate(-1)).catch(error => showErrorResponse(t('common:failedToUpdate'), error, t));
        },
        [partnerService, t, partnerId, aanbiedingId, navigate]
    );

    const productOptions: SelectOption[] = useMemo(() => {
        return vendors.flatMap(v => {
            const productenForVendor = producten.filter(p => p.vendorId === v.id);

            return productenForVendor.map(p => {
                const deprecatedString = p.deprecated ? ` (${t('common:deprecated')})` : '';
                return {
                    value: p.id,
                    label: v.naam + ' - ' + p.naam + deprecatedString,
                };
            });
        });
    }, [vendors, producten, t]);

    const landOptions: SelectOption[] = Object.keys(Country).map<SelectOption>(c => ({
        value: c,
        label: t(`country.${c}`),
    }));

    const taalOptions: SelectOption[] = Object.keys(Language).map<SelectOption>(c => ({
        value: c,
        label: t(`language.${c}`),
    }));

    function getChoosableStandaardaddonDefinities(standaardaddon: Standaardaddon) {
        const standaardadddonsInUse =
            formRef.current?.values.standaardaddons.map(standaardaddon => standaardaddon.standaardaddonDefinitieId) ||
            [];
        return standaardaddonDefinities.filter(
            standaardaddonDefinitie =>
                !standaardadddonsInUse.includes(standaardaddonDefinitie.id) ||
                standaardaddon.standaardaddonDefinitieId === standaardaddonDefinitie.id
        );
    }

    const deleteAanbieding = useCallback(
        (partnerId: string, aanbiedingId: string) => {
            const promise = partnerService.deleteAanbieding(partnerId, aanbiedingId);

            promise
                .then(() => navigate(`/admin/profiel/aanbod`))
                .catch(error => showErrorResponse(t('common:failedToUpdate'), error, t));
        },
        [partnerService, t, navigate]
    );

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Formik<AanbiedingRequest>
                initialValues={aanbieding}
                enableReinitialize={true}
                validationSchema={validationScheme}
                innerRef={formRef}
                onSubmit={values => {
                    const castValues = validationScheme.cast(values) as AanbiedingRequest;
                    handleSubmit(castValues);
                }}>
                <Form noValidate>
                    <div className='p-5 mb-5 shadow-lg rounded-md'>
                        <div className='w-100 mb-5'>
                            <FormFieldSelect
                                name='productId'
                                label={t('partner:aanbod.product')}
                                options={productOptions}
                                required
                            />
                        </div>
                        <div className='w-100 mb-5'>
                            <FormFieldText type='text' name='naam' label={t('partner:aanbod.naam')} />
                        </div>
                        <div className='grid grid-cols-2 gap-4'>
                            <FormFieldSelect
                                name='landen'
                                label={t('partner:aanbod.landen')}
                                options={landOptions}
                                required
                                multiple={true}
                                closeMenuOnSelect={false}
                            />
                            <FormFieldSelect
                                name='talen'
                                label={t('partner:aanbod.taal')}
                                options={taalOptions}
                                required
                                multiple={true}
                                closeMenuOnSelect={false}
                            />
                        </div>
                        <div>
                            <h3 className='font-medium text-gray-600 mt-5 mb-1'>
                                {t('partner:aanbod.bedrijfsactiviteiten')}
                            </h3>
                        </div>
                        <div>
                            <BedrijfsactiviteitenCurrentSelectionForm
                                bedrijfsactiviteiten={bedrijfsactiviteiten}
                                name='bedrijfsactiviteitIds'
                                onAdd={() => setShowBedrijfsactiviteitModal(true)}
                                compactMode={false}
                            />
                        </div>
                        <div>
                            <h3 className='font-medium text-gray-600 mt-5 mb-1'>{t('partner:aanbod.gebruikers')}</h3>
                        </div>
                        <div className='grid grid-cols-3 gap-4'>
                            <FormFieldNumber name='gebruikersVan' label={t('partner:aanbod.van')} required min={0} />
                            <FormFieldNumber name='gebruikersTot' label={t('partner:aanbod.tot')} min={0} />
                        </div>
                        <div>
                            <h3 className='font-medium text-gray-600 mt-5 mb-1'>{t('partner:aanbod.medewerkers')}</h3>
                        </div>
                        <div className='grid grid-cols-3 gap-4'>
                            <FormFieldNumber name='medewerkersVan' label={t('partner:aanbod.van')} required min={0} />
                            <FormFieldNumber name='medewerkersTot' label={t('partner:aanbod.tot')} min={0} />
                        </div>
                        <div>
                            <h3 className='font-medium text-gray-600 mt-5 mb-1'>
                                {t('partner:aanbod.implementatiekosten')}
                            </h3>
                        </div>
                        <div className='grid grid-cols-3 gap-4'>
                            <FormFieldCurrency
                                name='implementatiekostenVan'
                                label={t('partner:aanbod.van')}
                                required
                                min={0}
                            />
                            <FormFieldCurrency name='implementatiekostenTot' label={t('partner:aanbod.tot')} min={0} />
                        </div>
                    </div>
                    <div className='flex gap-10 mt-4'>
                        <div className='w-1/2 flex gap-4 flex-col'>
                            <div className='p-5 mb-5 shadow-lg rounded-md'>
                                <h2 className='font-header text-xl mb-4'>{t('partner:aanbod.addons')}</h2>
                                <AddonListField
                                    name='addons'
                                    onAdd={() => showAddAddonModal()}
                                    onEdit={(a, index) => showEditAddonModal(a, index)}
                                />
                            </div>
                        </div>
                        <div className='w-1/2 flex gap-4 flex-col'>
                            <div className='p-5 mb-5 shadow-lg rounded-md'>
                                <h2 className='font-header text-xl mb-4'>{t('partner:aanbod.standaardaddons')}</h2>
                                <StandaardaddonListField
                                    name='standaardaddons'
                                    onAdd={() => showAddStandaardaddonModal()}
                                    onEdit={(a, index) => showEditStandaardaddonModal(a, index)}
                                    standaardaddonDefinities={standaardaddonDefinities}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mt-5 flex justify-end'>
                        {!nieuweAanbieding && (
                            <div className='mr-2'>
                                <DeleteButton disabled={false} onClick={() => showPartnerConfirmationModal()} />
                            </div>
                        )}

                        <SubmitButton label={t('common:save')} />
                    </div>
                </Form>
            </Formik>
            {showBedrijfsactiviteitModal && (
                <BedrijfsactiviteitenSelectieFormModal
                    bedrijfsactiviteiten={bedrijfsactiviteiten}
                    selected={formRef.current?.values.bedrijfsactiviteitIds || []}
                    onAdd={toAdd => {
                        const current = formRef.current?.values.bedrijfsactiviteitIds || [];
                        formRef.current?.setFieldValue('bedrijfsactiviteitIds', [...current, ...toAdd]);
                        setShowBedrijfsactiviteitModal(false);
                    }}></BedrijfsactiviteitenSelectieFormModal>
            )}
            {addonModalState.item && (
                <AddonFormModal
                    isEditing={addonModalState.isEditing}
                    addon={addonModalState.item}
                    onAdd={addon => {
                        let addons = formRef.current?.values.addons || [];
                        if (addonModalState.isEditing) {
                            addons[addonModalState.index] = addon;
                        } else {
                            addons = [...addons, addon];
                        }
                        formRef.current?.setFieldValue('addons', addons);
                        clearAddonModal();
                    }}
                    onCancel={() => clearAddonModal()}></AddonFormModal>
            )}
            {standaardaddonModalState.item && (
                <StandaardaddonFormModal
                    isEditing={standaardaddonModalState.isEditing}
                    standaardaddon={standaardaddonModalState.item}
                    standaardaddonDefinities={getChoosableStandaardaddonDefinities(standaardaddonModalState.item)}
                    onAdd={standaardaddon => {
                        let standaardaddons = formRef.current?.values.standaardaddons || [];
                        if (standaardaddonModalState.isEditing) {
                            standaardaddons[standaardaddonModalState.index] = standaardaddon;
                        } else {
                            standaardaddons = [...standaardaddons, standaardaddon];
                        }
                        formRef.current?.setFieldValue('standaardaddons', standaardaddons);
                        clearStandaardaddonModal();
                    }}
                    onCancel={() => clearStandaardaddonModal()}></StandaardaddonFormModal>
            )}
            {partnerConfirmationModal && (
                <div>
                    {(() => {
                        const product = producten.find(product => product.id === formRef.current?.values.productId);
                        const vendor = vendors.find(vendor => vendor.id === product?.vendorId);

                        return (
                            <PartnerConfirmationModal
                                onCancel={() => clearPartnerConfirmationModal()}
                                onDelete={() => deleteAanbieding(partnerId, aanbiedingId!!)}
                                product={product}
                                vendor={vendor}
                            />
                        );
                    })()}
                </div>
            )}
        </>
    );
}

const initialAddon: Addon = {
    naam: '',
    implementatiekostenVan: 0,
    implementatiekostenTot: null,
    licentiekosten: 0,
    licentiekostenEenheid: 'USER',
    functionaliteiten: [
        {
            functionaliteitId: '',
            ondersteuning: FunctionaliteitOndersteuning.VOLLEDIG,
        },
    ],
};

const initialStandaardaddon: Standaardaddon = {
    standaardaddonDefinitieId: '',
    implementatiekostenVan: 0,
    implementatiekostenTot: null,
    licentiekosten: 0,
    licentiekostenEenheid: 'USER',
};

type AddonEditState = {
    isEditing: boolean;
    item: Addon | null;
    index: number;
};

type StandaardaddonEditState = {
    isEditing: boolean;
    item: Standaardaddon | null;
    index: number;
};

function useAddonModalState() {
    const [addonModalState, setAddonModalState] = useState<AddonEditState>({ isEditing: false, item: null, index: -1 });

    function clearAddonModal() {
        setAddonModalState({ isEditing: false, item: null, index: -1 });
    }

    function showAddAddonModal() {
        setAddonModalState({ isEditing: false, item: { ...initialAddon }, index: -1 });
    }

    function showEditAddonModal(addon: Addon, index: number) {
        setAddonModalState({ isEditing: true, item: addon, index: index });
    }

    return { addonModalState, clearAddonModal, showAddAddonModal, showEditAddonModal };
}

function useStandaardaddonModalState() {
    const [standaardaddonModalState, setStandaardaddonModalState] = useState<StandaardaddonEditState>({
        isEditing: false,
        item: null,
        index: -1,
    });

    function clearStandaardaddonModal() {
        setStandaardaddonModalState({ isEditing: false, item: null, index: -1 });
    }

    function showAddStandaardaddonModal() {
        setStandaardaddonModalState({ isEditing: false, item: { ...initialStandaardaddon }, index: -1 });
    }

    function showEditStandaardaddonModal(standaardaddon: Standaardaddon, index: number) {
        setStandaardaddonModalState({ isEditing: true, item: standaardaddon, index: index });
    }

    return {
        standaardaddonModalState,
        clearStandaardaddonModal,
        showAddStandaardaddonModal,
        showEditStandaardaddonModal,
    };
}

function usePartnerConfirmationModalState() {
    const [partnerConfirmationModal, setPartnerConfirmationModal] = useState(false);

    function clearPartnerConfirmationModal() {
        setPartnerConfirmationModal(false);
    }

    function showPartnerConfirmationModal() {
        setPartnerConfirmationModal(true);
    }

    return { partnerConfirmationModal, clearPartnerConfirmationModal, showPartnerConfirmationModal };
}
