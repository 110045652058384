import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import { useAuthWrapper } from '../../hooks/auth-hooks';

export default function LoginLogoutButton() {
    const { login, logout, isAuthenticated } = useAuthWrapper();
    const { t } = useTranslation();

    return isAuthenticated ? (
        <Button onClick={() => logout()} variant='contained' color='secondary' dataTestId='logout-button'>
            {t('common:logout')}
        </Button>
    ) : (
        <Button onClick={() => login()} variant='contained' color='secondary' dataTestId='login-button'>
            {t('common:login')}
        </Button>
    );
}
