import PartnerEditForm from '../components/PartnerEditForm';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import PartnerPageHeader from '../components/PartnerPageHeader';

export default function PartnersAlgemeenPage() {
    const { t } = useTranslation();
    const { partnerId } = useParams();

    if (!partnerId) {
        return <div>{t('common:error.REQUEST_MISSING_PARAMETER')}</div>;
    }

    return (
        <>
            <PartnerPageHeader></PartnerPageHeader>
            <PartnerEditForm partnerId={partnerId} allowBedrijfsnaamActivationStatusChange={true} />
        </>
    );
}
