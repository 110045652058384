/* tslint:disable */
/* eslint-disable */
/**
 * Software Matching Api
 * Software Matching API for the match-making questionnaire service
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Enumeration of the license cost unit
 * @export
 * @enum {string}
 */

export const LicenseCostUnit = {
    USER: 'USER',
    CONCURRENT_USER: 'CONCURRENT_USER',
    MONTH: 'MONTH',
    QUARTER: 'QUARTER',
    YEAR: 'YEAR',
    ONE_TIME: 'ONE_TIME'
} as const;

export type LicenseCostUnit = typeof LicenseCostUnit[keyof typeof LicenseCostUnit];



