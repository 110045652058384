/* tslint:disable */
/* eslint-disable */
/**
 * Software Matching Api
 * Software Matching API for the match-making questionnaire service
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Enumeration of how well the functionality is supported
 * @export
 * @enum {string}
 */

export const FunctionaliteitOndersteuning = {
    VOLLEDIG: 'VOLLEDIG',
    DEELS: 'DEELS',
    NIET: 'NIET'
} as const;

export type FunctionaliteitOndersteuning = typeof FunctionaliteitOndersteuning[keyof typeof FunctionaliteitOndersteuning];



