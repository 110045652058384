import { IntakeMatch, IntakeMatchBedrijfsactiviteit, LicenseCostUnit } from '../../../generated/softwarematching-api';
import MatchDetailFunctionaliteitGroep from './MatchDetailFunctionaliteitGroep';
import * as React from 'react';
import { FaArrowRight, FaCheck, FaExclamation, FaTimes } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export type Props = {
    match: IntakeMatch;
};

export default function MatchDetails({ match }: Props) {
    const { t, i18n } = useTranslation();
    const numberFmt = new Intl.NumberFormat(i18n.resolvedLanguage);

    const branchSeparator = (extraClassNames: string) => (
        <FaArrowRight className={'h-4 w-4 mt-1 text-gray-500 ' + extraClassNames} />
    );

    const subbranche = (bedrijfsactiviteit: IntakeMatchBedrijfsactiviteit) =>
        bedrijfsactiviteit.subbranche && (
            <>
                {branchSeparator('mx-1')} {bedrijfsactiviteit.subbranche}
            </>
        );

    return (
        <>
            <div className='border-t border-gray-200 pt-4 pb-4'>
                <div className='portal-body--large lg:font-medium'>{t('matchSelection.implementationCostsLong')}</div>
                <div className='portal-body--small' data-testid='matchdetail-implementationcosts'>
                    €{numberFmt.format(match.budget.implementatiekosten)} {t('matchSelection.oneTime')}
                </div>
            </div>
            <div className='border-t border-gray-200 pt-4 pb-4'>
                <div className='portal-body--large lg:font-medium'>{t('matchSelection.licenseCostsLong')}</div>
                <div className='portal-body--small' data-testid='matchdetail-licensecosts'>
                    €{numberFmt.format(match.budget.licentiekosten)} {t('matchSelection.perMonth')}
                </div>
                {match.budget.eenheden.includes(LicenseCostUnit.CONCURRENT_USER) && (
                    <div className='portal-body--small text-gray-500' data-testid='matchdetail-concurrentuser-hint'>
                        {t('matchSelection.concurrentUserHint')}
                    </div>
                )}
            </div>
            <div className='border-t border-gray-200 pt-4 pb-4'>
                <div className='portal-body--large lg:font-medium'>{t('matchSelection.functionaliteiten')}</div>
                <div className='portal-body--small'>
                    <div className='sm:inline'>
                        <FaCheck className={'inline text-primary'} />
                        <span className='inline mx-2'>{t('matchSelection.legend.completely_covered')}</span>
                    </div>
                    <div className='sm:inline'>
                        <FaExclamation className={'inline text-secondary'} />
                        <span className='inline mx-2'>{t('matchSelection.legend.partly_covered')}</span>
                    </div>
                    <div className='sm:inline'>
                        <FaTimes className={'inline text-secondary'} />
                        <span className='inline mx-2'>{t('matchSelection.legend.not_covered')}</span>
                    </div>
                </div>
            </div>
            <div className='border-t border-gray-200 pt-4 pb-4'>
                <div className='grid grid-cols-1 lg:grid-cols-2 gap-5' data-testid='matchdetail-functionaliteiten'>
                    {match.functionaliteiten.map(functionaliteitGroep => (
                        <MatchDetailFunctionaliteitGroep
                            functionaliteitGroep={functionaliteitGroep}
                            key={functionaliteitGroep.naam}
                        />
                    ))}
                </div>
            </div>
            <div className='border-t border-gray-200 pt-4 pb-4'>
                <div className='portal-body--large lg:font-medium'>{t('matchSelection.bedrijfsactiviteiten')}</div>
                <div className='portal-body--small' data-testid='matchdetail-bedrijfsactiviteiten'>
                    {match.partner.bedrijfsactiviteiten.map(bedrijfsactiviteit => (
                        <div
                            key={
                                bedrijfsactiviteit.sector + bedrijfsactiviteit.branche + bedrijfsactiviteit.subbranche
                            }>
                            <div className='flex lg:hidden'>
                                {branchSeparator('mr-2')}{' '}
                                {bedrijfsactiviteit.subbranche
                                    ? bedrijfsactiviteit.subbranche
                                    : bedrijfsactiviteit.branche}
                            </div>
                            <div className='flex hidden lg:flex'>
                                {bedrijfsactiviteit.sector} {branchSeparator('mx-1')} {bedrijfsactiviteit.branche}{' '}
                                {subbranche(bedrijfsactiviteit)}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='portal-body--small text-gray-500 pt-4 pb-4'>{t('matchSelection.pricinghint')}</div>
        </>
    );
}
