import React, { ReactNode } from 'react';

type Props = {
    children: ReactNode;
    bgColor?: string;
};

export default function BottomBar({ children, bgColor }: Props) {
    return (
        <div
            data-testid='bottom-bar'
            className={'lg:hidden fixed bottom-0 right-0 py-3 lg:py-6 px-9 lg:px-20 w-screen ' + bgColor}>
            {children}
        </div>
    );
}
