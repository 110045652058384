import { useTranslation } from 'react-i18next';
import { Size } from '../../../common/Size';
import React from 'react';
import Modal from '../../../common/modal/Modal';
import ModalContent from '../../../common/modal/ModalContent';
import ModalFooter from '../../../common/modal/ModalFooter';
import DeleteButton from '../../../common/button/DeleteButton';
import ModalContentPlain from '../../../common/modal/ModalContentPlain';
import CancelButton from '../../../common/button/CancelButton';
import { Product, Vendor } from '../../../generated/softwarematching-api';

type Props = {
    onCancel: () => void;
    onDelete: () => void;
    product: Product | undefined;
    vendor: Vendor | undefined;
};

export default function PartnerConfirmationModal({ onCancel, onDelete, product, vendor }: Props) {
    const { t } = useTranslation();

    return (
        <>
            <Modal dataTestId={'form-modal'} size={Size.Large}>
                <ModalContent>
                    <ModalContentPlain>
                        <div className='text-gray-500 pt-4 pb-4 text-2xl'>
                            {t('common:confirmationDeletion', {
                                product: product?.naam,
                                vendor: vendor?.naam,
                            })}
                        </div>
                        <ModalFooter>
                            <CancelButton disabled={false} onClick={() => onCancel()} />
                            <DeleteButton onClick={() => onDelete()} dataTestId={'delete-button-confirmation'} />
                        </ModalFooter>
                    </ModalContentPlain>
                </ModalContent>
            </Modal>
            )
        </>
    );
}
