import { useField } from 'formik';
import React, { ReactNode } from 'react';
import FormError from './FormError';

type Props = {
    name: string;
    autoFocus?: boolean;
    disabled?: boolean;
    required?: boolean;
    children?: ReactNode;
};

export default function FormFieldCheckbox({ name, autoFocus, disabled, required, children }: Props) {
    const [field, meta] = useField(name);

    return (
        <div>
            <label className='flex items-start hover:cursor-pointer'>
                <input
                    className={`shrink-0 w-5 h-5 text-secondary rounded-md ${
                        meta.touched && meta.error ? 'form-field-invalid' : ''
                    }`}
                    id={name}
                    data-testid={name}
                    type='checkbox'
                    autoFocus={autoFocus}
                    disabled={disabled}
                    required={required}
                    checked={!!field.value}
                    {...field}
                />
                {children && <div className='ml-2'>{children}</div>}
            </label>
            <FormError meta={meta} name={name} />
        </div>
    );
}
