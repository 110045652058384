import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import HeaderUserProfile from '../../../common/header/HeaderUserProfile';

export default function AdminHeader() {
    const { t } = useTranslation();

    return (
        <header>
            <div className='h-24 px-4 container mx-auto flex justify-between items-stretch'>
                <div className='justify-start flex items-center'>
                    <Link to='/admin'>
                        <img src='/SoftwareMatchingLogo.svg' className='max-h-12' alt={t('logoSoftwareMatchingIo')} />
                    </Link>
                </div>
                <div className='flex items-center justify-end w-full py-2'>
                    <HeaderUserProfile />
                </div>
            </div>
        </header>
    );
}
