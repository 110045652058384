import React from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
    to: string;
    children: string;
    openInNewTab?: boolean;
};

export default function HeaderButton({ to, children, openInNewTab = false }: Props) {
    const target = openInNewTab ? '_blank' : '';

    return (
        <NavLink
            to={to}
            data-testid='header-button'
            target={target}
            className='px-4 py-2 flex items-center hover:underline underline-offset-2 text-primary'>
            {children}
        </NavLink>
    );
}
