import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FaChevronRight } from 'react-icons/fa';

export function NewIntake() {
    const { t } = useTranslation();

    return (
        <>
            <h2 className='portal-heading-2 mb-5'>{t('portal.newIntakeTitle')}</h2>

            <Link data-testid='new-intake' to='/intake/new'>
                <div className='hover:underline underline-offset-2 h-14 items-center flex justify-between'>
                    <div className='inline grow'>
                        <div className='portal-body--large lg:font-medium'>{t('portal.newIntakeSubTitle')}</div>
                        <div className='portal-body--large'>{t('portal.newIntakeContent')}</div>
                    </div>
                    <FaChevronRight className='text-primary cursor-pointer'></FaChevronRight>
                </div>
            </Link>
        </>
    );
}
