import * as React from 'react';
import { KnockoutPercentageByAssessmentType } from '../../../generated/softwarematching-api';
import { useTranslation } from 'react-i18next';

type Props = {
    knockoutPercentageByAssessmentType: KnockoutPercentageByAssessmentType[];
};

export default function MatchKnockoutExplanation({ knockoutPercentageByAssessmentType }: Props) {
    const { t } = useTranslation();
    const filteredKnockoutPercentageByAssessmentType = knockoutPercentageByAssessmentType
        .filter(it => it.percentage > 0)
        .filter(it => it.assessmentType !== 'UNIEKE_PARTNER')
        .sort((a, b) => b.percentage - a.percentage);

    return (
        <>
            <p data-testid='knockout-no-results' className='portal-body--large mb-2'>
                {t('matchSelection.noResultsText')}
            </p>
            <ul data-testid='knockout-explanations' className='list-disc pl-8'>
                {filteredKnockoutPercentageByAssessmentType.map(it => {
                    return (
                        <li className='portal-body--small' key={it.assessmentType}>
                            {t(`matchSelection.assessorsExplanation.${it.assessmentType}`, {
                                percentage: it.percentage,
                            })}
                        </li>
                    );
                })}
            </ul>
        </>
    );
}
