import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {
    domain: string;
};

export default function Placeholder({ domain }: Props) {
    const { t } = useTranslation();

    return (
        <div className='container lg:max-w-4xl mx-auto px-4'>
            <h1 className='portal-heading-1 mb-2.5'>{t('portal.placeholder.' + domain + '.placeholderHeading')}</h1>
            <p className='portal-body--large mb-6'>{t('portal.placeholder.' + domain + '.placeholderBody')}</p>
            <Link className='portal-body--link mb-6' to={'/'}>
                {t('portal.placeholder.' + domain + '.placeholderLink')}
            </Link>
        </div>
    );
}
