import React, { ReactNode } from 'react';
import { FaTimes } from 'react-icons/fa';

type Props = {
    children?: ReactNode;
    onClose: () => void;
};

export default function ModalHeaderPlain({ children, onClose }: Props) {
    return (
        <div className='p-5 flex justify-between gap-2'>
            <div>{children}</div>
            <FaTimes
                className='text-lg hover:cursor-pointer text-regular hover:fill-black active:text-warning transition-all'
                style={{ color: 'lightslategray' }}
                data-testid='exit-button'
                onClick={onClose}
            />
        </div>
    );
}
