import { IntakeMatchPartnerInfo } from '../../../generated/softwarematching-api';
import ExternalLink from '../../../common/ExternalLink';
import * as React from 'react';

type Props = {
    partner: IntakeMatchPartnerInfo;
};

export default function MatchDetailsPartnerContactInfo({ partner }: Props) {
    return (
        <>
            <div className='font-medium'>{partner.profiel.bedrijfsnaam}</div>
            <div>
                {partner.salesContact.voornaam} {partner.salesContact.achternaam}
            </div>
            {partner.salesContact.email && (
                <div>
                    <ExternalLink
                        link={'mailto:' + partner.salesContact.email}
                        linkText={partner.salesContact.email}></ExternalLink>
                </div>
            )}
            <div>{partner.salesContact.telefoonnummer}</div>
            <div>{partner.profiel.adres}</div>
            <div>
                {partner.profiel.postcode} {partner.profiel.stad}
            </div>
            {partner.profiel.website && (
                <div>
                    <ExternalLink link={partner.profiel.website} linkText={partner.profiel.website}></ExternalLink>
                </div>
            )}
        </>
    );
}
