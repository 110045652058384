import { Bedrijfsprofiel, Country, Language } from '../../../../generated/softwarematching-api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import FormFieldNumber from '../../../../common/form/FormFieldNumber';
import FormFieldSelect, { SelectOption } from '../../../../common/form/FormFieldSelect';
import FormFieldText from '../../../../common/form/FormFieldText';

type Props = {
    bedrijfsProfiel: Bedrijfsprofiel;
    onUpdateBedrijfsprofiel: (profiel: Bedrijfsprofiel) => void;
};

export function IntakeBedrijfsprofielForm({ bedrijfsProfiel, onUpdateBedrijfsprofiel }: Props) {
    const { t } = useTranslation();
    const validationScheme = yup.object().shape({
        bedrijfsnaam: yup.string().required().max(200),
        aantalGebruikers: yup.number().min(1).required().max(1_000_000_000),
        aantalMedewerkers: yup.number().min(1).required().max(1_000_000_000),
        land: yup.mixed<Country>().oneOf(Object.values(Country)).required(),
        voorkeurstalen: yup.array().of(yup.string()).required().min(1),
    });
    const taalOptions: SelectOption[] = Object.keys(Language).map<SelectOption>(c => ({
        value: c,
        label: t(`language.${c}`),
    }));
    const landOptions: SelectOption[] = Object.keys(Country).map<SelectOption>(c => ({
        value: c,
        label: t(`country.${c}`),
    }));

    return (
        <Formik
            enableReinitialize
            initialValues={bedrijfsProfiel}
            validationSchema={validationScheme}
            onSubmit={values => {
                const bedrijfsprofiel: Bedrijfsprofiel = {
                    ...bedrijfsProfiel,
                    ...values,
                };
                onUpdateBedrijfsprofiel(bedrijfsprofiel);
            }}>
            <Form className='flex gap-4 flex-col' id='intake-form' noValidate>
                <h2 className='text-2xl font-light'>{t('intake.bedrijfsprofiel.header')}</h2>
                <FormFieldText
                    type='text'
                    name='bedrijfsnaam'
                    label={t('intake.bedrijfsprofiel.bedrijfsnaam')}
                    required={true}
                    autocomplete='organization'
                />
                <FormFieldNumber
                    name='aantalGebruikers'
                    label={t('intake.bedrijfsprofiel.aantalGebruikers')}
                    required={true}
                    min={0}
                />
                <FormFieldNumber
                    name='aantalMedewerkers'
                    label={t('intake.bedrijfsprofiel.aantalMedewerkers')}
                    required={true}
                    min={0}
                />
                <FormFieldSelect
                    name='voorkeurstalen'
                    label={t('intake.bedrijfsprofiel.voorkeurstalen')}
                    options={taalOptions}
                    required
                    multiple={true}
                    closeMenuOnSelect={false}
                />
                <FormFieldSelect
                    name='land'
                    label={t('intake.bedrijfsprofiel.land')}
                    options={landOptions}
                    required={true}
                />
            </Form>
        </Formik>
    );
}
