import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { IntakeVoortgang, IntakeVoortgangItem } from '../../../../generated/softwarematching-api';
import { showErrorResponse } from '../../../../helpers/notify';
import { makeCancellable } from '../../../../helpers/promise';
import useIntakeService from '../../hooks/useIntakeService';
import Loader from '../../../../common/Loader';
import IntakePhase from '../IntakePhase';
import FormFieldRadioGroupLink from '../../../../common/form/FormFieldRadioGroupLink';

type ChooseSoftwareType = {
    antwoorden: string[] | null;
};

const initialValues: ChooseSoftwareType = {
    antwoorden: [],
};

type Props = {
    intakeVoortgang: IntakeVoortgang;
    currentIntakeVoortgangItem: IntakeVoortgangItem;
    onUpdateIntakeVoortgang: (intakeVoortgang: IntakeVoortgang) => void;
};

type AntwoordOptieLink = {
    id: string;
    tekst: string;
    beeindigtVraaggroep: boolean;
    volgnummer: number;
    link: string;
};

export default function IntakeSoftwareTypeKiezen({
    intakeVoortgang,
    currentIntakeVoortgangItem,
    onUpdateIntakeVoortgang,
}: Props) {
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const [antwoorden, setAntwoorden] = useState<ChooseSoftwareType>(initialValues);
    const [softwareTypes, setSoftwareTypes] = useState<AntwoordOptieLink[]>([]);
    const intakeService = useIntakeService();

    const handleSubmit = useCallback(
        (antwoorden: ChooseSoftwareType) => {
            intakeService
                .postSoftwareType(intakeVoortgang.klantvraagId!, antwoorden.antwoorden![0])
                .then(onUpdateIntakeVoortgang)
                .catch(error => showErrorResponse(t('common:failedToUpdate'), error, t))
                .finally(() => setLoading(false));
        },
        [intakeService, t, intakeVoortgang, onUpdateIntakeVoortgang]
    );

    useEffect(() => {
        const [promise, cancel] = makeCancellable(intakeService.getSoftwareTypes());
        promise
            .then(softwareTypes => {
                setSoftwareTypes(
                    softwareTypes.map<AntwoordOptieLink>((softwareType, index) => {
                        return {
                            id: softwareType.id,
                            tekst: softwareType.naam,
                            beeindigtVraaggroep: true,
                            volgnummer: index,
                            link: softwareType.link,
                        };
                    })
                );
            })
            .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t))
            .finally(() => setLoading(false));
        return cancel;
    }, [intakeService, t]);

    useEffect(() => {
        if (currentIntakeVoortgangItem.state === 'COMPLETED') {
            const [promise, cancel] = makeCancellable(intakeService.getSoftwareType(intakeVoortgang.klantvraagId));
            promise
                .then(softwareTypeId =>
                    setAntwoorden(
                        softwareTypeId?.softwareTypeId
                            ? { antwoorden: [softwareTypeId?.softwareTypeId] }
                            : { antwoorden: [''] }
                    )
                )
                .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t));
            return cancel;
        }
    }, [
        onUpdateIntakeVoortgang,
        intakeVoortgang,
        currentIntakeVoortgangItem,
        intakeService,
        t,
        intakeVoortgang.klantvraagId,
    ]);

    const validationSchema = yup.object({
        antwoorden: yup.array().required().min(1, t('intake.youHaveToAnswer')).of(yup.string().required()).required(),
    });

    return (
        <IntakePhase
            title={t('intake.intakePhase.SOFTWARE_TYPE_KIEZEN')}
            intakeVoortgang={intakeVoortgang}
            currentIntakeVoortgangItem={currentIntakeVoortgangItem}
            showPreviousButton={false}
            showNextButton>
            <Formik
                initialValues={antwoorden}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                <Form className='flex gap-4 flex-col' id='intake-form'>
                    {!loading && softwareTypes.length > 0 ? (
                        <>
                            <h2 className='text-2xl font-light'>{t('intake.softwaretype.vraag')}</h2>
                            <FormFieldRadioGroupLink name='antwoorden' antwoordOpties={softwareTypes} />
                        </>
                    ) : (
                        <Loader />
                    )}
                </Form>
            </Formik>
        </IntakePhase>
    );
}
