import * as React from 'react';
import { IntakeMatch } from '../../../generated/softwarematching-api';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';
import PartnerActionButton from './PartnerActionButton';

type Props = {
    match: IntakeMatch;
    onView: (match: IntakeMatch) => void;
    onRemoveMatch: (matchResultId: string) => void;
    onSelectMatch: (matchResultId: string) => void;
    isSelectedMatch: boolean;
};

export default function MatchCard({ match, onView, onSelectMatch, onRemoveMatch, isSelectedMatch }: Props) {
    const { t } = useTranslation();
    return (
        <article className='bg-white rounded-lg shadow-md' data-testid={'match-' + match.rating.rank}>
            <div
                className='border-b border-gray-200 p-4 flex justify-between cursor-pointer'
                onClick={() => onView(match)}
                onKeyDown={() => onView(match)}>
                <span className='portal-heading-5 text-primary underline overflow-hidden overflow-ellipsis w-64 overflow-wrap'>
                    {match.partner.name}
                </span>
                <div className='flex items-center'>
                    <span className='portal-body--small px-1'>{t('matchSelection.match')}:</span>
                    <span className='portal-heading-4 text-primary right-0'>{Math.trunc(match.rating.rate)}%</span>
                </div>
            </div>
            <div className='px-5 py-3 border-b border-gray-200 cursor-pointer'>
                <div className='mb-2 sm:flex justify-between items-center'>
                    <div
                        className='text-gray-500 flex-grow pb-5 sm:pb-0 mb-3'
                        onClick={() => onView(match)}
                        onKeyDown={() => onView(match)}>
                        <div className={'flex justify-start mb-3'}>
                            <div>{t('matchSelection.software')}</div>
                            <div className='pl-5 portal-heading-6 text-primary'>
                                {match.product.naam !== match.product.vendor
                                    ? match.product.vendor + ' - ' + match.product.naam
                                    : match.product.vendor}
                            </div>
                        </div>

                        <div className='flex items-center'>
                            <FaInfoCircle className='mr-1' />
                            <div className='underline text-primary'>{t('matchSelection.moreInfo')}</div>
                        </div>
                    </div>
                    <PartnerActionButton
                        isSelectedMatch={isSelectedMatch}
                        onRemoveMatch={onRemoveMatch}
                        onSelectMatch={onSelectMatch}
                        match={match}
                    />
                </div>
            </div>
        </article>
    );
}
