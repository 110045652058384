import React from 'react';
import FormFieldRadioMatrixGroup, { RadioMatrixOption, RadioMatrixRow } from './FormFieldRadioMatrixGroup';
import Accordion from '../accordion/Accordion';

export type RadioMatrixGroup = {
    label: string;
    rows: RadioMatrixRow[];
};
type Props = {
    options: RadioMatrixOption[];
    group: RadioMatrixGroup;
    defaultCollapsed: boolean;
};

export default function FormFieldCollapsableRadioMatrixGroup({ options, group, defaultCollapsed }: Props) {
    return (
        <div className='mt-5'>
            <Accordion label={group.label} defaultCollapsed={defaultCollapsed}>
                <FormFieldRadioMatrixGroup rows={group.rows} options={options} />
            </Accordion>
        </div>
    );
}
