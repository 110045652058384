import { MeetingAlternativeProposal, MeetingDate, TimeSlot } from '../generated/softwarematching-api';
import { enumFromStringValue } from './enum';

export function formatTimeslot(timeslot: string): string {
    return (
        timeslot.substring(0, 2) +
        ':' +
        timeslot.substring(2, 4) +
        ' - ' +
        timeslot.substring(5, 7) +
        ':' +
        timeslot.substring(7, 9)
    );
}

export function convertToMeetingDates(timeslots: string[]): MeetingDate[] {
    const meetingDates: MeetingDate[] = [];
    timeslots.forEach(value => {
        const datePart = value.substring(0, value.indexOf('_'));
        const timeslot = enumFromStringValue(TimeSlot, value.substring(value.indexOf('_') + 1));
        if (timeslot) {
            const meetingDate = meetingDates.find(item => item.date === datePart);
            if (meetingDate) {
                meetingDate.timeSlots.push(timeslot);
            } else {
                meetingDates.push({ date: datePart, timeSlots: [timeslot] });
            }
        }
    });

    return meetingDates;
}

export function convertToAlternativeMeetingProposal(dateTimeslot: string): MeetingAlternativeProposal {
    const datePart = dateTimeslot.substring(0, dateTimeslot.indexOf('_'));
    const timeslot = enumFromStringValue(TimeSlot, dateTimeslot.substring(dateTimeslot.indexOf('_') + 1))!;
    return { meetingDate: datePart, meetingTimeslot: timeslot };
}
