import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import IntakeBedrijfsactiviteitenKiezen from '../components/phase/IntakeBedrijfsactiviteitenKiezen';
import IntakeBedrijfsactiviteitenVraaggroepen from '../components/phase/IntakeBedrijfsactiviteitenVraaggroepen';
import IntakeFunctionaliteitenVraaggroepen from '../components/phase/IntakeFunctionaliteitenVraaggroepen';
import Loader from '../../../common/Loader';
import { IntakeVoortgang, PhaseState, PhaseType, StartIntakeRequest } from '../../../generated/softwarematching-api';
import { showErrorResponse } from '../../../helpers/notify';
import { makeCancellable } from '../../../helpers/promise';
import IntakeSoftwareTypeKiezen from '../components/phase/IntakeSoftwareTypeKiezen';
import useIntakeService from '../hooks/useIntakeService';
import Page from './Page';
import IntakeProject from '../components/phase/IntakeProject';
import { IntakeBedrijfsprofiel } from '../components/phase/IntakeBedrijfsprofiel';
import { IntakeContact } from '../components/phase/IntakeContact';
import IntakeAfsluiting from '../components/phase/IntakeAfsluiting';
import useLinkUserIdToKlantvraag from '../hooks/useLinkUserIdToKlantvraag';
import { findCurrentIntakeVoortgangItem } from '../helpers/intake';
import { BrowseDirection } from '../../../common/Constants';
import IntakeFunctionaliteitControle from '../components/phase/IntakeFunctionaliteitControle';
import { phaseContext } from '../../../common/ContextTypes';

export default function IntakePage() {
    const { t } = useTranslation();
    const intakeService = useIntakeService();
    const { search } = useLocation();
    useLinkUserIdToKlantvraag();

    const [loading, setLoading] = useState(true);
    const [intakeVoortgang, setIntakeVoortgang] = useState<IntakeVoortgang | null>(null);
    const [voortgangIndex, setVoortgangIndex] = useState<number>(0);

    const handleUpdateIntakeVoortgang = useCallback(
        (newIntakeVoortgang: IntakeVoortgang) => {
            intakeService.setKlantvraagId(newIntakeVoortgang.klantvraagId!!);
            setVoortgangIndex(newIntakeVoortgang.items.indexOf(findCurrentIntakeVoortgangItem(newIntakeVoortgang))!!);
            setIntakeVoortgang(newIntakeVoortgang);
        },
        [intakeService]
    );

    const retrieveIntakeVoortgang = useCallback(() => {
        const klantvraagId = intakeService.getKlantvraagId();
        const startIntakeRequest: StartIntakeRequest = {
            softwareType: new URLSearchParams(search).get('softwareType'),
        };

        const [promise, cancel] = makeCancellable(
            klantvraagId === null
                ? intakeService.startIntake(startIntakeRequest)
                : intakeService.getIntakeVoortgang(klantvraagId)
        );

        promise
            .then(handleUpdateIntakeVoortgang)
            .catch(error => {
                if (error.response.status === 404) {
                    intakeService.startIntake(startIntakeRequest).then(handleUpdateIntakeVoortgang);
                } else {
                    showErrorResponse(t('common:failedToRetrieveData'), error, t);
                }
            })
            .finally(() => setLoading(false));
        return cancel;
    }, [intakeService, handleUpdateIntakeVoortgang, t, search]);

    const browse = useCallback(
        (direction: BrowseDirection) => {
            if (direction === 'next') {
                setVoortgangIndex(voortgangIndex + 1);
            }
            if (direction === 'previous') {
                setVoortgangIndex(voortgangIndex - 1);
            }
        },
        [voortgangIndex, setVoortgangIndex]
    );

    const handlePhaseChange = useCallback(
        (phaseType: PhaseType, phaseState: PhaseState) => {
            if (!intakeVoortgang || phaseState === PhaseState.NOT_STARTED) {
                return;
            }

            const index = intakeVoortgang.items.findIndex(obj => obj.phaseType === phaseType);

            if (index >= 0) {
                setVoortgangIndex(index);
            }
        },
        [intakeVoortgang, setVoortgangIndex]
    );

    useEffect(retrieveIntakeVoortgang, [retrieveIntakeVoortgang]);

    const intakePhaseRender = useMemo(() => {
        if (!intakeVoortgang) {
            return;
        }

        const currentIntakeVoortgangItem = intakeVoortgang.items[voortgangIndex];

        switch (currentIntakeVoortgangItem.phaseType) {
            case PhaseType.SOFTWARE_TYPE_KIEZEN:
                return (
                    <IntakeSoftwareTypeKiezen
                        intakeVoortgang={intakeVoortgang}
                        currentIntakeVoortgangItem={currentIntakeVoortgangItem}
                        onUpdateIntakeVoortgang={handleUpdateIntakeVoortgang}
                    />
                );
            case PhaseType.BEDRIJFSACTIVITEIT_KIEZEN:
                return (
                    <IntakeBedrijfsactiviteitenKiezen
                        intakeVoortgang={intakeVoortgang}
                        currentIntakeVoortgangItem={currentIntakeVoortgangItem}
                        onUpdateIntakeVoortgang={handleUpdateIntakeVoortgang}
                        browse={browse}
                    />
                );
            case PhaseType.BEDRIJFSACTIVITEIT_VRAAGGROEPEN:
                return (
                    <IntakeBedrijfsactiviteitenVraaggroepen
                        intakeVoortgang={intakeVoortgang}
                        currentIntakeVoortgangItem={currentIntakeVoortgangItem}
                        onUpdateIntakeVoortgang={handleUpdateIntakeVoortgang}
                        browse={browse}
                    />
                );
            case PhaseType.FUNCTIONALITEITSGROEP_VRAAGGROEPEN:
                return (
                    <IntakeFunctionaliteitenVraaggroepen
                        intakeVoortgang={intakeVoortgang}
                        currentIntakeVoortgangItem={currentIntakeVoortgangItem}
                        onUpdateIntakeVoortgang={handleUpdateIntakeVoortgang}
                        browse={browse}
                    />
                );
            case PhaseType.FUNCTIONALITEIT_CONTROLE:
                return (
                    <IntakeFunctionaliteitControle
                        intakeVoortgang={intakeVoortgang}
                        onUpdateIntakeVoortgang={handleUpdateIntakeVoortgang}
                        browse={browse}
                        currentIntakeVoortgangItem={currentIntakeVoortgangItem}
                    />
                );
            case PhaseType.PROJECT:
                return (
                    <IntakeProject
                        intakeVoortgang={intakeVoortgang}
                        currentIntakeVoortgangItem={currentIntakeVoortgangItem}
                        onUpdateIntakeVoortgang={handleUpdateIntakeVoortgang}
                        browse={browse}
                    />
                );
            case PhaseType.BEDRIJFSPROFIEL:
                return (
                    <IntakeBedrijfsprofiel
                        intakeVoortgang={intakeVoortgang}
                        currentIntakeVoortgangItem={currentIntakeVoortgangItem}
                        onUpdateIntakeVoortgang={handleUpdateIntakeVoortgang}
                        browse={browse}
                    />
                );
            case PhaseType.CONTACTGEGEVENS:
                return (
                    <IntakeContact
                        intakeVoortgang={intakeVoortgang}
                        currentIntakeVoortgangItem={currentIntakeVoortgangItem}
                        onUpdateIntakeVoortgang={handleUpdateIntakeVoortgang}
                        browse={browse}
                    />
                );
            case PhaseType.AFSLUITING:
                return (
                    <IntakeAfsluiting
                        intakeVoortgang={intakeVoortgang}
                        currentIntakeVoortgangItem={currentIntakeVoortgangItem}
                    />
                );
        }
    }, [browse, voortgangIndex, intakeVoortgang, handleUpdateIntakeVoortgang]);

    return (
        <phaseContext.Provider value={{ PhaseChangeHandler: handlePhaseChange, intakeVoortgang: intakeVoortgang }}>
            <Page dataTestId='intake-page'>{loading ? <Loader /> : intakePhaseRender}</Page>
        </phaseContext.Provider>
    );
}
