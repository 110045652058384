export function useDateTimeFormatter(locale: string | undefined) {
    const dateFormatDayOfWeek = new Intl.DateTimeFormat(locale, {
        weekday: 'long',
    });

    const dateFormat = new Intl.DateTimeFormat(locale, {
        month: 'short',
        day: 'numeric',
    });

    const dateFormatFullNumeric = new Intl.DateTimeFormat(locale, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });

    const formatDayOfWeek = (dateStr: string): string => {
        const formattedDate = dateFormatDayOfWeek.format(new Date(dateStr));
        return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    };

    const formatDate = (dateStr: string): string => {
        return dateFormat.format(new Date(dateStr));
    };

    const formatFullNumeric = (dateStr: string): string => {
        return dateFormatFullNumeric.format(new Date(dateStr));
    };

    return { formatDate, formatDayOfWeek, formatFullNumeric };
}
