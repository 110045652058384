import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PartnerGebruiker } from '../../../generated/softwarematching-api';
import { makeCancellable } from '../../../helpers/promise';
import { showErrorResponse } from '../../../helpers/notify';
import { FaEdit } from 'react-icons/fa';
import Loader from '../../../common/Loader';
import GebruikerEditModal from '../modals/GebruikerEditModal';
import usePartnerService from '../hooks/usePartnerService';
import AddButton from '../../../common/button/AddButton';

type Props = {
    partnerId: string;
};
export default function Gebruikerslist({ partnerId }: Props) {
    const { t } = useTranslation();
    const partnerService = usePartnerService();

    const [gebruikers, setGebruikers] = useState<Array<PartnerGebruiker> | null>(null);
    const [loading, setLoading] = useState(true);
    const [selectedGebruiker, setSelectedGebruiker] = useState<PartnerGebruiker | null>(null);
    const [showModal, setShowModal] = useState(false);

    const retrieveData = useCallback(() => {
        setLoading(true);
        setShowModal(false);
        setSelectedGebruiker(null);

        const [promise, cancel] = makeCancellable(partnerService.getGebruikersForPartner(partnerId));
        promise
            .then(setGebruikers)
            .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t))
            .finally(() => setLoading(false));
        return cancel;
    }, [partnerId, partnerService, t]);

    useEffect(retrieveData, [retrieveData]);

    function openModal(gebruiker: PartnerGebruiker | null) {
        setSelectedGebruiker(gebruiker);
        setShowModal(true);
    }

    function closeModal() {
        retrieveData();
        setSelectedGebruiker(null);
        setShowModal(false);
    }

    return (
        <div>
            {loading ? (
                <Loader />
            ) : (
                <table className='admin-table' data-testid='gebruiker-table'>
                    <thead>
                        <tr className='admin-table--header-row'>
                            <th className='admin-table--header-cell pl-4'>Naam</th>
                            <th className='admin-table--header-cell'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {gebruikers?.map((gebruiker, list) => (
                            <tr
                                key={gebruiker.gebruikerId}
                                className='admin-table--body-row hover:cursor-pointer'
                                onClick={() => openModal(gebruiker)}>
                                <td className='admin-table--body-cell pl-4'>
                                    {gebruiker.gegevens.voornaam} {gebruiker.gegevens.achternaam}
                                </td>
                                <td className='admin-table--body-cell w-10'>
                                    <FaEdit
                                        className='admin-icon hover:text-black'
                                        data-testid={`edit-${gebruiker.gegevens.voornaam}${gebruiker.gegevens.achternaam}`}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}

            <AddButton onClick={() => openModal(null)} />

            {showModal && (
                <GebruikerEditModal
                    partnerId={partnerId}
                    gebruikerId={selectedGebruiker?.gebruikerId}
                    onSubmitted={retrieveData}
                    onCancelled={closeModal}
                />
            )}
        </div>
    );
}
