import React, { ReactNode } from 'react';

type Props = {
    children: ReactNode;
    variant: 'contained' | 'outlined';
    type?: 'submit' | 'reset' | 'button';
    color?: 'primary' | 'secondary' | 'warning' | 'deselect' | 'send';
    id?: string;
    className?: string;
    disabled?: boolean;
    dataTestId?: string;
    form?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export default function Button({
    children,
    variant = 'contained',
    type = 'button',
    color = 'primary',
    id,
    className,
    disabled,
    form,
    dataTestId,
    onClick,
}: Props) {
    return (
        <button
            id={id}
            type={type}
            className={`whitespace-nowrap text-lg p-3 rounded transition-colors leading-4 border-2 disabled:bg-faded disabled:border-faded disabled:cursor-not-allowed disabled:opacity-50 disabled:text-white 
            button ${variant} ${color} ${className ? className : ''}`}
            disabled={disabled}
            form={form}
            data-testid={dataTestId ?? 'button'}
            onClick={onClick}>
            {children}
        </button>
    );
}
