/* tslint:disable */
/* eslint-disable */
/**
 * Software Matching Api
 * Software Matching API for the match-making questionnaire service
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AcceptProposedMeetingRequest } from '../models';
// @ts-ignore
import { BedrijfContactInfo } from '../models';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { Intake } from '../models';
// @ts-ignore
import { IntakeMatch } from '../models';
// @ts-ignore
import { IntakeMatchDetails } from '../models';
// @ts-ignore
import { IntakeMatchMeeting } from '../models';
// @ts-ignore
import { IntakeMatchMeetingRequest } from '../models';
// @ts-ignore
import { IntakeMatchResult } from '../models';
// @ts-ignore
import { IntakeMatchSelectRequest } from '../models';
// @ts-ignore
import { IntakeWithMatchState } from '../models';
// @ts-ignore
import { MeetingDate } from '../models';
/**
 * ProtectedApi - axios parameter creator
 * @export
 */
export const ProtectedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Accept the proposal of the implementation partner.
         * @param {string} matchResultId ID of a match result
         * @param {AcceptProposedMeetingRequest} acceptProposedMeetingRequest The accepted proposed meeting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptProposedMeeting: async (matchResultId: string, acceptProposedMeetingRequest: AcceptProposedMeetingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchResultId' is not null or undefined
            assertParamExists('acceptProposedMeeting', 'matchResultId', matchResultId)
            // verify required parameter 'acceptProposedMeetingRequest' is not null or undefined
            assertParamExists('acceptProposedMeeting', 'acceptProposedMeetingRequest', acceptProposedMeetingRequest)
            const localVarPath = `/protected/matches/{match_result_id}/meeting/accept`
                .replace(`{${"match_result_id"}}`, encodeURIComponent(String(matchResultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptProposedMeetingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save the user-suggested meeting details
         * @param {string} klantvraagId ID of klantvraag
         * @param {IntakeMatchMeetingRequest} intakeMatchMeetingRequest The meeting data to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeetingProposal: async (klantvraagId: string, intakeMatchMeetingRequest: IntakeMatchMeetingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('createMeetingProposal', 'klantvraagId', klantvraagId)
            // verify required parameter 'intakeMatchMeetingRequest' is not null or undefined
            assertParamExists('createMeetingProposal', 'intakeMatchMeetingRequest', intakeMatchMeetingRequest)
            const localVarPath = `/protected/intake/{klantvraag_id}/meeting`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(intakeMatchMeetingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the intakes where the communication with the partner is in progress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInProgressIntakes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/intake/in-progress`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the details of the intake for the specific match
         * @param {string} matchResultId ID of a match result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntakeMatchDetails: async (matchResultId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchResultId' is not null or undefined
            assertParamExists('getIntakeMatchDetails', 'matchResultId', matchResultId)
            const localVarPath = `/protected/matches/{match_result_id}`
                .replace(`{${"match_result_id"}}`, encodeURIComponent(String(matchResultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets matches of klantvraag_id
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntakeMatches: async (klantvraagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('getIntakeMatches', 'klantvraagId', klantvraagId)
            const localVarPath = `/protected/intake/{klantvraag_id}/matches`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all intakes from a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntakes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/intake`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatchedIntakes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/protected/intake/matched`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve list of meeting dates on which the intake can be discussed with a partner
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingDateOptions: async (klantvraagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('getMeetingDateOptions', 'klantvraagId', klantvraagId)
            const localVarPath = `/protected/intake/{klantvraag_id}/meeting/dateoptions`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the meeting details (if any) for the given intake
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingProposal: async (klantvraagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('getMeetingProposal', 'klantvraagId', klantvraagId)
            const localVarPath = `/protected/intake/{klantvraag_id}/meeting`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save for the given klantvraagId the context\'s user id
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAanmelding: async (klantvraagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('postAanmelding', 'klantvraagId', klantvraagId)
            const localVarPath = `/protected/intake/{klantvraag_id}/aanmelding`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stores contact info of Bedrijf
         * @param {string} klantvraagId ID of klantvraag
         * @param {BedrijfContactInfo} bedrijfContactInfo The company profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBedrijfContactInfo: async (klantvraagId: string, bedrijfContactInfo: BedrijfContactInfo, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('postBedrijfContactInfo', 'klantvraagId', klantvraagId)
            // verify required parameter 'bedrijfContactInfo' is not null or undefined
            assertParamExists('postBedrijfContactInfo', 'bedrijfContactInfo', bedrijfContactInfo)
            const localVarPath = `/protected/intake/{klantvraag_id}/bedrijf-contact-info`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bedrijfContactInfo, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * For each selected intake match send a meeting proposal to the corresponding partners
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeToPartners: async (klantvraagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('proposeToPartners', 'klantvraagId', klantvraagId)
            const localVarPath = `/protected/intake/{klantvraag_id}/meeting/invite`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Select (some of) the matches of the intake
         * @param {string} klantvraagId ID of klantvraag
         * @param {IntakeMatchSelectRequest} intakeMatchSelectRequest The matches to select
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectMatches: async (klantvraagId: string, intakeMatchSelectRequest: IntakeMatchSelectRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('selectMatches', 'klantvraagId', klantvraagId)
            // verify required parameter 'intakeMatchSelectRequest' is not null or undefined
            assertParamExists('selectMatches', 'intakeMatchSelectRequest', intakeMatchSelectRequest)
            const localVarPath = `/protected/intake/{klantvraag_id}/matches/selection`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(intakeMatchSelectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProtectedApi - functional programming interface
 * @export
 */
export const ProtectedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProtectedApiAxiosParamCreator(configuration)
    return {
        /**
         * Accept the proposal of the implementation partner.
         * @param {string} matchResultId ID of a match result
         * @param {AcceptProposedMeetingRequest} acceptProposedMeetingRequest The accepted proposed meeting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptProposedMeeting(matchResultId: string, acceptProposedMeetingRequest: AcceptProposedMeetingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptProposedMeeting(matchResultId, acceptProposedMeetingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Save the user-suggested meeting details
         * @param {string} klantvraagId ID of klantvraag
         * @param {IntakeMatchMeetingRequest} intakeMatchMeetingRequest The meeting data to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMeetingProposal(klantvraagId: string, intakeMatchMeetingRequest: IntakeMatchMeetingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeMatchMeeting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMeetingProposal(klantvraagId, intakeMatchMeetingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the intakes where the communication with the partner is in progress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInProgressIntakes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntakeWithMatchState>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInProgressIntakes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the details of the intake for the specific match
         * @param {string} matchResultId ID of a match result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntakeMatchDetails(matchResultId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeMatchDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntakeMatchDetails(matchResultId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets matches of klantvraag_id
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntakeMatches(klantvraagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeMatchResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntakeMatches(klantvraagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all intakes from a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntakes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Intake>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntakes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMatchedIntakes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Intake>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMatchedIntakes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve list of meeting dates on which the intake can be discussed with a partner
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingDateOptions(klantvraagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeetingDate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeetingDateOptions(klantvraagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the meeting details (if any) for the given intake
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingProposal(klantvraagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeMatchMeeting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeetingProposal(klantvraagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Save for the given klantvraagId the context\'s user id
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAanmelding(klantvraagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAanmelding(klantvraagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stores contact info of Bedrijf
         * @param {string} klantvraagId ID of klantvraag
         * @param {BedrijfContactInfo} bedrijfContactInfo The company profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postBedrijfContactInfo(klantvraagId: string, bedrijfContactInfo: BedrijfContactInfo, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postBedrijfContactInfo(klantvraagId, bedrijfContactInfo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * For each selected intake match send a meeting proposal to the corresponding partners
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async proposeToPartners(klantvraagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.proposeToPartners(klantvraagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Select (some of) the matches of the intake
         * @param {string} klantvraagId ID of klantvraag
         * @param {IntakeMatchSelectRequest} intakeMatchSelectRequest The matches to select
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectMatches(klantvraagId: string, intakeMatchSelectRequest: IntakeMatchSelectRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IntakeMatch>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectMatches(klantvraagId, intakeMatchSelectRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProtectedApi - factory interface
 * @export
 */
export const ProtectedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProtectedApiFp(configuration)
    return {
        /**
         * Accept the proposal of the implementation partner.
         * @param {string} matchResultId ID of a match result
         * @param {AcceptProposedMeetingRequest} acceptProposedMeetingRequest The accepted proposed meeting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptProposedMeeting(matchResultId: string, acceptProposedMeetingRequest: AcceptProposedMeetingRequest, options?: any): AxiosPromise<void> {
            return localVarFp.acceptProposedMeeting(matchResultId, acceptProposedMeetingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Save the user-suggested meeting details
         * @param {string} klantvraagId ID of klantvraag
         * @param {IntakeMatchMeetingRequest} intakeMatchMeetingRequest The meeting data to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeetingProposal(klantvraagId: string, intakeMatchMeetingRequest: IntakeMatchMeetingRequest, options?: any): AxiosPromise<IntakeMatchMeeting> {
            return localVarFp.createMeetingProposal(klantvraagId, intakeMatchMeetingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the intakes where the communication with the partner is in progress
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInProgressIntakes(options?: any): AxiosPromise<Array<IntakeWithMatchState>> {
            return localVarFp.getInProgressIntakes(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the details of the intake for the specific match
         * @param {string} matchResultId ID of a match result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntakeMatchDetails(matchResultId: string, options?: any): AxiosPromise<IntakeMatchDetails> {
            return localVarFp.getIntakeMatchDetails(matchResultId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets matches of klantvraag_id
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntakeMatches(klantvraagId: string, options?: any): AxiosPromise<IntakeMatchResult> {
            return localVarFp.getIntakeMatches(klantvraagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all intakes from a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntakes(options?: any): AxiosPromise<Array<Intake>> {
            return localVarFp.getIntakes(options).then((request) => request(axios, basePath));
        },
        /**
         * Get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatchedIntakes(options?: any): AxiosPromise<Array<Intake>> {
            return localVarFp.getMatchedIntakes(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve list of meeting dates on which the intake can be discussed with a partner
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingDateOptions(klantvraagId: string, options?: any): AxiosPromise<Array<MeetingDate>> {
            return localVarFp.getMeetingDateOptions(klantvraagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve the meeting details (if any) for the given intake
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingProposal(klantvraagId: string, options?: any): AxiosPromise<IntakeMatchMeeting> {
            return localVarFp.getMeetingProposal(klantvraagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Save for the given klantvraagId the context\'s user id
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAanmelding(klantvraagId: string, options?: any): AxiosPromise<void> {
            return localVarFp.postAanmelding(klantvraagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Stores contact info of Bedrijf
         * @param {string} klantvraagId ID of klantvraag
         * @param {BedrijfContactInfo} bedrijfContactInfo The company profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBedrijfContactInfo(klantvraagId: string, bedrijfContactInfo: BedrijfContactInfo, options?: any): AxiosPromise<void> {
            return localVarFp.postBedrijfContactInfo(klantvraagId, bedrijfContactInfo, options).then((request) => request(axios, basePath));
        },
        /**
         * For each selected intake match send a meeting proposal to the corresponding partners
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        proposeToPartners(klantvraagId: string, options?: any): AxiosPromise<void> {
            return localVarFp.proposeToPartners(klantvraagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Select (some of) the matches of the intake
         * @param {string} klantvraagId ID of klantvraag
         * @param {IntakeMatchSelectRequest} intakeMatchSelectRequest The matches to select
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectMatches(klantvraagId: string, intakeMatchSelectRequest: IntakeMatchSelectRequest, options?: any): AxiosPromise<Array<IntakeMatch>> {
            return localVarFp.selectMatches(klantvraagId, intakeMatchSelectRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProtectedApi - object-oriented interface
 * @export
 * @class ProtectedApi
 * @extends {BaseAPI}
 */
export class ProtectedApi extends BaseAPI {
    /**
     * Accept the proposal of the implementation partner.
     * @param {string} matchResultId ID of a match result
     * @param {AcceptProposedMeetingRequest} acceptProposedMeetingRequest The accepted proposed meeting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProtectedApi
     */
    public acceptProposedMeeting(matchResultId: string, acceptProposedMeetingRequest: AcceptProposedMeetingRequest, options?: AxiosRequestConfig) {
        return ProtectedApiFp(this.configuration).acceptProposedMeeting(matchResultId, acceptProposedMeetingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save the user-suggested meeting details
     * @param {string} klantvraagId ID of klantvraag
     * @param {IntakeMatchMeetingRequest} intakeMatchMeetingRequest The meeting data to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProtectedApi
     */
    public createMeetingProposal(klantvraagId: string, intakeMatchMeetingRequest: IntakeMatchMeetingRequest, options?: AxiosRequestConfig) {
        return ProtectedApiFp(this.configuration).createMeetingProposal(klantvraagId, intakeMatchMeetingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the intakes where the communication with the partner is in progress
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProtectedApi
     */
    public getInProgressIntakes(options?: AxiosRequestConfig) {
        return ProtectedApiFp(this.configuration).getInProgressIntakes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the details of the intake for the specific match
     * @param {string} matchResultId ID of a match result
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProtectedApi
     */
    public getIntakeMatchDetails(matchResultId: string, options?: AxiosRequestConfig) {
        return ProtectedApiFp(this.configuration).getIntakeMatchDetails(matchResultId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets matches of klantvraag_id
     * @param {string} klantvraagId ID of klantvraag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProtectedApi
     */
    public getIntakeMatches(klantvraagId: string, options?: AxiosRequestConfig) {
        return ProtectedApiFp(this.configuration).getIntakeMatches(klantvraagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all intakes from a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProtectedApi
     */
    public getIntakes(options?: AxiosRequestConfig) {
        return ProtectedApiFp(this.configuration).getIntakes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProtectedApi
     */
    public getMatchedIntakes(options?: AxiosRequestConfig) {
        return ProtectedApiFp(this.configuration).getMatchedIntakes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve list of meeting dates on which the intake can be discussed with a partner
     * @param {string} klantvraagId ID of klantvraag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProtectedApi
     */
    public getMeetingDateOptions(klantvraagId: string, options?: AxiosRequestConfig) {
        return ProtectedApiFp(this.configuration).getMeetingDateOptions(klantvraagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the meeting details (if any) for the given intake
     * @param {string} klantvraagId ID of klantvraag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProtectedApi
     */
    public getMeetingProposal(klantvraagId: string, options?: AxiosRequestConfig) {
        return ProtectedApiFp(this.configuration).getMeetingProposal(klantvraagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save for the given klantvraagId the context\'s user id
     * @param {string} klantvraagId ID of klantvraag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProtectedApi
     */
    public postAanmelding(klantvraagId: string, options?: AxiosRequestConfig) {
        return ProtectedApiFp(this.configuration).postAanmelding(klantvraagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stores contact info of Bedrijf
     * @param {string} klantvraagId ID of klantvraag
     * @param {BedrijfContactInfo} bedrijfContactInfo The company profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProtectedApi
     */
    public postBedrijfContactInfo(klantvraagId: string, bedrijfContactInfo: BedrijfContactInfo, options?: AxiosRequestConfig) {
        return ProtectedApiFp(this.configuration).postBedrijfContactInfo(klantvraagId, bedrijfContactInfo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * For each selected intake match send a meeting proposal to the corresponding partners
     * @param {string} klantvraagId ID of klantvraag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProtectedApi
     */
    public proposeToPartners(klantvraagId: string, options?: AxiosRequestConfig) {
        return ProtectedApiFp(this.configuration).proposeToPartners(klantvraagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Select (some of) the matches of the intake
     * @param {string} klantvraagId ID of klantvraag
     * @param {IntakeMatchSelectRequest} intakeMatchSelectRequest The matches to select
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProtectedApi
     */
    public selectMatches(klantvraagId: string, intakeMatchSelectRequest: IntakeMatchSelectRequest, options?: AxiosRequestConfig) {
        return ProtectedApiFp(this.configuration).selectMatches(klantvraagId, intakeMatchSelectRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
