import React, { useCallback, useEffect, useState } from 'react';
import FormFieldText from '../../../common/form/FormFieldText';
import { ObjectSchema } from 'yup';
import { useTranslation } from 'react-i18next';
import { Size } from '../../../common/Size';
import { showErrorResponse, showSuccess } from '../../../helpers/notify';
import { Contactgegevens, PartnerGebruiker } from '../../../generated/softwarematching-api';
import Loader from '../../../common/Loader';
import usePartnerService from '../hooks/usePartnerService';
import Modal from '../../../common/modal/Modal';
import ModalHeader from '../../../common/modal/ModalHeader';
import ModalContent from '../../../common/modal/ModalContent';
import { Form, Formik } from 'formik';
import ModalFooter from '../../../common/modal/ModalFooter';
import DeleteButton from '../../../common/button/DeleteButton';
import SubmitButton from '../../../common/button/SubmitButton';
import Button from '../../../common/button/Button';
import yup from '../../../common/yup/YupExtended';

type Props = {
    partnerId: string;
    gebruikerId: string | undefined;
    onCancelled: () => void;
    onSubmitted: () => void;
};

const initialValues: Contactgegevens = {
    voornaam: '',
    achternaam: '',
    telefoonnummer: '',
    email: '',
};

export default function GebruikerEditModal({ partnerId, gebruikerId, onCancelled, onSubmitted }: Props) {
    const { t } = useTranslation();
    const partnerService = usePartnerService();

    const isEditing = !!gebruikerId;
    const [gegevens, setGegevens] = useState<Contactgegevens | null>(initialValues);
    const [loading, setLoading] = useState(isEditing);

    const validationScheme: ObjectSchema<Contactgegevens> = yup.object().shape({
        voornaam: yup.string().required().max(200),
        achternaam: yup.string().required().max(200),
        email: yup.string().required().validEmail().max(200),
        telefoonnummer: yup.string().required().max(20),
    });

    useEffect(() => {
        if (isEditing) {
            partnerService
                .getGebruiker(partnerId, gebruikerId)
                .then((gebruiker: PartnerGebruiker) => setGegevens(gebruiker.gegevens))
                .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t))
                .finally(() => setLoading(false));
        } else {
            setGegevens(null);
        }
    }, [isEditing, partnerId, gebruikerId, partnerService, t]);

    const handleSubmit = useCallback(
        (gegevens: Contactgegevens) => {
            const action = isEditing
                ? partnerService.updateGebruiker(partnerId, gebruikerId, gegevens)
                : partnerService.addGebruiker(partnerId, gegevens);

            action.then(onSubmitted).catch(error => showErrorResponse(t('common:failedToSubmit'), error, t));
        },
        [isEditing, partnerId, gebruikerId, partnerService, t, onSubmitted]
    );

    const handleReset = useCallback(
        (gebruikerId: string) => {
            partnerService
                .resetGebruiker(partnerId, gebruikerId)
                .then(() => showSuccess(t('common:executed')))
                .catch(error => showErrorResponse(t('common:failedToSubmit'), error, t));
        },
        [partnerId, partnerService, t]
    );

    const handleDelete = useCallback(
        (gebruikerId: string) => {
            partnerService
                .deleteGebruiker(partnerId, gebruikerId)
                .then(onCancelled)
                .catch(error => showErrorResponse(t('common:failedToDelete'), error, t));
        },
        [partnerId, partnerService, t, onCancelled]
    );

    function exit() {
        setGegevens(null);
        onCancelled();
    }

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <Modal dataTestId={'form-modal'} size={Size.Large}>
                    <ModalHeader onClose={exit}>
                        <h1 className='text-2xl'>
                            {isEditing ? t('partner:gebruiker.edit') : t('partner:gebruiker.add')}
                        </h1>
                    </ModalHeader>
                    <ModalContent>
                        <Formik
                            initialValues={gegevens ?? initialValues}
                            validationSchema={validationScheme}
                            enableReinitialize
                            onSubmit={handleSubmit}>
                            <Form className='flex gap-4 flex-col' noValidate>
                                <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                                    <FormFieldText
                                        type='text'
                                        name='voornaam'
                                        label={t('domain:contactgegevens.voornaam')}
                                        required={true}
                                    />
                                    <FormFieldText
                                        type='text'
                                        name='achternaam'
                                        label={t('domain:contactgegevens.achternaam')}
                                        required={true}
                                    />
                                </div>
                                <FormFieldText
                                    type='text'
                                    name='email'
                                    label={t('domain:contactgegevens.email')}
                                    inputMode='email'
                                    required={true}
                                    disabled={isEditing}
                                />
                                <FormFieldText
                                    type='text'
                                    name='telefoonnummer'
                                    label={t('domain:contactgegevens.telefoonnummer')}
                                    inputMode='tel'
                                    required={true}
                                />
                                <ModalFooter>
                                    {isEditing && <DeleteButton onClick={() => handleDelete(gebruikerId)} />}
                                    {isEditing && (
                                        <Button
                                            variant='outlined'
                                            type='reset'
                                            dataTestId='reset-button'
                                            onClick={() => handleReset(gebruikerId)}>
                                            {t('common:reset')}
                                        </Button>
                                    )}
                                    <SubmitButton label={isEditing ? t('common:save') : t('common:add')} />
                                </ModalFooter>
                            </Form>
                        </Formik>
                    </ModalContent>
                </Modal>
            )}
        </>
    );
}
