import * as yup from 'yup';
import { AnyObject, Flags, Maybe, string } from 'yup';

yup.addMethod<yup.StringSchema>(yup.string, 'validEmail', () => string().matches(/^.+@.+\..+$/));

declare module 'yup' {
    interface StringSchema<
        TType extends Maybe<string> = string | undefined,
        TContext = AnyObject,
        TDefault = undefined,
        TFlags extends Flags = '',
    > extends yup.Schema<TType, TContext, TDefault, TFlags> {
        validEmail(): StringSchema<TType, TContext, TDefault, TFlags>;
    }
}

export default yup;
