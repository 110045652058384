// Return type specific to ensure TypeScript understands that the returned values cannot be null or undefined
// See https://stackoverflow.com/questions/43118692/typescript-filter-out-nulls-from-an-array
export function notNullOrUndefined<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export function arraysAreEqual(a?: unknown[], b?: unknown[]): boolean {
    return !!a && !!b && a.length === b.length && a.every((element, index) => element === b[index]);
}

export function onlyUnique<TValue>(value: TValue, index: number, array: TValue[]) {
    return array.indexOf(value) === index;
}
