import React, { ReactNode } from 'react';
import IntakeVoortgangIndicator from './voortgang/IntakeVoortgangIndicator';
import { IntakeVoortgang, IntakeVoortgangItem, PhaseType } from '../../../generated/softwarematching-api';
import SubmitButton from '../../../common/button/SubmitButton';
import IntakeVoortgangIndicatorMobile from './voortgang/IntakeVoortgangIndicatorMobile';
import Button from '../../../common/button/Button';
import { useTranslation } from 'react-i18next';

type Props = {
    title: string;
    intakeVoortgang: IntakeVoortgang;
    currentIntakeVoortgangItem: IntakeVoortgangItem;
    previousCallback?: () => void;
    showPreviousButton?: boolean;
    showNextButton: boolean;
    children: ReactNode;
};

export default function IntakePhase({
    title,
    intakeVoortgang,
    currentIntakeVoortgangItem,
    previousCallback,
    showPreviousButton = true,
    showNextButton,
    children,
}: Props) {
    const { t } = useTranslation();

    return (
        <>
            <div className='flex flex-col grow min-h-[0] px-4 lg:px-8 py-4 bg-white rounded-lg'>
                <header className='border-b-2 w-full text-center lg:pt-2 pb-4 lg:pb-6' data-testid='page-title'>
                    <IntakeVoortgangIndicatorMobile intakeVoortgang={intakeVoortgang} />
                    <h1 className='font-header text-2xl'>{title}</h1>
                </header>
                <div className='flex grow min-h-[0]'>
                    {intakeVoortgang && (
                        <aside className='hidden lg:overflow-y-auto lg:block border-r-2 px-4 pt-8 pr-8'>
                            <IntakeVoortgangIndicator intakeVoortgang={intakeVoortgang} />
                        </aside>
                    )}
                    <main className='w-full py-4 lg:pt-8 lg:pl-8 lg:overflow-y-auto'>{children}</main>
                </div>
            </div>
            {showNextButton && (
                <div className='mt-4 flex flex-row justify-end items-stretch basis-1/2 space-x-2'>
                    {showPreviousButton && (
                        <Button
                            id={`previous-${createUniqueButtonId(intakeVoortgang, currentIntakeVoortgangItem)}`}
                            variant='contained'
                            onClick={previousCallback}
                            dataTestId='previous-button'>
                            {t('common:previous')}
                        </Button>
                    )}
                    <SubmitButton
                        id={`next-${createUniqueButtonId(intakeVoortgang, currentIntakeVoortgangItem)}`}
                        form='intake-form'
                    />
                </div>
            )}
        </>
    );
}

/**
 * Create a unique id for the previous and next buttons, for analytics software like Google Tag Manager
 * @param intakeVoortgang
 * @param currentIntakeVoortgangItem
 */
function createUniqueButtonId(
    intakeVoortgang: IntakeVoortgang,
    currentIntakeVoortgangItem: IntakeVoortgangItem
): string {
    switch (currentIntakeVoortgangItem.phaseType) {
        case PhaseType.SOFTWARE_TYPE_KIEZEN:
        case PhaseType.BEDRIJFSACTIVITEIT_KIEZEN:
        case PhaseType.FUNCTIONALITEIT_CONTROLE:
        case PhaseType.PROJECT:
        case PhaseType.BEDRIJFSPROFIEL:
        case PhaseType.CONTACTGEGEVENS:
        case PhaseType.AFSLUITING:
            return currentIntakeVoortgangItem.phaseType;
        case PhaseType.BEDRIJFSACTIVITEIT_VRAAGGROEPEN:
        case PhaseType.FUNCTIONALITEITSGROEP_VRAAGGROEPEN: {
            const vraaggroep = intakeVoortgang.phases
                .filter(ph => ph.vraaggroepen)
                .flatMap(ph => ph.vraaggroepen)
                .find(vg => vg?.id === currentIntakeVoortgangItem.vraagGroepId);
            const vraaggroepCleaned = vraaggroep?.naam?.replace(/\s/g, '_');
            return `${currentIntakeVoortgangItem.phaseType}-${vraaggroepCleaned}-${currentIntakeVoortgangItem.vraagId}`;
        }
    }
}
