import { IntakeVoortgang, PhaseState, PhaseType } from '../generated/softwarematching-api';
import { createContext } from 'react';

type phaseContextType = {
    PhaseChangeHandler: (phaseType: PhaseType, phaseState: PhaseState) => void;
    intakeVoortgang: IntakeVoortgang | null;
};

export const phaseContext = createContext<phaseContextType>({
    PhaseChangeHandler: () => null,
    intakeVoortgang: null,
});
