import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useAuth } from 'react-oidc-context';
import { useCallback, useMemo } from 'react';
import { Location, useLocation } from 'react-router-dom';

interface JwtKeycloakToken extends JwtPayload {
    realm_access?: JwtKeycloakRealmAccess;
    partner_id?: string;
    groups?: string[];
}

interface JwtKeycloakRealmAccess {
    roles?: string[];
}

type UseRoles = {
    roles: string[];
    hasRole: (role: string) => boolean;
};

export function useAuthWrapper() {
    const userName = useUserName();
    const auth = useAuth();
    const location = useLocation();

    const login = useCallback(() => auth.signinRedirect({ redirect_uri: getCurrentPath(location) }), [auth, location]);
    const logout = useCallback(
        () => auth.signoutRedirect({ post_logout_redirect_uri: getCurrentPath(location) }),
        [auth, location]
    );
    return { login, logout, isAuthenticated: auth.isAuthenticated, userName: userName };
}

export function useUserName() {
    const auth = useAuth();
    return auth.user?.profile.name;
}

export function useToken() {
    const auth = useAuth();
    return auth.isAuthenticated ? auth.user?.access_token : undefined;
}

export function useAuthRoles() {
    const token = useToken();

    return useMemo((): UseRoles => {
        const roles = getRolesFromAccessToken(token);

        return { roles, hasRole: (role: string) => roles.includes(role) };
    }, [token]);
}

export function useAuthPartnerId() {
    const token = useToken();

    return useMemo(() => {
        return decodeToken(token)?.partner_id;
    }, [token]);
}

function getRolesFromAccessToken(token?: string) {
    return decodeToken(token)?.realm_access?.roles ?? [];
}

function decodeToken(token?: string): JwtKeycloakToken | undefined {
    return token ? jwtDecode<JwtKeycloakToken>(token) : undefined;
}

function getCurrentPath(location: Location) {
    //Note use the location.pathname to get the current location set by react router (instead of using location.href that can be an outdated value)
    return window.location.origin + location.pathname;
}
