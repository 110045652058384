import React from 'react';
import { useAuthPartnerId } from '../../../hooks/auth-hooks';
import UnauthorizedPage from '../../../common/page/UnauthorizedPage';
import MatchesList from '../components/MatchesList';

export default function PartnerMatchesPage() {
    const partnerId = useAuthPartnerId();

    if (!partnerId) {
        return <UnauthorizedPage />;
    }

    return <MatchesList partnerId={partnerId} />;
}
