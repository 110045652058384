/* tslint:disable */
/* eslint-disable */
/**
 * Software Matching Api
 * Software Matching API for the match-making questionnaire service
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Aanbieding } from '../models';
// @ts-ignore
import { AanbiedingRequest } from '../models';
// @ts-ignore
import { Bedrijfsactiviteit } from '../models';
// @ts-ignore
import { Contactgegevens } from '../models';
// @ts-ignore
import { EnrollPartnerprofielRequest } from '../models';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { IntakeMatchMeeting } from '../models';
// @ts-ignore
import { MatchDetail } from '../models';
// @ts-ignore
import { MeetingAcceptRequest } from '../models';
// @ts-ignore
import { MeetingAlternativeProposeRequest } from '../models';
// @ts-ignore
import { MeetingDate } from '../models';
// @ts-ignore
import { PartnerGebruiker } from '../models';
// @ts-ignore
import { PartnerMatch } from '../models';
// @ts-ignore
import { Partnerprofiel } from '../models';
// @ts-ignore
import { PartnerprofielRequest } from '../models';
// @ts-ignore
import { StandaardaddonDefinitie } from '../models';
/**
 * PartnerApi - axios parameter creator
 * @export
 */
export const PartnerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Accept the match
         * @param {string} matchResultId ID of a match result
         * @param {MeetingAcceptRequest} meetingAcceptRequest The accepted meeting timeslot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptMeeting: async (matchResultId: string, meetingAcceptRequest: MeetingAcceptRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchResultId' is not null or undefined
            assertParamExists('acceptMeeting', 'matchResultId', matchResultId)
            // verify required parameter 'meetingAcceptRequest' is not null or undefined
            assertParamExists('acceptMeeting', 'meetingAcceptRequest', meetingAcceptRequest)
            const localVarPath = `/partner/matches/{match_result_id}/meeting/accept`
                .replace(`{${"match_result_id"}}`, encodeURIComponent(String(matchResultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingAcceptRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a new aanbieding
         * @param {string} partnerId ID of a partner
         * @param {AanbiedingRequest} aanbiedingRequest The aanbieding to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAanbieding: async (partnerId: string, aanbiedingRequest: AanbiedingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('addAanbieding', 'partnerId', partnerId)
            // verify required parameter 'aanbiedingRequest' is not null or undefined
            assertParamExists('addAanbieding', 'aanbiedingRequest', aanbiedingRequest)
            const localVarPath = `/partner/{partner_id}/aanbieding`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aanbiedingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save the partner-suggested meeting details
         * @param {string} matchResultId ID of a match result
         * @param {MeetingAlternativeProposeRequest} meetingAlternativeProposeRequest The meeting date &amp; timeslot to propose
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeetingAlternativeProposal: async (matchResultId: string, meetingAlternativeProposeRequest: MeetingAlternativeProposeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchResultId' is not null or undefined
            assertParamExists('createMeetingAlternativeProposal', 'matchResultId', matchResultId)
            // verify required parameter 'meetingAlternativeProposeRequest' is not null or undefined
            assertParamExists('createMeetingAlternativeProposal', 'meetingAlternativeProposeRequest', meetingAlternativeProposeRequest)
            const localVarPath = `/partner/matches/{match_result_id}/meeting/propose`
                .replace(`{${"match_result_id"}}`, encodeURIComponent(String(matchResultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(meetingAlternativeProposeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an existing aanbieding
         * @param {string} partnerId ID of a partner
         * @param {string} aanbiedingId ID of a aanbieding
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAanbieding: async (partnerId: string, aanbiedingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('deleteAanbieding', 'partnerId', partnerId)
            // verify required parameter 'aanbiedingId' is not null or undefined
            assertParamExists('deleteAanbieding', 'aanbiedingId', aanbiedingId)
            const localVarPath = `/partner/{partner_id}/aanbieding/{aanbieding_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"aanbieding_id"}}`, encodeURIComponent(String(aanbiedingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a partner\'s gebruiker by its ID
         * @param {string} partnerId ID of a partner
         * @param {string} gebruikerId ID of a gebruiker within some partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerGebruiker: async (partnerId: string, gebruikerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('deletePartnerGebruiker', 'partnerId', partnerId)
            // verify required parameter 'gebruikerId' is not null or undefined
            assertParamExists('deletePartnerGebruiker', 'gebruikerId', gebruikerId)
            const localVarPath = `/partner/{partner_id}/gebruiker/{gebruiker_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"gebruiker_id"}}`, encodeURIComponent(String(gebruikerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Enrolls a partnerprofiel with just a name and an initial gebruiker, to allow a gebruiker to get started
         * @param {EnrollPartnerprofielRequest} enrollPartnerprofielRequest A bedrijfsnaam with gebruikersgegevens to create a placeholder partnerprofiel and initial gebruiker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollPartnerprofiel: async (enrollPartnerprofielRequest: EnrollPartnerprofielRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enrollPartnerprofielRequest' is not null or undefined
            assertParamExists('enrollPartnerprofiel', 'enrollPartnerprofielRequest', enrollPartnerprofielRequest)
            const localVarPath = `/partner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enrollPartnerprofielRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a specific aanbieding by its id for a partner
         * @param {string} partnerId ID of a partner
         * @param {string} aanbiedingId ID of a aanbieding
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAanbieding: async (partnerId: string, aanbiedingId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('getAanbieding', 'partnerId', partnerId)
            // verify required parameter 'aanbiedingId' is not null or undefined
            assertParamExists('getAanbieding', 'aanbiedingId', aanbiedingId)
            const localVarPath = `/partner/{partner_id}/aanbieding/{aanbieding_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"aanbieding_id"}}`, encodeURIComponent(String(aanbiedingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of all aanbiedingen for a partner
         * @param {string} partnerId ID of a partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAanbiedingen: async (partnerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('getAanbiedingen', 'partnerId', partnerId)
            const localVarPath = `/partner/{partner_id}/aanbieding`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the full bedrijfsactiviteiten hierarchy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBedrijfsactiviteiten: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/bedrijfsactiviteiten`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get matches by partnerId
         * @param {string} matchResultId ID of a match result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatchDetail: async (matchResultId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchResultId' is not null or undefined
            assertParamExists('getMatchDetail', 'matchResultId', matchResultId)
            const localVarPath = `/partner/matches/{match_result_id}`
                .replace(`{${"match_result_id"}}`, encodeURIComponent(String(matchResultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve list of meeting dates on which the intake can be discussed with stella
         * @param {string} matchResultId ID of a match result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingDateOptionsPartner: async (matchResultId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchResultId' is not null or undefined
            assertParamExists('getMeetingDateOptionsPartner', 'matchResultId', matchResultId)
            const localVarPath = `/partner/matches/{match_result_id}/meeting/dateoptions`
                .replace(`{${"match_result_id"}}`, encodeURIComponent(String(matchResultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of meeting proposals provided by Stella
         * @param {string} matchResultId ID of a match result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingProposals: async (matchResultId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchResultId' is not null or undefined
            assertParamExists('getMeetingProposals', 'matchResultId', matchResultId)
            const localVarPath = `/partner/matches/{match_result_id}/meetingProposals`
                .replace(`{${"match_result_id"}}`, encodeURIComponent(String(matchResultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific gebruiker by its id, from the specified partner
         * @param {string} partnerId ID of a partner
         * @param {string} gebruikerId ID of a gebruiker within some partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerGebruiker: async (partnerId: string, gebruikerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('getPartnerGebruiker', 'partnerId', partnerId)
            // verify required parameter 'gebruikerId' is not null or undefined
            assertParamExists('getPartnerGebruiker', 'gebruikerId', gebruikerId)
            const localVarPath = `/partner/{partner_id}/gebruiker/{gebruiker_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"gebruiker_id"}}`, encodeURIComponent(String(gebruikerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of all gebruikers for a given partner
         * @param {string} partnerId ID of a partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerGebruikers: async (partnerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('getPartnerGebruikers', 'partnerId', partnerId)
            const localVarPath = `/partner/{partner_id}/gebruiker`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get matches by partnerId
         * @param {string} partnerId ID of a partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerMatches: async (partnerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('getPartnerMatches', 'partnerId', partnerId)
            const localVarPath = `/partner/{partner_id}/matches`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a specific partnerprofiel by its id
         * @param {string} partnerId ID of a partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerprofiel: async (partnerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('getPartnerprofiel', 'partnerId', partnerId)
            const localVarPath = `/partner/{partner_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of all partnerprofielen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerprofielen: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a list of all standaardaddonDefinities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStandaardaddonDefinities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/standaardaddondefinities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates/Adds a gebruiker to the given partner through contactgegevens
         * @param {string} partnerId ID of a partner
         * @param {Contactgegevens} contactgegevens 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPartnerGebruiker: async (partnerId: string, contactgegevens: Contactgegevens, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('postPartnerGebruiker', 'partnerId', partnerId)
            // verify required parameter 'contactgegevens' is not null or undefined
            assertParamExists('postPartnerGebruiker', 'contactgegevens', contactgegevens)
            const localVarPath = `/partner/{partner_id}/gebruiker`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactgegevens, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing gebruiker for the given partner
         * @param {string} partnerId ID of a partner
         * @param {string} gebruikerId ID of a gebruiker within some partner
         * @param {Contactgegevens} contactgegevens 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPartnerGebruiker: async (partnerId: string, gebruikerId: string, contactgegevens: Contactgegevens, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('putPartnerGebruiker', 'partnerId', partnerId)
            // verify required parameter 'gebruikerId' is not null or undefined
            assertParamExists('putPartnerGebruiker', 'gebruikerId', gebruikerId)
            // verify required parameter 'contactgegevens' is not null or undefined
            assertParamExists('putPartnerGebruiker', 'contactgegevens', contactgegevens)
            const localVarPath = `/partner/{partner_id}/gebruiker/{gebruiker_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"gebruiker_id"}}`, encodeURIComponent(String(gebruikerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactgegevens, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reject the match by matchResultId
         * @param {string} matchResultId ID of a match result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectMatch: async (matchResultId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchResultId' is not null or undefined
            assertParamExists('rejectMatch', 'matchResultId', matchResultId)
            const localVarPath = `/partner/matches/{match_result_id}/reject`
                .replace(`{${"match_result_id"}}`, encodeURIComponent(String(matchResultId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a specific gebruiker by its id, from the specified partner
         * @param {string} partnerId ID of a partner
         * @param {string} gebruikerId ID of a gebruiker within some partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPartnerGebruiker: async (partnerId: string, gebruikerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('resetPartnerGebruiker', 'partnerId', partnerId)
            // verify required parameter 'gebruikerId' is not null or undefined
            assertParamExists('resetPartnerGebruiker', 'gebruikerId', gebruikerId)
            const localVarPath = `/partner/{partner_id}/gebruiker/{gebruiker_id}/reset`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"gebruiker_id"}}`, encodeURIComponent(String(gebruikerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing aanbieding
         * @param {string} partnerId ID of a partner
         * @param {string} aanbiedingId ID of a aanbieding
         * @param {AanbiedingRequest} aanbiedingRequest The aanbieding to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAanbieding: async (partnerId: string, aanbiedingId: string, aanbiedingRequest: AanbiedingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('updateAanbieding', 'partnerId', partnerId)
            // verify required parameter 'aanbiedingId' is not null or undefined
            assertParamExists('updateAanbieding', 'aanbiedingId', aanbiedingId)
            // verify required parameter 'aanbiedingRequest' is not null or undefined
            assertParamExists('updateAanbieding', 'aanbiedingRequest', aanbiedingRequest)
            const localVarPath = `/partner/{partner_id}/aanbieding/{aanbieding_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)))
                .replace(`{${"aanbieding_id"}}`, encodeURIComponent(String(aanbiedingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(aanbiedingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing partnerprofiel\'s properties
         * @param {string} partnerId ID of a partner
         * @param {PartnerprofielRequest} partnerprofielRequest The partnerprofiel to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerprofiel: async (partnerId: string, partnerprofielRequest: PartnerprofielRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerId' is not null or undefined
            assertParamExists('updatePartnerprofiel', 'partnerId', partnerId)
            // verify required parameter 'partnerprofielRequest' is not null or undefined
            assertParamExists('updatePartnerprofiel', 'partnerprofielRequest', partnerprofielRequest)
            const localVarPath = `/partner/{partner_id}`
                .replace(`{${"partner_id"}}`, encodeURIComponent(String(partnerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partnerprofielRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PartnerApi - functional programming interface
 * @export
 */
export const PartnerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PartnerApiAxiosParamCreator(configuration)
    return {
        /**
         * Accept the match
         * @param {string} matchResultId ID of a match result
         * @param {MeetingAcceptRequest} meetingAcceptRequest The accepted meeting timeslot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptMeeting(matchResultId: string, meetingAcceptRequest: MeetingAcceptRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptMeeting(matchResultId, meetingAcceptRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a new aanbieding
         * @param {string} partnerId ID of a partner
         * @param {AanbiedingRequest} aanbiedingRequest The aanbieding to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addAanbieding(partnerId: string, aanbiedingRequest: AanbiedingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Aanbieding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addAanbieding(partnerId, aanbiedingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Save the partner-suggested meeting details
         * @param {string} matchResultId ID of a match result
         * @param {MeetingAlternativeProposeRequest} meetingAlternativeProposeRequest The meeting date &amp; timeslot to propose
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMeetingAlternativeProposal(matchResultId: string, meetingAlternativeProposeRequest: MeetingAlternativeProposeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMeetingAlternativeProposal(matchResultId, meetingAlternativeProposeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an existing aanbieding
         * @param {string} partnerId ID of a partner
         * @param {string} aanbiedingId ID of a aanbieding
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAanbieding(partnerId: string, aanbiedingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAanbieding(partnerId, aanbiedingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a partner\'s gebruiker by its ID
         * @param {string} partnerId ID of a partner
         * @param {string} gebruikerId ID of a gebruiker within some partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePartnerGebruiker(partnerId: string, gebruikerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePartnerGebruiker(partnerId, gebruikerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Enrolls a partnerprofiel with just a name and an initial gebruiker, to allow a gebruiker to get started
         * @param {EnrollPartnerprofielRequest} enrollPartnerprofielRequest A bedrijfsnaam with gebruikersgegevens to create a placeholder partnerprofiel and initial gebruiker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enrollPartnerprofiel(enrollPartnerprofielRequest: EnrollPartnerprofielRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Partnerprofiel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enrollPartnerprofiel(enrollPartnerprofielRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a specific aanbieding by its id for a partner
         * @param {string} partnerId ID of a partner
         * @param {string} aanbiedingId ID of a aanbieding
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAanbieding(partnerId: string, aanbiedingId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Aanbieding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAanbieding(partnerId, aanbiedingId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of all aanbiedingen for a partner
         * @param {string} partnerId ID of a partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAanbiedingen(partnerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Aanbieding>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAanbiedingen(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the full bedrijfsactiviteiten hierarchy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBedrijfsactiviteiten(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Bedrijfsactiviteit>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBedrijfsactiviteiten(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get matches by partnerId
         * @param {string} matchResultId ID of a match result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMatchDetail(matchResultId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMatchDetail(matchResultId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve list of meeting dates on which the intake can be discussed with stella
         * @param {string} matchResultId ID of a match result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingDateOptionsPartner(matchResultId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MeetingDate>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeetingDateOptionsPartner(matchResultId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the list of meeting proposals provided by Stella
         * @param {string} matchResultId ID of a match result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeetingProposals(matchResultId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeMatchMeeting>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeetingProposals(matchResultId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a specific gebruiker by its id, from the specified partner
         * @param {string} partnerId ID of a partner
         * @param {string} gebruikerId ID of a gebruiker within some partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnerGebruiker(partnerId: string, gebruikerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerGebruiker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnerGebruiker(partnerId, gebruikerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of all gebruikers for a given partner
         * @param {string} partnerId ID of a partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnerGebruikers(partnerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerGebruiker>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnerGebruikers(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get matches by partnerId
         * @param {string} partnerId ID of a partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnerMatches(partnerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartnerMatch>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnerMatches(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a specific partnerprofiel by its id
         * @param {string} partnerId ID of a partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnerprofiel(partnerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Partnerprofiel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnerprofiel(partnerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of all partnerprofielen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPartnerprofielen(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Partnerprofiel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnerprofielen(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a list of all standaardaddonDefinities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStandaardaddonDefinities(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StandaardaddonDefinitie>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStandaardaddonDefinities(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates/Adds a gebruiker to the given partner through contactgegevens
         * @param {string} partnerId ID of a partner
         * @param {Contactgegevens} contactgegevens 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPartnerGebruiker(partnerId: string, contactgegevens: Contactgegevens, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerGebruiker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPartnerGebruiker(partnerId, contactgegevens, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing gebruiker for the given partner
         * @param {string} partnerId ID of a partner
         * @param {string} gebruikerId ID of a gebruiker within some partner
         * @param {Contactgegevens} contactgegevens 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPartnerGebruiker(partnerId: string, gebruikerId: string, contactgegevens: Contactgegevens, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartnerGebruiker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPartnerGebruiker(partnerId, gebruikerId, contactgegevens, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reject the match by matchResultId
         * @param {string} matchResultId ID of a match result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rejectMatch(matchResultId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rejectMatch(matchResultId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a specific gebruiker by its id, from the specified partner
         * @param {string} partnerId ID of a partner
         * @param {string} gebruikerId ID of a gebruiker within some partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPartnerGebruiker(partnerId: string, gebruikerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPartnerGebruiker(partnerId, gebruikerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing aanbieding
         * @param {string} partnerId ID of a partner
         * @param {string} aanbiedingId ID of a aanbieding
         * @param {AanbiedingRequest} aanbiedingRequest The aanbieding to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAanbieding(partnerId: string, aanbiedingId: string, aanbiedingRequest: AanbiedingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Aanbieding>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAanbieding(partnerId, aanbiedingId, aanbiedingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing partnerprofiel\'s properties
         * @param {string} partnerId ID of a partner
         * @param {PartnerprofielRequest} partnerprofielRequest The partnerprofiel to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePartnerprofiel(partnerId: string, partnerprofielRequest: PartnerprofielRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Partnerprofiel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePartnerprofiel(partnerId, partnerprofielRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PartnerApi - factory interface
 * @export
 */
export const PartnerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PartnerApiFp(configuration)
    return {
        /**
         * Accept the match
         * @param {string} matchResultId ID of a match result
         * @param {MeetingAcceptRequest} meetingAcceptRequest The accepted meeting timeslot
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptMeeting(matchResultId: string, meetingAcceptRequest: MeetingAcceptRequest, options?: any): AxiosPromise<MatchDetail> {
            return localVarFp.acceptMeeting(matchResultId, meetingAcceptRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a new aanbieding
         * @param {string} partnerId ID of a partner
         * @param {AanbiedingRequest} aanbiedingRequest The aanbieding to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAanbieding(partnerId: string, aanbiedingRequest: AanbiedingRequest, options?: any): AxiosPromise<Aanbieding> {
            return localVarFp.addAanbieding(partnerId, aanbiedingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Save the partner-suggested meeting details
         * @param {string} matchResultId ID of a match result
         * @param {MeetingAlternativeProposeRequest} meetingAlternativeProposeRequest The meeting date &amp; timeslot to propose
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMeetingAlternativeProposal(matchResultId: string, meetingAlternativeProposeRequest: MeetingAlternativeProposeRequest, options?: any): AxiosPromise<MatchDetail> {
            return localVarFp.createMeetingAlternativeProposal(matchResultId, meetingAlternativeProposeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing aanbieding
         * @param {string} partnerId ID of a partner
         * @param {string} aanbiedingId ID of a aanbieding
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAanbieding(partnerId: string, aanbiedingId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAanbieding(partnerId, aanbiedingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a partner\'s gebruiker by its ID
         * @param {string} partnerId ID of a partner
         * @param {string} gebruikerId ID of a gebruiker within some partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePartnerGebruiker(partnerId: string, gebruikerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePartnerGebruiker(partnerId, gebruikerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Enrolls a partnerprofiel with just a name and an initial gebruiker, to allow a gebruiker to get started
         * @param {EnrollPartnerprofielRequest} enrollPartnerprofielRequest A bedrijfsnaam with gebruikersgegevens to create a placeholder partnerprofiel and initial gebruiker
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrollPartnerprofiel(enrollPartnerprofielRequest: EnrollPartnerprofielRequest, options?: any): AxiosPromise<Partnerprofiel> {
            return localVarFp.enrollPartnerprofiel(enrollPartnerprofielRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a specific aanbieding by its id for a partner
         * @param {string} partnerId ID of a partner
         * @param {string} aanbiedingId ID of a aanbieding
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAanbieding(partnerId: string, aanbiedingId: string, options?: any): AxiosPromise<Aanbieding> {
            return localVarFp.getAanbieding(partnerId, aanbiedingId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of all aanbiedingen for a partner
         * @param {string} partnerId ID of a partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAanbiedingen(partnerId: string, options?: any): AxiosPromise<Array<Aanbieding>> {
            return localVarFp.getAanbiedingen(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the full bedrijfsactiviteiten hierarchy
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBedrijfsactiviteiten(options?: any): AxiosPromise<Array<Bedrijfsactiviteit>> {
            return localVarFp.getBedrijfsactiviteiten(options).then((request) => request(axios, basePath));
        },
        /**
         * Get matches by partnerId
         * @param {string} matchResultId ID of a match result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMatchDetail(matchResultId: string, options?: any): AxiosPromise<MatchDetail> {
            return localVarFp.getMatchDetail(matchResultId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve list of meeting dates on which the intake can be discussed with stella
         * @param {string} matchResultId ID of a match result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingDateOptionsPartner(matchResultId: string, options?: any): AxiosPromise<Array<MeetingDate>> {
            return localVarFp.getMeetingDateOptionsPartner(matchResultId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of meeting proposals provided by Stella
         * @param {string} matchResultId ID of a match result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeetingProposals(matchResultId: string, options?: any): AxiosPromise<IntakeMatchMeeting> {
            return localVarFp.getMeetingProposals(matchResultId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a specific gebruiker by its id, from the specified partner
         * @param {string} partnerId ID of a partner
         * @param {string} gebruikerId ID of a gebruiker within some partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerGebruiker(partnerId: string, gebruikerId: string, options?: any): AxiosPromise<PartnerGebruiker> {
            return localVarFp.getPartnerGebruiker(partnerId, gebruikerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of all gebruikers for a given partner
         * @param {string} partnerId ID of a partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerGebruikers(partnerId: string, options?: any): AxiosPromise<Array<PartnerGebruiker>> {
            return localVarFp.getPartnerGebruikers(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get matches by partnerId
         * @param {string} partnerId ID of a partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerMatches(partnerId: string, options?: any): AxiosPromise<Array<PartnerMatch>> {
            return localVarFp.getPartnerMatches(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a specific partnerprofiel by its id
         * @param {string} partnerId ID of a partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerprofiel(partnerId: string, options?: any): AxiosPromise<Partnerprofiel> {
            return localVarFp.getPartnerprofiel(partnerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of all partnerprofielen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPartnerprofielen(options?: any): AxiosPromise<Array<Partnerprofiel>> {
            return localVarFp.getPartnerprofielen(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a list of all standaardaddonDefinities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStandaardaddonDefinities(options?: any): AxiosPromise<Array<StandaardaddonDefinitie>> {
            return localVarFp.getStandaardaddonDefinities(options).then((request) => request(axios, basePath));
        },
        /**
         * Creates/Adds a gebruiker to the given partner through contactgegevens
         * @param {string} partnerId ID of a partner
         * @param {Contactgegevens} contactgegevens 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPartnerGebruiker(partnerId: string, contactgegevens: Contactgegevens, options?: any): AxiosPromise<PartnerGebruiker> {
            return localVarFp.postPartnerGebruiker(partnerId, contactgegevens, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing gebruiker for the given partner
         * @param {string} partnerId ID of a partner
         * @param {string} gebruikerId ID of a gebruiker within some partner
         * @param {Contactgegevens} contactgegevens 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPartnerGebruiker(partnerId: string, gebruikerId: string, contactgegevens: Contactgegevens, options?: any): AxiosPromise<PartnerGebruiker> {
            return localVarFp.putPartnerGebruiker(partnerId, gebruikerId, contactgegevens, options).then((request) => request(axios, basePath));
        },
        /**
         * Reject the match by matchResultId
         * @param {string} matchResultId ID of a match result
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectMatch(matchResultId: string, options?: any): AxiosPromise<void> {
            return localVarFp.rejectMatch(matchResultId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a specific gebruiker by its id, from the specified partner
         * @param {string} partnerId ID of a partner
         * @param {string} gebruikerId ID of a gebruiker within some partner
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPartnerGebruiker(partnerId: string, gebruikerId: string, options?: any): AxiosPromise<void> {
            return localVarFp.resetPartnerGebruiker(partnerId, gebruikerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing aanbieding
         * @param {string} partnerId ID of a partner
         * @param {string} aanbiedingId ID of a aanbieding
         * @param {AanbiedingRequest} aanbiedingRequest The aanbieding to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAanbieding(partnerId: string, aanbiedingId: string, aanbiedingRequest: AanbiedingRequest, options?: any): AxiosPromise<Aanbieding> {
            return localVarFp.updateAanbieding(partnerId, aanbiedingId, aanbiedingRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing partnerprofiel\'s properties
         * @param {string} partnerId ID of a partner
         * @param {PartnerprofielRequest} partnerprofielRequest The partnerprofiel to save
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePartnerprofiel(partnerId: string, partnerprofielRequest: PartnerprofielRequest, options?: any): AxiosPromise<Partnerprofiel> {
            return localVarFp.updatePartnerprofiel(partnerId, partnerprofielRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PartnerApi - object-oriented interface
 * @export
 * @class PartnerApi
 * @extends {BaseAPI}
 */
export class PartnerApi extends BaseAPI {
    /**
     * Accept the match
     * @param {string} matchResultId ID of a match result
     * @param {MeetingAcceptRequest} meetingAcceptRequest The accepted meeting timeslot
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public acceptMeeting(matchResultId: string, meetingAcceptRequest: MeetingAcceptRequest, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).acceptMeeting(matchResultId, meetingAcceptRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a new aanbieding
     * @param {string} partnerId ID of a partner
     * @param {AanbiedingRequest} aanbiedingRequest The aanbieding to save
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public addAanbieding(partnerId: string, aanbiedingRequest: AanbiedingRequest, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).addAanbieding(partnerId, aanbiedingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Save the partner-suggested meeting details
     * @param {string} matchResultId ID of a match result
     * @param {MeetingAlternativeProposeRequest} meetingAlternativeProposeRequest The meeting date &amp; timeslot to propose
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public createMeetingAlternativeProposal(matchResultId: string, meetingAlternativeProposeRequest: MeetingAlternativeProposeRequest, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).createMeetingAlternativeProposal(matchResultId, meetingAlternativeProposeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an existing aanbieding
     * @param {string} partnerId ID of a partner
     * @param {string} aanbiedingId ID of a aanbieding
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public deleteAanbieding(partnerId: string, aanbiedingId: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).deleteAanbieding(partnerId, aanbiedingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a partner\'s gebruiker by its ID
     * @param {string} partnerId ID of a partner
     * @param {string} gebruikerId ID of a gebruiker within some partner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public deletePartnerGebruiker(partnerId: string, gebruikerId: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).deletePartnerGebruiker(partnerId, gebruikerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Enrolls a partnerprofiel with just a name and an initial gebruiker, to allow a gebruiker to get started
     * @param {EnrollPartnerprofielRequest} enrollPartnerprofielRequest A bedrijfsnaam with gebruikersgegevens to create a placeholder partnerprofiel and initial gebruiker
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public enrollPartnerprofiel(enrollPartnerprofielRequest: EnrollPartnerprofielRequest, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).enrollPartnerprofiel(enrollPartnerprofielRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a specific aanbieding by its id for a partner
     * @param {string} partnerId ID of a partner
     * @param {string} aanbiedingId ID of a aanbieding
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public getAanbieding(partnerId: string, aanbiedingId: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).getAanbieding(partnerId, aanbiedingId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of all aanbiedingen for a partner
     * @param {string} partnerId ID of a partner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public getAanbiedingen(partnerId: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).getAanbiedingen(partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the full bedrijfsactiviteiten hierarchy
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public getBedrijfsactiviteiten(options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).getBedrijfsactiviteiten(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get matches by partnerId
     * @param {string} matchResultId ID of a match result
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public getMatchDetail(matchResultId: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).getMatchDetail(matchResultId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve list of meeting dates on which the intake can be discussed with stella
     * @param {string} matchResultId ID of a match result
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public getMeetingDateOptionsPartner(matchResultId: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).getMeetingDateOptionsPartner(matchResultId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of meeting proposals provided by Stella
     * @param {string} matchResultId ID of a match result
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public getMeetingProposals(matchResultId: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).getMeetingProposals(matchResultId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a specific gebruiker by its id, from the specified partner
     * @param {string} partnerId ID of a partner
     * @param {string} gebruikerId ID of a gebruiker within some partner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public getPartnerGebruiker(partnerId: string, gebruikerId: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).getPartnerGebruiker(partnerId, gebruikerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of all gebruikers for a given partner
     * @param {string} partnerId ID of a partner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public getPartnerGebruikers(partnerId: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).getPartnerGebruikers(partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get matches by partnerId
     * @param {string} partnerId ID of a partner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public getPartnerMatches(partnerId: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).getPartnerMatches(partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a specific partnerprofiel by its id
     * @param {string} partnerId ID of a partner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public getPartnerprofiel(partnerId: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).getPartnerprofiel(partnerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of all partnerprofielen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public getPartnerprofielen(options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).getPartnerprofielen(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a list of all standaardaddonDefinities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public getStandaardaddonDefinities(options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).getStandaardaddonDefinities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates/Adds a gebruiker to the given partner through contactgegevens
     * @param {string} partnerId ID of a partner
     * @param {Contactgegevens} contactgegevens 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public postPartnerGebruiker(partnerId: string, contactgegevens: Contactgegevens, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).postPartnerGebruiker(partnerId, contactgegevens, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing gebruiker for the given partner
     * @param {string} partnerId ID of a partner
     * @param {string} gebruikerId ID of a gebruiker within some partner
     * @param {Contactgegevens} contactgegevens 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public putPartnerGebruiker(partnerId: string, gebruikerId: string, contactgegevens: Contactgegevens, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).putPartnerGebruiker(partnerId, gebruikerId, contactgegevens, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reject the match by matchResultId
     * @param {string} matchResultId ID of a match result
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public rejectMatch(matchResultId: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).rejectMatch(matchResultId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a specific gebruiker by its id, from the specified partner
     * @param {string} partnerId ID of a partner
     * @param {string} gebruikerId ID of a gebruiker within some partner
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public resetPartnerGebruiker(partnerId: string, gebruikerId: string, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).resetPartnerGebruiker(partnerId, gebruikerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing aanbieding
     * @param {string} partnerId ID of a partner
     * @param {string} aanbiedingId ID of a aanbieding
     * @param {AanbiedingRequest} aanbiedingRequest The aanbieding to save
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public updateAanbieding(partnerId: string, aanbiedingId: string, aanbiedingRequest: AanbiedingRequest, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).updateAanbieding(partnerId, aanbiedingId, aanbiedingRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing partnerprofiel\'s properties
     * @param {string} partnerId ID of a partner
     * @param {PartnerprofielRequest} partnerprofielRequest The partnerprofiel to save
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PartnerApi
     */
    public updatePartnerprofiel(partnerId: string, partnerprofielRequest: PartnerprofielRequest, options?: AxiosRequestConfig) {
        return PartnerApiFp(this.configuration).updatePartnerprofiel(partnerId, partnerprofielRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
