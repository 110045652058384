import { useTranslation } from 'react-i18next';
import { Size } from '../../../common/Size';
import React, { useCallback } from 'react';
import Modal from '../../../common/modal/Modal';
import ModalContent from '../../../common/modal/ModalContent';
import ModalFooter from '../../../common/modal/ModalFooter';
import ModalContentPlain from '../../../common/modal/ModalContentPlain';
import CancelButton from '../../../common/button/CancelButton';
import usePartnerService from '../hooks/usePartnerService';
import { showErrorResponse } from '../../../helpers/notify';
import Button from '../../../common/button/Button';

type Props = {
    onCancel: () => void;
    matchResultId: string;
    onReject: () => void;
};

export default function MatchDeclineConfirmationModal({ onCancel, matchResultId, onReject }: Props) {
    const { t } = useTranslation();
    const partnerService = usePartnerService();

    const rejectProposal = useCallback(
        (matchResultId: string) => {
            partnerService
                .rejectMatch(matchResultId)
                .then(() => onReject())
                .catch(error => showErrorResponse(t('common:failedToUpdate'), error, t));
        },
        [partnerService, onReject, t]
    );

    return (
        <>
            <Modal dataTestId={'form-modal'} size={Size.Large}>
                <ModalContent>
                    <ModalContentPlain>
                        <div className='text-gray-500 pt-4 pb-4 text-2xl'>
                            {t('partner:matches.rejectConfirmation')}
                        </div>
                        <ModalFooter>
                            <CancelButton disabled={false} onClick={() => onCancel()} />
                            <Button
                                variant='contained'
                                color='warning'
                                onClick={() => rejectProposal(matchResultId)}
                                dataTestId={'reject-match-confirmation-button'}>
                                {t('partner:matches.reject')}
                            </Button>
                        </ModalFooter>
                    </ModalContentPlain>
                </ModalContent>
            </Modal>
        </>
    );
}
