import Button from '../../../common/button/Button';
import { Addon } from '../../../generated/softwarematching-api';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';
import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

type Props = {
    name: string;
    onAdd: () => void;
    onEdit: (addon: Addon, index: number) => void;
};

export default function AddonListField({ name, onAdd, onEdit }: Props) {
    const { t } = useTranslation();
    const [field] = useField<Addon[]>(name);

    function renderAddon(item: Addon, index: number, arrayHelpers: FieldArrayRenderProps) {
        return (
            <tr key={index} className='admin-table--body-row hover:cursor-pointer' onClick={() => onEdit(item, index)}>
                <td className='admin-table--body-cell text-bold pl-4'>{item.naam}</td>
                <td className='admin-table--body-cell w-10' data-testid={`edit-addon-${item.naam}`}>
                    <FaEdit className='admin-icon hover:text-black' />
                </td>
                <td
                    className='admin-table--body-cell w-10'
                    data-testid={`delete-addon-${item.naam}`}
                    onClick={event => {
                        event.stopPropagation();
                        arrayHelpers.remove(index);
                    }}>
                    <FaTrash className='admin-icon fill-warning hover:fill-red-800' />
                </td>
            </tr>
        );
    }

    return (
        <FieldArray
            name={name}
            render={(arrayHelpers: FieldArrayRenderProps) => (
                <div>
                    {field.value.length ? (
                        <table className='admin-table' data-testid='addon-table'>
                            <thead>
                                <tr className='admin-table--header-row'>
                                    <th className='admin-table--header-cell pl-4'>Naam</th>
                                    <th className='admin-table--header-cell'></th>
                                    <th className='admin-table--header-cell'></th>
                                </tr>
                            </thead>
                            <tbody>{field.value.map((item, index) => renderAddon(item, index, arrayHelpers))}</tbody>
                        </table>
                    ) : (
                        <span className='block py-4 text-lg italic'>{t('partner:addon.noAddons')}</span>
                    )}
                    <Button variant='outlined' dataTestId='add-addon-btn' onClick={() => onAdd()}>
                        {t('partner:addon.add')}
                    </Button>
                </div>
            )}
        />
    );
}
