import {
    BedrijfContactInfo,
    Intake,
    IntakeMatch,
    IntakeMatchDetails,
    IntakeMatchMeeting,
    IntakeMatchMeetingRequest,
    IntakeMatchResult,
    IntakeMatchSelectRequest,
    IntakeWithMatchState,
    MeetingDate,
    ProtectedApi,
} from '../../../generated/softwarematching-api';
import { useToken } from '../../../hooks/auth-hooks';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { TokenizedService } from '../../../hooks/TokenizedService';

export default function useProtectedService() {
    const token = useToken();
    const { i18n } = useTranslation();
    const protectedService = useMemo(() => new ProtectedService(i18n.language), [i18n.language]);
    useEffect(() => protectedService.updateToken(token), [token, protectedService]);
    return protectedService;
}

export class ProtectedService extends TokenizedService<ProtectedApi> {
    public constructor(language: string) {
        super(ProtectedApi, language);
    }

    getMatchedIntakes(): Promise<Intake[]> {
        return this.api.getMatchedIntakes().then(response => response.data);
    }

    getIntakes(): Promise<Intake[]> {
        return this.api.getIntakes().then(response => response.data);
    }

    postAanmelding(klantvraagId: string): Promise<void> {
        return this.api.postAanmelding(klantvraagId).then(response => response.data);
    }

    getIntakeMatches(klantvraagId: string): Promise<IntakeMatchResult> {
        return this.api.getIntakeMatches(klantvraagId).then(response => response.data);
    }

    selectMatches(klantvraagId: string, intakeMatchSelectRequest: IntakeMatchSelectRequest): Promise<IntakeMatch[]> {
        return this.api.selectMatches(klantvraagId, intakeMatchSelectRequest).then(response => response.data);
    }

    getMeetingDateOptions(klantvraagId: string): Promise<MeetingDate[]> {
        return this.api.getMeetingDateOptions(klantvraagId).then(response => response.data);
    }

    getMeetingProposal(klantvraagId: string): Promise<IntakeMatchMeeting> {
        return this.api.getMeetingProposal(klantvraagId).then(response => response.data);
    }

    createMeetingProposal(
        klantvraagId: string,
        intakeMatchMeetingRequest: IntakeMatchMeetingRequest
    ): Promise<IntakeMatchMeeting> {
        return this.api.createMeetingProposal(klantvraagId, intakeMatchMeetingRequest).then(response => response.data);
    }

    proposeToPartners(klantvraagId: string): Promise<void> {
        return this.api.proposeToPartners(klantvraagId).then(response => response.data);
    }

    getInProgressIntakes(): Promise<IntakeWithMatchState[]> {
        return this.api.getInProgressIntakes().then(response => response.data);
    }

    getIntakeMatchDetails(matchResultId: string): Promise<IntakeMatchDetails> {
        return this.api.getIntakeMatchDetails(matchResultId).then(response => response.data);
    }

    acceptProposedMeeting(matchResultId: string, acceptTimeSlot: boolean): Promise<void> {
        return this.api.acceptProposedMeeting(matchResultId, { acceptTimeSlot }).then(response => response.data);
    }

    postBedrijfContactInfo(klantvraagId: string, bedrijfContactInfo: BedrijfContactInfo): Promise<void> {
        return this.api.postBedrijfContactInfo(klantvraagId, bedrijfContactInfo).then(response => response.data);
    }
}
