import React, { useContext, useMemo } from 'react';
import { PhaseType, PhaseState, VraaggroepState } from '../../../../generated/softwarematching-api';
import { phaseContext } from '../../../../common/ContextTypes';
import { phaseTypeToText } from '../../helpers/intake';
import { useTranslation } from 'react-i18next';

type Props = {
    phaseType: PhaseType;
    phaseState: PhaseState | VraaggroepState;
    groupText?: string;
};

export default function IntakeVoortgangItem({ phaseState, phaseType, groupText }: Props) {
    const { t } = useTranslation();
    const { PhaseChangeHandler, intakeVoortgang } = useContext(phaseContext);
    const text = groupText ? groupText : phaseTypeToText(phaseType, t);

    const itemClasses =
        phaseState !== PhaseState.NOT_STARTED &&
        (intakeVoortgang?.items.some(obj => obj.phaseType === phaseType) ?? false)
            ? 'text-unimportant -mt-2 font-medium cursor-pointer hover:underline'
            : 'text-unimportant -mt-2 font-medium';

    const icon = useMemo(() => {
        switch (phaseState) {
            case PhaseState.NOT_STARTED:
                return <div data-testid='not-started-icon' className='progress-icon bg-faded outline-transparent' />;
            case PhaseState.IN_PROGRESS:
                return <div data-testid='in-progress-icon' className='progress-icon bg-primary outline-[#E3F0FF]' />;
            case PhaseState.COMPLETED:
                return <div data-testid='completed-icon' className='progress-icon bg-primary outline-transparent' />;
            default:
                return <div className='progress-icon bg-faded outline-transparent' />;
        }
    }, [phaseState]);

    return (
        <div className='flex gap-4 align-baseline' data-testid='voortgang-item'>
            {icon}
            <div
                data-testid={phaseType}
                className={itemClasses}
                data-intakeid={text}
                onClick={() => PhaseChangeHandler(phaseType, phaseState)}>
                {text}
            </div>
        </div>
    );
}
