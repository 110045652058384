import { Bedrijfsactiviteit } from '../../../generated/softwarematching-api';
import FormModal from '../../../common/modal/FormModal';
import { Size } from '../../../common/Size';
import React from 'react';
import * as yup from 'yup';
import BedrijfsactiviteitenSelectie from './BedrijfsactiviteitenSelectie';
import { useTranslation } from 'react-i18next';

type Props = {
    bedrijfsactiviteiten: Bedrijfsactiviteit[];
    selected: string[];
    onAdd: (selectedBedrijfsactiviteitIds: string[]) => void;
};

export type ChooseBedrijfactiviteitenValues = {
    sector: string | null;
    branche: string | null;
    subbranches: string[];
    allSubbranches: boolean;
};

const initialValues: ChooseBedrijfactiviteitenValues = {
    sector: '',
    branche: '',
    subbranches: [],
    allSubbranches: false,
};

export default function BedrijfsactiviteitenSelectieFormModal({ bedrijfsactiviteiten, onAdd, selected }: Props) {
    const { t } = useTranslation();

    const handleSubmit = (values: string[]) => {
        onAdd(values);
    };

    const validationScheme = yup.object().shape({
        sector: yup.string().required(),
        branche: yup.string().required(),
        subbranches: yup
            .array()
            .required()
            .min(1)
            .when('allSubbranches', { is: true, then: schema => schema.min(0) }),
        allSubbranches: yup.bool(),
    });

    return (
        <FormModal
            title={t('partner:aanbod.bedrijfsactiviteitenAdd')}
            size={Size.Large}
            initialValues={initialValues}
            validationSchema={validationScheme}
            submitLabel={t('common:add')}
            onSubmit={values => handleSubmit(values.allSubbranches ? [values.branche!] : values.subbranches)}
            onCancel={() => handleSubmit([])}>
            <BedrijfsactiviteitenSelectie
                bedrijfsactiviteiten={bedrijfsactiviteiten}
                selectedBedrijfsactiviteitenIds={selected}
            />
        </FormModal>
    );
}
