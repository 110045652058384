import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { useLocation } from 'react-router-dom';

type Props = {
    children: ReactNode;
};

export default function HeaderHamburger({ children }: Props) {
    const location = useLocation();
    const [isOpen, setOpen] = useState<boolean>(false);

    const dropdownRef = useRef<any>(null);
    const handleToggleMenu = useCallback(() => setOpen(prevState => !prevState), [setOpen]);

    const handleFocus = useCallback(() => setOpen(true), [setOpen]);
    const handleBlur = useCallback(() => setOpen(false), [setOpen]);

    useEffect(() => {
        setOpen(false);
    }, [location.pathname]);

    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            const wasClickedOutsideOfDropdown = !dropdownRef.current?.contains(e.target);
            if (wasClickedOutsideOfDropdown) {
                setOpen(false);
            }
        };
        window.addEventListener('click', handleClickOutside);
        return () => window.removeEventListener('click', handleClickOutside);
    }, [setOpen, dropdownRef]);

    return (
        <div
            className='lg:hidden flex items-center relative hover:cursor-pointer'
            onClick={handleToggleMenu}
            ref={dropdownRef}>
            <div className='text-2xl m-4 text-regular flex items-center pointer-events-none'>
                {isOpen ? <FaTimes className='inline-block' /> : <FaBars className='inline-block' />}
            </div>
            <div
                className={`fixed top-20 left-0 w-screen z-10 bg-white overflow-hidden transition-all ${
                    isOpen ? 'h-screen py-4' : 'h-0 pt-0'
                }`}
                onFocus={handleFocus}
                onBlur={handleBlur}>
                <div className='container mx-auto px-4 text-2xl text-light whitespace-nowrap'>{children}</div>
            </div>
        </div>
    );
}
