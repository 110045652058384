import { Bedrijfsactiviteit } from '../../generated/softwarematching-api';
import { SelectOption } from '../form/FormFieldSelect';
import { TFunction } from 'i18next';

export function bedrijfsactiviteitenToSelectOptions(
    bedrijfsactiviteiten: Bedrijfsactiviteit[],
    t: TFunction
): SelectOption[] {
    return bedrijfsactiviteiten?.map<SelectOption>(it => {
        const deprecatedText = it.deprecated ? ` (${t('common:deprecated')})` : '';
        return {
            value: it.id,
            label: it.naam + deprecatedText,
        };
    });
}

export function flattenBedrijfsactiviteiten(members: Bedrijfsactiviteit[]): Bedrijfsactiviteit[] {
    const flattenChilds = members.flatMap(m => m.children);
    if (flattenChilds.length > 0) {
        return members.concat(flattenBedrijfsactiviteiten(flattenChilds));
    } else {
        return members.concat(flattenChilds);
    }
}
