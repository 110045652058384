import { FieldArray, FieldArrayRenderProps, useField } from 'formik';
import { AddonFunctionaliteit, FunctionaliteitOndersteuning } from '../../../generated/softwarematching-api';
import FormFieldSelect, { SelectOption } from '../../../common/form/FormFieldSelect';
import { FaTrash } from 'react-icons/fa';
import FormFieldError from '../../../common/form/FormFieldError';
import Button from '../../../common/button/Button';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    name: string;
    functionaliteitenOptions: SelectOption[];
};

export default function AddonFunctionaliteitFieldArray({ name, functionaliteitenOptions }: Props) {
    const { t } = useTranslation();
    const [field, meta] = useField<AddonFunctionaliteit[] | undefined>(name);
    const currentSelected = meta.value || [];

    const eenheidOptions: SelectOption[] = Object.keys(FunctionaliteitOndersteuning).map<SelectOption>(c => ({
        value: c,
        label: t(`partner:functionaliteitOndersteuning.${c}`),
    }));

    const availableFunctionaliteiten = useMemo(() => {
        const usedFunctionaliteitenIds = (field.value || []).map(item => item.functionaliteitId);
        return functionaliteitenOptions.filter(option => !usedFunctionaliteitenIds.includes(option.value));
    }, [field.value, functionaliteitenOptions]);

    function renderFunctionaliteit(
        addonFunctionaliteit: AddonFunctionaliteit,
        index: number,
        arrayHelpers: FieldArrayRenderProps
    ) {
        //Make sure the availableFunctionaliteiten include the currently selected option (required by react-select)
        const selectedOption = functionaliteitenOptions.filter(
            item => item.value === addonFunctionaliteit.functionaliteitId
        );

        return (
            <div className='flex items-start justify-between mb-4' key={index}>
                <div className='grid grid-cols-3 gap-4 w-full'>
                    <div className='col-span-2'>
                        <FormFieldSelect
                            name={`${name}.${index}.functionaliteitId`}
                            label={index === 0 ? t('partner:addon.functionaliteit') : ''}
                            options={[...availableFunctionaliteiten, ...selectedOption]}
                            menuPosition='fixed'
                            required
                            dataTestId={`functionaliteit_${index}`}
                        />
                    </div>
                    <div>
                        <FormFieldSelect
                            name={`${name}.${index}.ondersteuning`}
                            label={index === 0 ? t('partner:addon.ondersteuning') : ''}
                            options={eenheidOptions}
                            menuPosition='fixed'
                            required
                            dataTestId={`ondersteuning_${index}`}
                        />
                    </div>
                </div>

                <FaTrash
                    className={
                        'admin-icon fill-warning hover:fill-red-800 h-4 w-4 ml-2 flex-none ' +
                        (index === 0 ? 'mt-9' : 'mt-4')
                    }
                    data-testid={`remove-funtionaliteit_${index}`}
                    onClick={() => arrayHelpers.remove(index)}></FaTrash>
            </div>
        );
    }

    return (
        <FieldArray
            name={name}
            render={(arrayHelpers: FieldArrayRenderProps) => (
                <div className=''>
                    {currentSelected.length ? (
                        currentSelected.map((item, index) => renderFunctionaliteit(item, index, arrayHelpers))
                    ) : (
                        <>
                            <h3 className='font-medium text-gray-600 mb-1'>{t('partner:addon.functionaliteit')}</h3>
                            <span className='block py-4 text-lg italic'>
                                {t('partner:addon.noFunctionaliteitSelected')}
                            </span>
                            <FormFieldError name={name} />
                        </>
                    )}
                    <Button
                        variant='outlined'
                        dataTestId='add-functionaliteit-btn'
                        onClick={() =>
                            arrayHelpers.push({
                                functionaliteitId: '',
                                ondersteuning: FunctionaliteitOndersteuning.VOLLEDIG,
                            })
                        }>
                        {t('partner:addon.addFunctionaliteit')}
                    </Button>
                </div>
            )}
        />
    );
}
