import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { IntakeVoortgang, IntakeVoortgangItem } from '../../../../generated/softwarematching-api';
import { showErrorResponse } from '../../../../helpers/notify';
import useIntakeService from '../../hooks/useIntakeService';
import IntakePhase from '../IntakePhase';
import FormFieldCurrency from '../../../../common/form/FormFieldCurrency';
import { makeCancellable } from '../../../../helpers/promise';
import { BrowseDirection, MAX_BUDGET, MIN_BUDGET } from '../../../../common/Constants';
import FormFieldNumber from '../../../../common/form/FormFieldNumber';

export type ChooseBudgetValues = {
    implementatieBudget?: number | null;
    maandelijksBudget?: number | null;
    beoogdeAantalMaandenTotImplementatie?: number | null;
    beoogdeAantalMaandenTotBeslissing?: number | null;
};

const defaultValues: ChooseBudgetValues = {
    implementatieBudget: 0,
    maandelijksBudget: 0,
    beoogdeAantalMaandenTotImplementatie: null,
    beoogdeAantalMaandenTotBeslissing: null,
};

type Props = {
    intakeVoortgang: IntakeVoortgang;
    currentIntakeVoortgangItem: IntakeVoortgangItem;
    onUpdateIntakeVoortgang: (intakeVoortgang: IntakeVoortgang) => void;
    browse: (direction: BrowseDirection) => void;
};

const MAX_AANTAL_MAANDEN = 99;
const MIN_AANTAL_MAANDEN = 1;

export default function IntakeProject({
    intakeVoortgang,
    currentIntakeVoortgangItem,
    onUpdateIntakeVoortgang,
    browse,
}: Props) {
    const klantvraagId = intakeVoortgang.klantvraagId!!;
    const { t } = useTranslation();
    const intakeService = useIntakeService();
    const [projectValue, setProjectValue] = useState<ChooseBudgetValues>(defaultValues);

    const handleSubmit = useCallback(
        (values: ChooseBudgetValues) => {
            intakeService
                .postProject(
                    klantvraagId,
                    values.implementatieBudget,
                    values.maandelijksBudget,
                    values.beoogdeAantalMaandenTotImplementatie,
                    values.beoogdeAantalMaandenTotBeslissing
                )
                .then(onUpdateIntakeVoortgang)
                .catch(error => showErrorResponse(t('common:failedToUpdate'), error, t));
        },
        [t, intakeService, klantvraagId, onUpdateIntakeVoortgang]
    );

    const validationSchema = yup.object({
        implementatieBudget: yup
            .number()
            .integer()
            .typeError(t('validation:number.typeError'))
            .min(MIN_BUDGET)
            .max(MAX_BUDGET)
            .nullable(),
        maandelijksBudget: yup
            .number()
            .integer()
            .typeError(t('validation:number.typeError'))
            .min(MIN_BUDGET)
            .max(MAX_BUDGET)
            .nullable(),
        beoogdeAantalMaandenTotImplementatie: yup
            .number()
            .integer()
            .typeError(t('validation:number.typeError'))
            .min(MIN_AANTAL_MAANDEN)
            .max(MAX_AANTAL_MAANDEN)
            .nullable(),
        beoogdeAantalMaandenTotBeslissing: yup
            .number()
            .integer()
            .typeError(t('validation:number.typeError'))
            .min(MIN_AANTAL_MAANDEN)
            .max(MAX_AANTAL_MAANDEN)
            .nullable(),
    });

    useEffect(() => {
        if (currentIntakeVoortgangItem.state === 'COMPLETED') {
            const [promise, cancel] = makeCancellable(intakeService.getProject(klantvraagId));
            promise.then(setProjectValue).catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t));
            return cancel;
        }
    }, [currentIntakeVoortgangItem, intakeVoortgang, intakeService, t, klantvraagId]);

    return (
        <IntakePhase
            title={t('intake.intakePhase.PROJECT')}
            intakeVoortgang={intakeVoortgang}
            currentIntakeVoortgangItem={currentIntakeVoortgangItem}
            showNextButton
            showPreviousButton
            previousCallback={() => browse('previous')}>
            <Formik
                initialValues={projectValue}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleSubmit}>
                <Form className='w-full flex gap-4 flex-col' id='intake-form' noValidate>
                    <h2 className='text-2xl font-light'>{t('domain:intake.titles.project')}</h2>
                    <FormFieldCurrency
                        name='implementatieBudget'
                        label={t('domain:intake.project.implementatie')}
                        min={MIN_BUDGET}
                        max={MAX_BUDGET}
                    />
                    <FormFieldCurrency
                        name='maandelijksBudget'
                        label={t('domain:intake.project.maandelijks')}
                        min={MIN_BUDGET}
                        max={MAX_BUDGET}
                    />
                    <FormFieldNumber
                        name='beoogdeAantalMaandenTotImplementatie'
                        label={t('domain:intake.project.beoogdeAantalMaandenTotImplementatie')}
                        min={MIN_AANTAL_MAANDEN}
                        max={MAX_AANTAL_MAANDEN}
                    />
                    <FormFieldNumber
                        name='beoogdeAantalMaandenTotBeslissing'
                        label={t('domain:intake.project.beoogdeAantalMaandenTotBeslissing')}
                        min={MIN_AANTAL_MAANDEN}
                        max={MAX_AANTAL_MAANDEN}
                    />
                </Form>
            </Formik>
        </IntakePhase>
    );
}
