import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import Role from '../../models/Role';
import { useAuthRoles } from '../../hooks/auth-hooks';

export interface MenuItem {
    name: string;
    icon: ReactNode;
    to: string;
    roles?: Role[];
}

type Props = {
    menuItems: MenuItem[];
};

export function NavigationMenu(props: Props) {
    const { hasRole } = useAuthRoles();

    return (
        <nav className='hidden sm:block'>
            {props.menuItems.map(item => {
                const hasRequiredRole = item.roles?.some(hasRole) ?? true;

                const cssClasses = ({ isActive }: { isActive: boolean }): string => {
                    return `text-primary font-medium mx-2 mt-1 p-2 hover:bg-primary-light hover:text-white rounded flex items-center ${
                        isActive ? 'active' : ''
                    }`;
                };
                return (
                    hasRequiredRole && (
                        <NavLink key={item.name} to={item.to} className={cssClasses}>
                            {item.icon}
                            <span className='hidden md:block pl-3 w-48'>{item.name}</span>
                        </NavLink>
                    )
                );
            })}
        </nav>
    );
}
