import { IntakeWithMatchState, MatchStatus } from '../../../generated/softwarematching-api';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ListItem } from '../../../common/ListItem';
import { FaChevronRight } from 'react-icons/fa';
import React from 'react';

type Props = {
    matchState: IntakeWithMatchState;
};

export default function InProgressMatchListViewItem({ matchState }: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const hasDetails = matchState.match.status !== MatchStatus.PROPOSED;
    const onClickHandler = hasDetails
        ? () => navigate(`/portal/intakes/matches/${matchState.match.matchResultId}`)
        : undefined;

    return (
        <ListItem
            title={
                t('portal.matchItemTitle', {
                    softwareType: matchState.match.product.softwareType,
                }) + ` -  ${matchState.match.partnerName}`
            }
            subTitle={t(`matchStatusTitle.${matchState.match.status}`)}
            onClick={onClickHandler}>
            <div className='badge badge-secondary uppercase mr-4'>{t(`matchStatus.${matchState.match.status}`)}</div>
            {hasDetails && <FaChevronRight className='text-primary'></FaChevronRight>}
        </ListItem>
    );
}
