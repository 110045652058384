/* tslint:disable */
/* eslint-disable */
/**
 * Software Matching Api
 * Software Matching API for the match-making questionnaire service
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * An intake consists of phases; a phase can be in one of these states
 * @export
 * @enum {string}
 */

export const PhaseState = {
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED'
} as const;

export type PhaseState = typeof PhaseState[keyof typeof PhaseState];



