import { Link, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IntakeMatchDetails, MatchStatus } from '../../../generated/softwarematching-api';
import { makeCancellable } from '../../../helpers/promise';
import { showErrorResponse } from '../../../helpers/notify';
import useProtectedService from '../../intake/hooks/useProtectedService';
import Loader from '../../../common/Loader';
import MatchDetailsPartnerContactInfo from '../components/MatchDetailsPartnerContactInfo';
import MatchDetails from '../components/MatchDetails';
import MatchMeetingProposal from '../components/MatchMeetingProposal';

export default function MatchDetailsPage() {
    const { matchResultId } = useParams();
    const { t } = useTranslation();
    const protectedService = useProtectedService();
    const [loading, setLoading] = useState(false);
    const [matchDetails, setMatchDetails] = useState<IntakeMatchDetails | null>(null);

    const loadMatchDetails = useCallback(() => {
        if (!matchResultId) {
            return;
        }
        setLoading(true);

        const [promise, cancel] = makeCancellable(protectedService.getIntakeMatchDetails(matchResultId));
        promise
            .then(details => setMatchDetails(details))
            .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t))
            .finally(() => setLoading(false));
        return cancel;
    }, [protectedService, matchResultId, t]);

    useEffect(loadMatchDetails, [loadMatchDetails]);

    if (!matchResultId || !matchDetails) {
        return null;
    }
    if (loading) {
        return <Loader />;
    }

    return (
        <div className='container mx-auto px-4 gap-10 space-y-6'>
            <Link className='flex portal-body--link' to='/portal/intakes'>
                <FaArrowLeft className='text-primary mt-1.5 mr-3'></FaArrowLeft>
                {t('common:back')}
            </Link>
            <div className='badge badge-secondary uppercase'>{t(`matchStatus.${matchDetails.match.status}`)}</div>
            <h1 className='portal-heading-1 mb-5'>
                {t('portal.matchItemTitle', {
                    softwareType: matchDetails.match.product.softwareType,
                })}
            </h1>
            <div>{t(`matchDetails.helperText.${matchDetails.match.status}`)}</div>

            {matchDetails.match.status === MatchStatus.PROPOSED_ALTERNATIVE && matchDetails.meeting && (
                <MatchMeetingProposal
                    matchResultId={matchResultId}
                    meeting={matchDetails.meeting}
                    onAccepted={() => loadMatchDetails()}
                />
            )}

            {matchDetails.partner && (
                <div>
                    <h2 className='portal-heading-2 mb-3'>{t('matchDetails.implementatiepartnerTitle')}</h2>
                    <MatchDetailsPartnerContactInfo partner={matchDetails.partner} />
                </div>
            )}

            {matchDetails.match.status !== MatchStatus.REJECTED && (
                <div>
                    <h2 className='portal-heading-2 mb-4'>{t('matchDetails.matchInformatieTitle')}</h2>
                    <MatchDetails match={matchDetails.match}></MatchDetails>
                </div>
            )}
        </div>
    );
}
