import { useAuthPartnerId } from '../../../hooks/auth-hooks';
import UnauthorizedPage from '../../../common/page/UnauthorizedPage';
import React from 'react';
import { useParams } from 'react-router-dom';
import { MatchDetails } from '../components/MatchDetails';

export function PartnerMatchDetailsPage() {
    const partnerId = useAuthPartnerId();
    const { matchResultId } = useParams();

    if (!partnerId || !matchResultId) {
        return <UnauthorizedPage />;
    }

    return <MatchDetails matchResultId={matchResultId} />;
}
