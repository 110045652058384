import React from 'react';
import { PhaseProgress, PhaseState } from '../../../../generated/softwarematching-api';
import IntakeVoortgangIndicatorPhaseDetails from './IntakeVoortgangIndicatorPhaseDetails';
import IntakeVoortgangItem from './IntakeVoortgangItem';

type Props = {
    intakePhase: PhaseProgress;
};

export default function IntakeVoortgangIndicatorPhase({ intakePhase }: Props) {
    return (
        <div>
            <IntakeVoortgangItem phaseType={intakePhase.phaseType} phaseState={intakePhase.state} />
            {intakePhase.state === PhaseState.IN_PROGRESS && (
                <IntakeVoortgangIndicatorPhaseDetails phase={intakePhase} />
            )}
        </div>
    );
}
