import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import { useAuthWrapper } from '../../hooks/auth-hooks';

export default function LoginButton() {
    const { login, isAuthenticated } = useAuthWrapper();
    const { t } = useTranslation();

    if (!isAuthenticated) {
        return (
            <Button onClick={() => login()} variant='contained' color='secondary' dataTestId='login-button'>
                {t('common:login')}
            </Button>
        );
    }

    return null;
}
