import React, { useEffect, useState } from 'react';
import ProductAanbodBeherenForm from '../components/ProductAanbodBeherenForm';
import { AanbiedingRequest } from '../../../generated/softwarematching-api';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import usePartnerService from '../hooks/usePartnerService';
import { makeCancellable } from '../../../helpers/promise';
import Loader from '../../../common/Loader';
import { showErrorResponse } from '../../../helpers/notify';
import Button from '../../../common/button/Button';
import { useAuthRoles } from '../../../hooks/auth-hooks';
import Role from '../../../models/Role';

export default function AanbodEditPage() {
    const { hasRole } = useAuthRoles();
    const { partnerId, aanbiedingId } = useParams();
    const { t } = useTranslation();
    const partnerService = usePartnerService();
    const [aanbieding, setAanbieding] = useState<AanbiedingRequest>();

    useEffect(() => {
        if (partnerId && aanbiedingId) {
            const [promise, cancel] = makeCancellable(partnerService.getAanbieding(partnerId, aanbiedingId));
            promise
                .then(aanbieding => {
                    const { id, partnerprofielId, ...otherValues } = aanbieding;
                    setAanbieding(otherValues);
                })
                .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t));
            return cancel;
        }
    }, [partnerService, partnerId, aanbiedingId, t]);

    if (!partnerId || !aanbiedingId) {
        return <div>{t('common:error.REQUEST_MISSING_PARAMETER')}</div>;
    }
    if (!aanbieding) {
        return <Loader />;
    }
    return (
        <>
            <Link relative={'path'} to={hasRole(Role.PartnerAdmin) ? '/admin/profiel/aanbod' : '../'}>
                <Button className='mb-4' variant='contained' color='primary' type='button'>
                    {t('common:back')}
                </Button>
            </Link>
            <h1 className='font-header text-3xl mb-5'>{t('partner:aanbod.editTitle')}</h1>
            <ProductAanbodBeherenForm
                partnerId={partnerId}
                aanbiedingId={aanbiedingId}
                aanbieding={aanbieding}
                nieuweAanbieding={false}></ProductAanbodBeherenForm>
        </>
    );
}
