import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormFieldSelect from '../../../common/form/FormFieldSelect';
import { Bedrijfsactiviteit } from '../../../generated/softwarematching-api';
import { ChooseBedrijfactiviteitenValues } from './phase/IntakeBedrijfsactiviteitenKiezen';
import { useBedrijfsactiviteitenSelection } from '../../../common/bedrijfsactiviteiten/useBedrijfsactiviteitenSelection';

type Props = {
    bedrijfsactiviteiten: Bedrijfsactiviteit[];
};

export default function BedrijfsactiviteitKiezenFieldGroup({ bedrijfsactiviteiten }: Props) {
    const { t } = useTranslation();
    const { values } = useFormikContext<ChooseBedrijfactiviteitenValues>();

    const selectedSectorId = values.sector;
    const selectedBrancheId = values.branche;
    const selectedBedrijfsactiviteiten = values.selectedBedrijfsactiviteiten;

    const { sectorOptions, brancheOptions, subbrancheOptions } = useBedrijfsactiviteitenSelection(
        bedrijfsactiviteiten,
        selectedSectorId,
        selectedBrancheId,
        selectedBedrijfsactiviteiten
    );

    return (
        <>
            <h2 className='text-2xl font-light'>{t('intake.bedrijfsactiviteitKiezenTitle')}</h2>
            <FormFieldSelect
                name='sector'
                label={t('bedrijfsactiviteiten.sector')}
                options={sectorOptions}
                required
                clearable
            />
            <FormFieldSelect
                name='branche'
                label={t('bedrijfsactiviteiten.branche')}
                options={brancheOptions}
                required
                clearable
            />
            <FormFieldSelect
                name='subbranches'
                closeMenuOnSelect={false}
                label={t('bedrijfsactiviteiten.subbranche')}
                options={subbrancheOptions}
                multiple
                required
                clearable
            />
        </>
    );
}
