/* tslint:disable */
/* eslint-disable */
/**
 * Software Matching Api
 * Software Matching API for the match-making questionnaire service
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Bedrijfsactiviteit } from '../models';
// @ts-ignore
import { Bedrijfsprofiel } from '../models';
// @ts-ignore
import { ChosenSoftwareType } from '../models';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { Functionaliteitwens } from '../models';
// @ts-ignore
import { IntakeAntwoord } from '../models';
// @ts-ignore
import { IntakeContactgegevens } from '../models';
// @ts-ignore
import { IntakeVoortgang } from '../models';
// @ts-ignore
import { IntakeVraagAntwoord } from '../models';
// @ts-ignore
import { KlantvraagFunctionaliteitGroep } from '../models';
// @ts-ignore
import { PostBedrijfsactiviteitenRequest } from '../models';
// @ts-ignore
import { PostSoftwareTypeRequest } from '../models';
// @ts-ignore
import { Project } from '../models';
// @ts-ignore
import { SoftwareType } from '../models';
// @ts-ignore
import { StartIntakeRequest } from '../models';
/**
 * IntakeApi - axios parameter creator
 * @export
 */
export const IntakeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the full bedrijfsactiviteiten hierarchy, for a given intake version based on the klantvraag
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBedrijfsactiviteitenHierarchy: async (klantvraagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('getBedrijfsactiviteitenHierarchy', 'klantvraagId', klantvraagId)
            const localVarPath = `/intake/{klantvraag_id}/bedrijfsactiviteiten`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets bedrijfsprofiel of klantvraag_id
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBedrijfsprofiel: async (klantvraagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('getBedrijfsprofiel', 'klantvraagId', klantvraagId)
            const localVarPath = `/intake/{klantvraag_id}/bedrijfsprofiel`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all chosen bedrijfsactiviteiten by klantvraagId
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChosenBedrijfsactiviteiten: async (klantvraagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('getChosenBedrijfsactiviteiten', 'klantvraagId', klantvraagId)
            const localVarPath = `/intake/bedrijfsactiviteiten/{klantvraag_id}`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets functionaliteiten for a functionaliteiten vraaggroep with pre-filled answers based on the klantvraag
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFunctionaliteitwensen: async (klantvraagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('getFunctionaliteitwensen', 'klantvraagId', klantvraagId)
            const localVarPath = `/intake/{klantvraag_id}/functionaliteit_wensen`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets contactgegevens of klantvraag_id
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntakeContactgegevens: async (klantvraagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('getIntakeContactgegevens', 'klantvraagId', klantvraagId)
            const localVarPath = `/intake/{klantvraag_id}/contactgegevens`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the intake voortgang by klantvraag id
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntakeVoortgang: async (klantvraagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('getIntakeVoortgang', 'klantvraagId', klantvraagId)
            const localVarPath = `/intake/{klantvraag_id}`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets the project values that are currently known
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: async (klantvraagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('getProject', 'klantvraagId', klantvraagId)
            const localVarPath = `/intake/{klantvraag_id}/project`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the softwareType, for a given intake version based on the klantvraag
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoftwareType: async (klantvraagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('getSoftwareType', 'klantvraagId', klantvraagId)
            const localVarPath = `/intake/{klantvraag_id}/softwaretype`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the full list of software types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoftwareTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/intake/sofwaretypes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a vraag by id
         * @param {string} klantvraagId ID of klantvraag
         * @param {string} vraagId ID of the vraag to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVraag: async (klantvraagId: string, vraagId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('getVraag', 'klantvraagId', klantvraagId)
            // verify required parameter 'vraagId' is not null or undefined
            assertParamExists('getVraag', 'vraagId', vraagId)
            const localVarPath = `/intake/{klantvraag_id}/vraag/{vraag_id}`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)))
                .replace(`{${"vraag_id"}}`, encodeURIComponent(String(vraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Posts an antwoord to a vraag in a bedrijfsactiviteiten-vraaggroep
         * @param {string} klantvraagId ID of klantvraag
         * @param {IntakeAntwoord} intakeAntwoord An array of antwoorden for a specific vraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBedrijfsactiviteitAntwoord: async (klantvraagId: string, intakeAntwoord: IntakeAntwoord, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('postBedrijfsactiviteitAntwoord', 'klantvraagId', klantvraagId)
            // verify required parameter 'intakeAntwoord' is not null or undefined
            assertParamExists('postBedrijfsactiviteitAntwoord', 'intakeAntwoord', intakeAntwoord)
            const localVarPath = `/intake/{klantvraag_id}/bedrijfsactiviteitvraag`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(intakeAntwoord, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submit the chosen bedrijfsactiviteiten for this intake
         * @param {string} klantvraagId ID of klantvraag
         * @param {PostBedrijfsactiviteitenRequest} postBedrijfsactiviteitenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBedrijfsactiviteiten: async (klantvraagId: string, postBedrijfsactiviteitenRequest: PostBedrijfsactiviteitenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('postBedrijfsactiviteiten', 'klantvraagId', klantvraagId)
            // verify required parameter 'postBedrijfsactiviteitenRequest' is not null or undefined
            assertParamExists('postBedrijfsactiviteiten', 'postBedrijfsactiviteitenRequest', postBedrijfsactiviteitenRequest)
            const localVarPath = `/intake/{klantvraag_id}/bedrijfsactiviteiten`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postBedrijfsactiviteitenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Store the company information
         * @param {string} klantvraagId ID of klantvraag
         * @param {Bedrijfsprofiel} bedrijfsprofiel The company profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBedrijfsprofiel: async (klantvraagId: string, bedrijfsprofiel: Bedrijfsprofiel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('postBedrijfsprofiel', 'klantvraagId', klantvraagId)
            // verify required parameter 'bedrijfsprofiel' is not null or undefined
            assertParamExists('postBedrijfsprofiel', 'bedrijfsprofiel', bedrijfsprofiel)
            const localVarPath = `/intake/{klantvraag_id}/bedrijfsprofiel`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bedrijfsprofiel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Store the business contact information
         * @param {string} klantvraagId ID of klantvraag
         * @param {IntakeContactgegevens} intakeContactgegevens The business contact information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContactgegevens: async (klantvraagId: string, intakeContactgegevens: IntakeContactgegevens, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('postContactgegevens', 'klantvraagId', klantvraagId)
            // verify required parameter 'intakeContactgegevens' is not null or undefined
            assertParamExists('postContactgegevens', 'intakeContactgegevens', intakeContactgegevens)
            const localVarPath = `/intake/{klantvraag_id}/contactgegevens`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(intakeContactgegevens, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Posts an antwoord to a vraag for a vraag in a functionaliteiten-vraaggroep
         * @param {string} klantvraagId ID of klantvraag
         * @param {IntakeAntwoord} intakeAntwoord An array of antwoorden for a specific vraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFunctionaliteitAntwoord: async (klantvraagId: string, intakeAntwoord: IntakeAntwoord, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('postFunctionaliteitAntwoord', 'klantvraagId', klantvraagId)
            // verify required parameter 'intakeAntwoord' is not null or undefined
            assertParamExists('postFunctionaliteitAntwoord', 'intakeAntwoord', intakeAntwoord)
            const localVarPath = `/intake/{klantvraag_id}/functionaliteitenvraag`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(intakeAntwoord, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Post functionaliteitenwensen for a functionaliteitengroep
         * @param {string} klantvraagId ID of klantvraag
         * @param {Array<Functionaliteitwens>} functionaliteitwens An array of functionaliteitwensen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFunctionaliteitwensen: async (klantvraagId: string, functionaliteitwens: Array<Functionaliteitwens>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('postFunctionaliteitwensen', 'klantvraagId', klantvraagId)
            // verify required parameter 'functionaliteitwens' is not null or undefined
            assertParamExists('postFunctionaliteitwensen', 'functionaliteitwens', functionaliteitwens)
            const localVarPath = `/intake/{klantvraag_id}/functionaliteit_wensen`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(functionaliteitwens, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Posts the maximum implementation and monthly service costs for filtering matches. Optionally can also update period till implementation and/or decision for a project
         * @param {string} klantvraagId ID of klantvraag
         * @param {Project} project The selected project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProject: async (klantvraagId: string, project: Project, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('postProject', 'klantvraagId', klantvraagId)
            // verify required parameter 'project' is not null or undefined
            assertParamExists('postProject', 'project', project)
            const localVarPath = `/intake/{klantvraag_id}/project`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(project, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Submit the chosen softwareType for this intake
         * @param {string} klantvraagId ID of klantvraag
         * @param {PostSoftwareTypeRequest} postSoftwareTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSoftwareType: async (klantvraagId: string, postSoftwareTypeRequest: PostSoftwareTypeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('postSoftwareType', 'klantvraagId', klantvraagId)
            // verify required parameter 'postSoftwareTypeRequest' is not null or undefined
            assertParamExists('postSoftwareType', 'postSoftwareTypeRequest', postSoftwareTypeRequest)
            const localVarPath = `/intake/{klantvraag_id}/softwaretype`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postSoftwareTypeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an antwoord to a vraag in a bedrijfsactiviteiten-vraaggroep
         * @param {string} klantvraagId ID of klantvraag
         * @param {string} vraagId ID of vraag
         * @param {IntakeAntwoord} intakeAntwoord An array of antwoorden for a specific vraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBedrijfsactiviteitAntwoord: async (klantvraagId: string, vraagId: string, intakeAntwoord: IntakeAntwoord, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('putBedrijfsactiviteitAntwoord', 'klantvraagId', klantvraagId)
            // verify required parameter 'vraagId' is not null or undefined
            assertParamExists('putBedrijfsactiviteitAntwoord', 'vraagId', vraagId)
            // verify required parameter 'intakeAntwoord' is not null or undefined
            assertParamExists('putBedrijfsactiviteitAntwoord', 'intakeAntwoord', intakeAntwoord)
            const localVarPath = `/intake/{klantvraag_id}/bedrijfsactiviteitvraag/{vraag_id}`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)))
                .replace(`{${"vraag_id"}}`, encodeURIComponent(String(vraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(intakeAntwoord, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an antwoord to a vraag in a functionaliteiten-vraaggroep
         * @param {string} klantvraagId ID of klantvraag
         * @param {string} vraagId ID of vraag
         * @param {IntakeAntwoord} intakeAntwoord An array of antwoorden for a specific vraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFunctionaliteitAntwoord: async (klantvraagId: string, vraagId: string, intakeAntwoord: IntakeAntwoord, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('putFunctionaliteitAntwoord', 'klantvraagId', klantvraagId)
            // verify required parameter 'vraagId' is not null or undefined
            assertParamExists('putFunctionaliteitAntwoord', 'vraagId', vraagId)
            // verify required parameter 'intakeAntwoord' is not null or undefined
            assertParamExists('putFunctionaliteitAntwoord', 'intakeAntwoord', intakeAntwoord)
            const localVarPath = `/intake/{klantvraag_id}/functionaliteitenvraag/{vraag_id}`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)))
                .replace(`{${"vraag_id"}}`, encodeURIComponent(String(vraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(intakeAntwoord, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates functionaliteitwensen
         * @param {string} klantvraagId ID of klantvraag
         * @param {Array<Functionaliteitwens>} functionaliteitwens An array of functionaliteitwensen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFunctionaliteitwensen: async (klantvraagId: string, functionaliteitwens: Array<Functionaliteitwens>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'klantvraagId' is not null or undefined
            assertParamExists('putFunctionaliteitwensen', 'klantvraagId', klantvraagId)
            // verify required parameter 'functionaliteitwens' is not null or undefined
            assertParamExists('putFunctionaliteitwensen', 'functionaliteitwens', functionaliteitwens)
            const localVarPath = `/intake/{klantvraag_id}/functionaliteit_wensen`
                .replace(`{${"klantvraag_id"}}`, encodeURIComponent(String(klantvraagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(functionaliteitwens, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Start a new intake
         * @param {StartIntakeRequest} startIntakeRequest Params for starting the intake e.g. the softwareType chosen on the sm.io website
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startIntake: async (startIntakeRequest: StartIntakeRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startIntakeRequest' is not null or undefined
            assertParamExists('startIntake', 'startIntakeRequest', startIntakeRequest)
            const localVarPath = `/intake`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startIntakeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntakeApi - functional programming interface
 * @export
 */
export const IntakeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntakeApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the full bedrijfsactiviteiten hierarchy, for a given intake version based on the klantvraag
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBedrijfsactiviteitenHierarchy(klantvraagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Bedrijfsactiviteit>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBedrijfsactiviteitenHierarchy(klantvraagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets bedrijfsprofiel of klantvraag_id
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBedrijfsprofiel(klantvraagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Bedrijfsprofiel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBedrijfsprofiel(klantvraagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get all chosen bedrijfsactiviteiten by klantvraagId
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChosenBedrijfsactiviteiten(klantvraagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Bedrijfsactiviteit>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChosenBedrijfsactiviteiten(klantvraagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets functionaliteiten for a functionaliteiten vraaggroep with pre-filled answers based on the klantvraag
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFunctionaliteitwensen(klantvraagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KlantvraagFunctionaliteitGroep>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFunctionaliteitwensen(klantvraagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets contactgegevens of klantvraag_id
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntakeContactgegevens(klantvraagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeContactgegevens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntakeContactgegevens(klantvraagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the intake voortgang by klantvraag id
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIntakeVoortgang(klantvraagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeVoortgang>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIntakeVoortgang(klantvraagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets the project values that are currently known
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProject(klantvraagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(klantvraagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the softwareType, for a given intake version based on the klantvraag
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSoftwareType(klantvraagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChosenSoftwareType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSoftwareType(klantvraagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the full list of software types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSoftwareTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SoftwareType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSoftwareTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a vraag by id
         * @param {string} klantvraagId ID of klantvraag
         * @param {string} vraagId ID of the vraag to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVraag(klantvraagId: string, vraagId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeVraagAntwoord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVraag(klantvraagId, vraagId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Posts an antwoord to a vraag in a bedrijfsactiviteiten-vraaggroep
         * @param {string} klantvraagId ID of klantvraag
         * @param {IntakeAntwoord} intakeAntwoord An array of antwoorden for a specific vraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postBedrijfsactiviteitAntwoord(klantvraagId: string, intakeAntwoord: IntakeAntwoord, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeVoortgang>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postBedrijfsactiviteitAntwoord(klantvraagId, intakeAntwoord, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Submit the chosen bedrijfsactiviteiten for this intake
         * @param {string} klantvraagId ID of klantvraag
         * @param {PostBedrijfsactiviteitenRequest} postBedrijfsactiviteitenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postBedrijfsactiviteiten(klantvraagId: string, postBedrijfsactiviteitenRequest: PostBedrijfsactiviteitenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeVoortgang>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postBedrijfsactiviteiten(klantvraagId, postBedrijfsactiviteitenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Store the company information
         * @param {string} klantvraagId ID of klantvraag
         * @param {Bedrijfsprofiel} bedrijfsprofiel The company profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postBedrijfsprofiel(klantvraagId: string, bedrijfsprofiel: Bedrijfsprofiel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeVoortgang>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postBedrijfsprofiel(klantvraagId, bedrijfsprofiel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Store the business contact information
         * @param {string} klantvraagId ID of klantvraag
         * @param {IntakeContactgegevens} intakeContactgegevens The business contact information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postContactgegevens(klantvraagId: string, intakeContactgegevens: IntakeContactgegevens, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeVoortgang>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postContactgegevens(klantvraagId, intakeContactgegevens, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Posts an antwoord to a vraag for a vraag in a functionaliteiten-vraaggroep
         * @param {string} klantvraagId ID of klantvraag
         * @param {IntakeAntwoord} intakeAntwoord An array of antwoorden for a specific vraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFunctionaliteitAntwoord(klantvraagId: string, intakeAntwoord: IntakeAntwoord, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeVoortgang>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFunctionaliteitAntwoord(klantvraagId, intakeAntwoord, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Post functionaliteitenwensen for a functionaliteitengroep
         * @param {string} klantvraagId ID of klantvraag
         * @param {Array<Functionaliteitwens>} functionaliteitwens An array of functionaliteitwensen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postFunctionaliteitwensen(klantvraagId: string, functionaliteitwens: Array<Functionaliteitwens>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeVoortgang>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postFunctionaliteitwensen(klantvraagId, functionaliteitwens, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Posts the maximum implementation and monthly service costs for filtering matches. Optionally can also update period till implementation and/or decision for a project
         * @param {string} klantvraagId ID of klantvraag
         * @param {Project} project The selected project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProject(klantvraagId: string, project: Project, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeVoortgang>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProject(klantvraagId, project, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Submit the chosen softwareType for this intake
         * @param {string} klantvraagId ID of klantvraag
         * @param {PostSoftwareTypeRequest} postSoftwareTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSoftwareType(klantvraagId: string, postSoftwareTypeRequest: PostSoftwareTypeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeVoortgang>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSoftwareType(klantvraagId, postSoftwareTypeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an antwoord to a vraag in a bedrijfsactiviteiten-vraaggroep
         * @param {string} klantvraagId ID of klantvraag
         * @param {string} vraagId ID of vraag
         * @param {IntakeAntwoord} intakeAntwoord An array of antwoorden for a specific vraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putBedrijfsactiviteitAntwoord(klantvraagId: string, vraagId: string, intakeAntwoord: IntakeAntwoord, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeVoortgang>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putBedrijfsactiviteitAntwoord(klantvraagId, vraagId, intakeAntwoord, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an antwoord to a vraag in a functionaliteiten-vraaggroep
         * @param {string} klantvraagId ID of klantvraag
         * @param {string} vraagId ID of vraag
         * @param {IntakeAntwoord} intakeAntwoord An array of antwoorden for a specific vraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFunctionaliteitAntwoord(klantvraagId: string, vraagId: string, intakeAntwoord: IntakeAntwoord, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeVoortgang>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFunctionaliteitAntwoord(klantvraagId, vraagId, intakeAntwoord, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates functionaliteitwensen
         * @param {string} klantvraagId ID of klantvraag
         * @param {Array<Functionaliteitwens>} functionaliteitwens An array of functionaliteitwensen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFunctionaliteitwensen(klantvraagId: string, functionaliteitwens: Array<Functionaliteitwens>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeVoortgang>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFunctionaliteitwensen(klantvraagId, functionaliteitwens, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Start a new intake
         * @param {StartIntakeRequest} startIntakeRequest Params for starting the intake e.g. the softwareType chosen on the sm.io website
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startIntake(startIntakeRequest: StartIntakeRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntakeVoortgang>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startIntake(startIntakeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntakeApi - factory interface
 * @export
 */
export const IntakeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntakeApiFp(configuration)
    return {
        /**
         * Get the full bedrijfsactiviteiten hierarchy, for a given intake version based on the klantvraag
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBedrijfsactiviteitenHierarchy(klantvraagId: string, options?: any): AxiosPromise<Array<Bedrijfsactiviteit>> {
            return localVarFp.getBedrijfsactiviteitenHierarchy(klantvraagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets bedrijfsprofiel of klantvraag_id
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBedrijfsprofiel(klantvraagId: string, options?: any): AxiosPromise<Bedrijfsprofiel> {
            return localVarFp.getBedrijfsprofiel(klantvraagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all chosen bedrijfsactiviteiten by klantvraagId
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChosenBedrijfsactiviteiten(klantvraagId: string, options?: any): AxiosPromise<Array<Bedrijfsactiviteit>> {
            return localVarFp.getChosenBedrijfsactiviteiten(klantvraagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets functionaliteiten for a functionaliteiten vraaggroep with pre-filled answers based on the klantvraag
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFunctionaliteitwensen(klantvraagId: string, options?: any): AxiosPromise<Array<KlantvraagFunctionaliteitGroep>> {
            return localVarFp.getFunctionaliteitwensen(klantvraagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets contactgegevens of klantvraag_id
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntakeContactgegevens(klantvraagId: string, options?: any): AxiosPromise<IntakeContactgegevens> {
            return localVarFp.getIntakeContactgegevens(klantvraagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the intake voortgang by klantvraag id
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntakeVoortgang(klantvraagId: string, options?: any): AxiosPromise<IntakeVoortgang> {
            return localVarFp.getIntakeVoortgang(klantvraagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets the project values that are currently known
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(klantvraagId: string, options?: any): AxiosPromise<Project> {
            return localVarFp.getProject(klantvraagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the softwareType, for a given intake version based on the klantvraag
         * @param {string} klantvraagId ID of klantvraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoftwareType(klantvraagId: string, options?: any): AxiosPromise<ChosenSoftwareType> {
            return localVarFp.getSoftwareType(klantvraagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the full list of software types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoftwareTypes(options?: any): AxiosPromise<Array<SoftwareType>> {
            return localVarFp.getSoftwareTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * Get a vraag by id
         * @param {string} klantvraagId ID of klantvraag
         * @param {string} vraagId ID of the vraag to get
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVraag(klantvraagId: string, vraagId: string, options?: any): AxiosPromise<IntakeVraagAntwoord> {
            return localVarFp.getVraag(klantvraagId, vraagId, options).then((request) => request(axios, basePath));
        },
        /**
         * Posts an antwoord to a vraag in a bedrijfsactiviteiten-vraaggroep
         * @param {string} klantvraagId ID of klantvraag
         * @param {IntakeAntwoord} intakeAntwoord An array of antwoorden for a specific vraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBedrijfsactiviteitAntwoord(klantvraagId: string, intakeAntwoord: IntakeAntwoord, options?: any): AxiosPromise<IntakeVoortgang> {
            return localVarFp.postBedrijfsactiviteitAntwoord(klantvraagId, intakeAntwoord, options).then((request) => request(axios, basePath));
        },
        /**
         * Submit the chosen bedrijfsactiviteiten for this intake
         * @param {string} klantvraagId ID of klantvraag
         * @param {PostBedrijfsactiviteitenRequest} postBedrijfsactiviteitenRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBedrijfsactiviteiten(klantvraagId: string, postBedrijfsactiviteitenRequest: PostBedrijfsactiviteitenRequest, options?: any): AxiosPromise<IntakeVoortgang> {
            return localVarFp.postBedrijfsactiviteiten(klantvraagId, postBedrijfsactiviteitenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Store the company information
         * @param {string} klantvraagId ID of klantvraag
         * @param {Bedrijfsprofiel} bedrijfsprofiel The company profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postBedrijfsprofiel(klantvraagId: string, bedrijfsprofiel: Bedrijfsprofiel, options?: any): AxiosPromise<IntakeVoortgang> {
            return localVarFp.postBedrijfsprofiel(klantvraagId, bedrijfsprofiel, options).then((request) => request(axios, basePath));
        },
        /**
         * Store the business contact information
         * @param {string} klantvraagId ID of klantvraag
         * @param {IntakeContactgegevens} intakeContactgegevens The business contact information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postContactgegevens(klantvraagId: string, intakeContactgegevens: IntakeContactgegevens, options?: any): AxiosPromise<IntakeVoortgang> {
            return localVarFp.postContactgegevens(klantvraagId, intakeContactgegevens, options).then((request) => request(axios, basePath));
        },
        /**
         * Posts an antwoord to a vraag for a vraag in a functionaliteiten-vraaggroep
         * @param {string} klantvraagId ID of klantvraag
         * @param {IntakeAntwoord} intakeAntwoord An array of antwoorden for a specific vraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFunctionaliteitAntwoord(klantvraagId: string, intakeAntwoord: IntakeAntwoord, options?: any): AxiosPromise<IntakeVoortgang> {
            return localVarFp.postFunctionaliteitAntwoord(klantvraagId, intakeAntwoord, options).then((request) => request(axios, basePath));
        },
        /**
         * Post functionaliteitenwensen for a functionaliteitengroep
         * @param {string} klantvraagId ID of klantvraag
         * @param {Array<Functionaliteitwens>} functionaliteitwens An array of functionaliteitwensen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postFunctionaliteitwensen(klantvraagId: string, functionaliteitwens: Array<Functionaliteitwens>, options?: any): AxiosPromise<IntakeVoortgang> {
            return localVarFp.postFunctionaliteitwensen(klantvraagId, functionaliteitwens, options).then((request) => request(axios, basePath));
        },
        /**
         * Posts the maximum implementation and monthly service costs for filtering matches. Optionally can also update period till implementation and/or decision for a project
         * @param {string} klantvraagId ID of klantvraag
         * @param {Project} project The selected project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProject(klantvraagId: string, project: Project, options?: any): AxiosPromise<IntakeVoortgang> {
            return localVarFp.postProject(klantvraagId, project, options).then((request) => request(axios, basePath));
        },
        /**
         * Submit the chosen softwareType for this intake
         * @param {string} klantvraagId ID of klantvraag
         * @param {PostSoftwareTypeRequest} postSoftwareTypeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSoftwareType(klantvraagId: string, postSoftwareTypeRequest: PostSoftwareTypeRequest, options?: any): AxiosPromise<IntakeVoortgang> {
            return localVarFp.postSoftwareType(klantvraagId, postSoftwareTypeRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an antwoord to a vraag in a bedrijfsactiviteiten-vraaggroep
         * @param {string} klantvraagId ID of klantvraag
         * @param {string} vraagId ID of vraag
         * @param {IntakeAntwoord} intakeAntwoord An array of antwoorden for a specific vraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBedrijfsactiviteitAntwoord(klantvraagId: string, vraagId: string, intakeAntwoord: IntakeAntwoord, options?: any): AxiosPromise<IntakeVoortgang> {
            return localVarFp.putBedrijfsactiviteitAntwoord(klantvraagId, vraagId, intakeAntwoord, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an antwoord to a vraag in a functionaliteiten-vraaggroep
         * @param {string} klantvraagId ID of klantvraag
         * @param {string} vraagId ID of vraag
         * @param {IntakeAntwoord} intakeAntwoord An array of antwoorden for a specific vraag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFunctionaliteitAntwoord(klantvraagId: string, vraagId: string, intakeAntwoord: IntakeAntwoord, options?: any): AxiosPromise<IntakeVoortgang> {
            return localVarFp.putFunctionaliteitAntwoord(klantvraagId, vraagId, intakeAntwoord, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates functionaliteitwensen
         * @param {string} klantvraagId ID of klantvraag
         * @param {Array<Functionaliteitwens>} functionaliteitwens An array of functionaliteitwensen
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFunctionaliteitwensen(klantvraagId: string, functionaliteitwens: Array<Functionaliteitwens>, options?: any): AxiosPromise<IntakeVoortgang> {
            return localVarFp.putFunctionaliteitwensen(klantvraagId, functionaliteitwens, options).then((request) => request(axios, basePath));
        },
        /**
         * Start a new intake
         * @param {StartIntakeRequest} startIntakeRequest Params for starting the intake e.g. the softwareType chosen on the sm.io website
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startIntake(startIntakeRequest: StartIntakeRequest, options?: any): AxiosPromise<IntakeVoortgang> {
            return localVarFp.startIntake(startIntakeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntakeApi - object-oriented interface
 * @export
 * @class IntakeApi
 * @extends {BaseAPI}
 */
export class IntakeApi extends BaseAPI {
    /**
     * Get the full bedrijfsactiviteiten hierarchy, for a given intake version based on the klantvraag
     * @param {string} klantvraagId ID of klantvraag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public getBedrijfsactiviteitenHierarchy(klantvraagId: string, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).getBedrijfsactiviteitenHierarchy(klantvraagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets bedrijfsprofiel of klantvraag_id
     * @param {string} klantvraagId ID of klantvraag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public getBedrijfsprofiel(klantvraagId: string, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).getBedrijfsprofiel(klantvraagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all chosen bedrijfsactiviteiten by klantvraagId
     * @param {string} klantvraagId ID of klantvraag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public getChosenBedrijfsactiviteiten(klantvraagId: string, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).getChosenBedrijfsactiviteiten(klantvraagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets functionaliteiten for a functionaliteiten vraaggroep with pre-filled answers based on the klantvraag
     * @param {string} klantvraagId ID of klantvraag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public getFunctionaliteitwensen(klantvraagId: string, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).getFunctionaliteitwensen(klantvraagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets contactgegevens of klantvraag_id
     * @param {string} klantvraagId ID of klantvraag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public getIntakeContactgegevens(klantvraagId: string, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).getIntakeContactgegevens(klantvraagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the intake voortgang by klantvraag id
     * @param {string} klantvraagId ID of klantvraag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public getIntakeVoortgang(klantvraagId: string, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).getIntakeVoortgang(klantvraagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets the project values that are currently known
     * @param {string} klantvraagId ID of klantvraag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public getProject(klantvraagId: string, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).getProject(klantvraagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the softwareType, for a given intake version based on the klantvraag
     * @param {string} klantvraagId ID of klantvraag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public getSoftwareType(klantvraagId: string, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).getSoftwareType(klantvraagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the full list of software types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public getSoftwareTypes(options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).getSoftwareTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a vraag by id
     * @param {string} klantvraagId ID of klantvraag
     * @param {string} vraagId ID of the vraag to get
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public getVraag(klantvraagId: string, vraagId: string, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).getVraag(klantvraagId, vraagId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Posts an antwoord to a vraag in a bedrijfsactiviteiten-vraaggroep
     * @param {string} klantvraagId ID of klantvraag
     * @param {IntakeAntwoord} intakeAntwoord An array of antwoorden for a specific vraag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public postBedrijfsactiviteitAntwoord(klantvraagId: string, intakeAntwoord: IntakeAntwoord, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).postBedrijfsactiviteitAntwoord(klantvraagId, intakeAntwoord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submit the chosen bedrijfsactiviteiten for this intake
     * @param {string} klantvraagId ID of klantvraag
     * @param {PostBedrijfsactiviteitenRequest} postBedrijfsactiviteitenRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public postBedrijfsactiviteiten(klantvraagId: string, postBedrijfsactiviteitenRequest: PostBedrijfsactiviteitenRequest, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).postBedrijfsactiviteiten(klantvraagId, postBedrijfsactiviteitenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Store the company information
     * @param {string} klantvraagId ID of klantvraag
     * @param {Bedrijfsprofiel} bedrijfsprofiel The company profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public postBedrijfsprofiel(klantvraagId: string, bedrijfsprofiel: Bedrijfsprofiel, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).postBedrijfsprofiel(klantvraagId, bedrijfsprofiel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Store the business contact information
     * @param {string} klantvraagId ID of klantvraag
     * @param {IntakeContactgegevens} intakeContactgegevens The business contact information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public postContactgegevens(klantvraagId: string, intakeContactgegevens: IntakeContactgegevens, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).postContactgegevens(klantvraagId, intakeContactgegevens, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Posts an antwoord to a vraag for a vraag in a functionaliteiten-vraaggroep
     * @param {string} klantvraagId ID of klantvraag
     * @param {IntakeAntwoord} intakeAntwoord An array of antwoorden for a specific vraag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public postFunctionaliteitAntwoord(klantvraagId: string, intakeAntwoord: IntakeAntwoord, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).postFunctionaliteitAntwoord(klantvraagId, intakeAntwoord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Post functionaliteitenwensen for a functionaliteitengroep
     * @param {string} klantvraagId ID of klantvraag
     * @param {Array<Functionaliteitwens>} functionaliteitwens An array of functionaliteitwensen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public postFunctionaliteitwensen(klantvraagId: string, functionaliteitwens: Array<Functionaliteitwens>, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).postFunctionaliteitwensen(klantvraagId, functionaliteitwens, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Posts the maximum implementation and monthly service costs for filtering matches. Optionally can also update period till implementation and/or decision for a project
     * @param {string} klantvraagId ID of klantvraag
     * @param {Project} project The selected project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public postProject(klantvraagId: string, project: Project, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).postProject(klantvraagId, project, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Submit the chosen softwareType for this intake
     * @param {string} klantvraagId ID of klantvraag
     * @param {PostSoftwareTypeRequest} postSoftwareTypeRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public postSoftwareType(klantvraagId: string, postSoftwareTypeRequest: PostSoftwareTypeRequest, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).postSoftwareType(klantvraagId, postSoftwareTypeRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an antwoord to a vraag in a bedrijfsactiviteiten-vraaggroep
     * @param {string} klantvraagId ID of klantvraag
     * @param {string} vraagId ID of vraag
     * @param {IntakeAntwoord} intakeAntwoord An array of antwoorden for a specific vraag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public putBedrijfsactiviteitAntwoord(klantvraagId: string, vraagId: string, intakeAntwoord: IntakeAntwoord, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).putBedrijfsactiviteitAntwoord(klantvraagId, vraagId, intakeAntwoord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an antwoord to a vraag in a functionaliteiten-vraaggroep
     * @param {string} klantvraagId ID of klantvraag
     * @param {string} vraagId ID of vraag
     * @param {IntakeAntwoord} intakeAntwoord An array of antwoorden for a specific vraag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public putFunctionaliteitAntwoord(klantvraagId: string, vraagId: string, intakeAntwoord: IntakeAntwoord, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).putFunctionaliteitAntwoord(klantvraagId, vraagId, intakeAntwoord, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates functionaliteitwensen
     * @param {string} klantvraagId ID of klantvraag
     * @param {Array<Functionaliteitwens>} functionaliteitwens An array of functionaliteitwensen
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public putFunctionaliteitwensen(klantvraagId: string, functionaliteitwens: Array<Functionaliteitwens>, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).putFunctionaliteitwensen(klantvraagId, functionaliteitwens, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Start a new intake
     * @param {StartIntakeRequest} startIntakeRequest Params for starting the intake e.g. the softwareType chosen on the sm.io website
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntakeApi
     */
    public startIntake(startIntakeRequest: StartIntakeRequest, options?: AxiosRequestConfig) {
        return IntakeApiFp(this.configuration).startIntake(startIntakeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
