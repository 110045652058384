/* tslint:disable */
/* eslint-disable */
/**
 * Software Matching Api
 * Software Matching API for the match-making questionnaire service
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Enumeration of time slots
 * @export
 * @enum {string}
 */

export const TimeSlot = {
    _0900_1200: '0900-1200',
    _1200_1500: '1200-1500',
    _1500_1700: '1500-1700'
} as const;

export type TimeSlot = typeof TimeSlot[keyof typeof TimeSlot];



