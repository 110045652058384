import { Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { AntwoordOptie, IntakeAntwoord, IntakeVraagAntwoord } from '../../../generated/softwarematching-api';
import { showErrorResponse } from '../../../helpers/notify';
import { makeCancellable } from '../../../helpers/promise';
import useIntakeService from '../hooks/useIntakeService';
import FormFieldCheckboxGroup from '../../../common/form/FormFieldCheckboxGroup';
import FormFieldRadioGroup from '../../../common/form/FormFieldRadioGroup';
import Loader from '../../../common/Loader';
import { arraysAreEqual } from '../../../helpers/array';
import { hasAntwoorden } from '../helpers/intake';

type VraagValues = {
    antwoorden: string[];
};

const defaultValues: VraagValues = {
    antwoorden: [],
};

type Props = {
    klantvraagId: string;
    vraagId: string;
    onSave: (intakeAntwoord: IntakeAntwoord) => Promise<void>;
    onEdit: (intakeAntwoord: IntakeAntwoord) => Promise<void>;
    skipQuestion: () => void;
};

export default function FormAnswerVraag({ klantvraagId, vraagId, onSave, onEdit, skipQuestion }: Props) {
    const { t } = useTranslation();
    const intakeService = useIntakeService();

    const [loading, setLoading] = useState(true);
    const [intakeVraag, setIntakeVraagAntwoord] = useState<IntakeVraagAntwoord | null>(null);

    const handleSubmit = useCallback(
        (values: VraagValues, { resetForm }: FormikHelpers<VraagValues>) => {
            if (hasAntwoorden(intakeVraag) && arraysAreEqual(values.antwoorden, intakeVraag?.antwoorden)) {
                skipQuestion();
            } else {
                const intakeAntwoord: IntakeAntwoord = { antwoorden: values.antwoorden };
                const cbPromise = hasAntwoorden(intakeVraag) ? onEdit(intakeAntwoord) : onSave(intakeAntwoord);
                cbPromise
                    .then(() => resetForm())
                    .catch(error => showErrorResponse(t('common:failedToSubmit'), error, t))
                    .finally(() => setLoading(false));
            }
        },
        [t, onSave, onEdit, skipQuestion, intakeVraag]
    );

    useEffect(() => {
        setLoading(true);
        const [promise, cancel] = makeCancellable(intakeService.getIntakeVraagAntwoord(klantvraagId, vraagId));

        promise
            .then(setIntakeVraagAntwoord)
            .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t))
            .finally(() => setLoading(false));
        return cancel;
    }, [vraagId, intakeService, t, klantvraagId]);

    const validationSchema = yup.object().shape({
        antwoorden: yup.array().min(1, t('intake.youHaveToAnswer')).of(yup.string().required()).required(),
    });

    return (
        <Formik
            initialValues={intakeVraag?.antwoorden ? intakeVraag : defaultValues}
            enableReinitialize
            validationSchema={validationSchema}
            initialTouched={{
                antwoorden: true,
            }}
            onSubmit={handleSubmit}>
            <Form className='flex gap-4 flex-col' id='intake-form'>
                {!loading && intakeVraag ? (
                    <>
                        <h2 className='text-2xl font-light'>{intakeVraag.vraag.tekst}</h2>
                        <FormAnswerVraagField
                            antwoordOpties={intakeVraag.vraag.antwoordOpties}
                            multiselect={intakeVraag.vraag.multiselect}
                        />
                    </>
                ) : (
                    <Loader />
                )}
            </Form>
        </Formik>
    );
}

type FormAnswerVraagFieldProps = {
    antwoordOpties: AntwoordOptie[];
    multiselect: boolean;
};

function FormAnswerVraagField({ antwoordOpties, multiselect }: FormAnswerVraagFieldProps) {
    return multiselect ? (
        <FormFieldCheckboxGroup name='antwoorden' antwoordOpties={antwoordOpties} />
    ) : (
        <FormFieldRadioGroup name='antwoorden' antwoordOpties={antwoordOpties} />
    );
}
