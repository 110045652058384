import { Bedrijfsactiviteit } from '../../../generated/softwarematching-api';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import FormFieldSelect from '../../../common/form/FormFieldSelect';
import FormFieldCheckbox from '../../../common/form/FormFieldCheckbox';
import { ChooseBedrijfactiviteitenValues } from './BedrijfsactiviteitenSelectieFormModal';
import { useBedrijfsactiviteitenSelection } from '../../../common/bedrijfsactiviteiten/useBedrijfsactiviteitenSelection';

type Props = {
    bedrijfsactiviteiten: Bedrijfsactiviteit[];
    selectedBedrijfsactiviteitenIds: string[];
};

export default function BedrijfsactiviteitenSelectie({ bedrijfsactiviteiten, selectedBedrijfsactiviteitenIds }: Props) {
    const { t } = useTranslation();
    const { values, setFieldValue } = useFormikContext<ChooseBedrijfactiviteitenValues>();
    const selectedSectorId = values.sector;
    const selectedBrancheId = values.branche;
    const selectedAllSubbranches = values.allSubbranches;
    const { sectorOptions, brancheOptions, subbrancheOptions } = useBedrijfsactiviteitenSelection(
        bedrijfsactiviteiten,
        selectedSectorId,
        selectedBrancheId,
        selectedBedrijfsactiviteitenIds
    );

    useEffect(() => {
        setFieldValue('subbranches', []);
    }, [selectedAllSubbranches, setFieldValue]);

    return (
        <>
            <FormFieldSelect
                name='sector'
                label={t('bedrijfsactiviteiten.sector')}
                options={sectorOptions}
                required
                clearable
                menuPosition='fixed'
            />
            <FormFieldSelect
                name='branche'
                label={t('bedrijfsactiviteiten.branche')}
                options={brancheOptions}
                required
                clearable
                menuPosition='fixed'
            />
            <FormFieldCheckbox name='allSubbranches'>{t('bedrijfsactiviteiten.allSubbranches')}</FormFieldCheckbox>
            <FormFieldSelect
                name='subbranches'
                closeMenuOnSelect={false}
                label={t('bedrijfsactiviteiten.subbranche')}
                options={subbrancheOptions}
                multiple
                required
                clearable
                menuPosition='fixed'
                disabled={values.allSubbranches}
            />
        </>
    );
}
