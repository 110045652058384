import React from 'react';
import Role from '../models/Role';
import UnauthorizedPage from './page/UnauthorizedPage';
import { useAuthRoles, useAuthWrapper } from '../hooks/auth-hooks';
import { Outlet } from 'react-router-dom';

type Props = {
    /**
     * list of Keycloak roles allowed to access this route
     */
    roles: Role[];
};

/**
 * A route only accessible when the user is authenticated through Keycloak.
 */
export default function ProtectedRoute({ roles }: Props) {
    const { isAuthenticated } = useAuthWrapper();
    const { hasRole } = useAuthRoles();
    const hasRequiredRole = roles.some(hasRole);

    if (isAuthenticated && hasRequiredRole) {
        return <Outlet />;
    } else {
        return <UnauthorizedPage />;
    }
}
