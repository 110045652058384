import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IntakeAntwoord, IntakeVoortgang, IntakeVoortgangItem } from '../../../../generated/softwarematching-api';
import useIntakeService from '../../hooks/useIntakeService';
import FormAnswerVraag from '../FormAnswerVraag';
import IntakePhase from '../IntakePhase';
import { findBedrijfsactiviteitVraaggroepById } from '../../helpers/intake';
import { BrowseDirection } from '../../../../common/Constants';

type Props = {
    intakeVoortgang: IntakeVoortgang;
    currentIntakeVoortgangItem: IntakeVoortgangItem;
    onUpdateIntakeVoortgang: (intakeVoortgang: IntakeVoortgang) => void;
    browse: (direction: BrowseDirection) => void;
};

export default function IntakeBedrijfsactiviteitenVraaggroepen({
    intakeVoortgang,
    currentIntakeVoortgangItem,
    onUpdateIntakeVoortgang,
    browse,
}: Props) {
    const klantvraagId = intakeVoortgang.klantvraagId!!;
    const currentVraaggroep = findBedrijfsactiviteitVraaggroepById(
        intakeVoortgang,
        currentIntakeVoortgangItem.vraagGroepId!!
    );
    const currentVraagId = currentIntakeVoortgangItem.vraagId;

    const { t } = useTranslation();
    const intakeService = useIntakeService();

    const handleEdit = useCallback(
        (intakeAntwoord: IntakeAntwoord) => {
            return intakeService
                .putBedrijfsactiviteitAntwoord(klantvraagId, currentVraagId!, intakeAntwoord)
                .then(onUpdateIntakeVoortgang);
        },
        [intakeService, currentVraagId, klantvraagId, onUpdateIntakeVoortgang]
    );

    const handleSubmit = useCallback(
        (intakeAntwoord: IntakeAntwoord) => {
            return intakeService
                .postBedrijfsactiviteitAntwoord(klantvraagId, intakeAntwoord)
                .then(onUpdateIntakeVoortgang);
        },
        [intakeService, klantvraagId, onUpdateIntakeVoortgang]
    );

    return (
        <IntakePhase
            title={currentVraaggroep?.naam ?? t('intake.intakePhase.BEDRIJFSACTIVITEIT_VRAAGGROEPEN')}
            intakeVoortgang={intakeVoortgang}
            currentIntakeVoortgangItem={currentIntakeVoortgangItem}
            showPreviousButton
            previousCallback={() => browse('previous')}
            showNextButton>
            <FormAnswerVraag
                klantvraagId={klantvraagId}
                vraagId={currentVraagId!}
                onEdit={handleEdit}
                onSave={handleSubmit}
                skipQuestion={() => browse('next')}
            />
        </IntakePhase>
    );
}
