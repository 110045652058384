import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import HeaderUserProfile from '../../../common/header/HeaderUserProfile';

export default function PortalHeader() {
    const { t } = useTranslation();

    return (
        <header className='bg-white border-b border-gray-200'>
            <nav className='h-14 lg:h-24 px-4 container mx-auto flex justify-between items-stretch'>
                <div className='justify-start flex items-center'>
                    <Link to='/portal'>
                        <img
                            src='/SoftwareMatchingLogoSmall.svg'
                            className='h-10 lg:h-12'
                            alt={t('logoSoftwareMatchingIo')}
                        />
                    </Link>
                </div>
                <div className='justify-start flex items-center'>
                    <HeaderUserProfile />
                </div>
            </nav>
        </header>
    );
}
