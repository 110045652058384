import React, { useEffect, useState } from 'react';
import useVendorService from '../hooks/useVendorService';
import usePartnerService from '../hooks/usePartnerService';
import { makeCancellable } from '../../../helpers/promise';
import { showErrorResponse } from '../../../helpers/notify';
import { Aanbieding, Product, Vendor } from '../../../generated/softwarematching-api';
import { useTranslation } from 'react-i18next';
import Loader from '../../../common/Loader';
import { useNavigate } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import AddButton from '../../../common/button/AddButton';

type Props = {
    partnerId: string;
};

export default function ProductAanbodList({ partnerId }: Props) {
    const { t } = useTranslation();
    const vendorService = useVendorService();
    const partnerService = usePartnerService();
    const navigate = useNavigate();

    const [vendors, setVendors] = useState<Vendor[]>([]);
    const [producten, setProducten] = useState<Product[]>([]);
    const [aanbiedingen, setAanbiedingen] = useState<Aanbieding[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const [promise, cancel] = makeCancellable(
            Promise.all([
                vendorService.getVendors(),
                vendorService.getProductenFromAllVendors(),
                partnerService.getAanbiedingen(partnerId),
            ])
        );
        promise
            .then(([vendors, producten, aanbiedingen]) => {
                setVendors(vendors);
                setProducten(producten);
                setAanbiedingen(aanbiedingen);
            })
            .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t))
            .finally(() => setLoading(false));
        return cancel;
    }, [partnerService, vendorService, partnerId, t]);

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <table className='admin-table' data-testid='aanbod-table'>
                <thead>
                    <tr className='admin-table--header-row'>
                        <th className='admin-table--header-cell pl-4'>Vendor - Product</th>
                        <th className='admin-table--header-cell'></th>
                        <th className='admin-table--header-cell'></th>
                    </tr>
                </thead>
                <tbody>
                    {aanbiedingen.map((aanbieding, index) => {
                        const product = producten.find(p => p.id === aanbieding.productId)!;
                        const vendor = vendors.find(v => v.id === product.vendorId)!;

                        return (
                            <tr
                                key={aanbieding.id}
                                className='admin-table--body-row hover:cursor-pointer'
                                onClick={() => navigate(`/admin/partners/${partnerId}/aanbod/${aanbieding.id}`)}>
                                <td className='admin-table--body-cell pl-4'>
                                    <div data-testid='aanbod-vendor-product' className='font-medium'>
                                        {vendor.naam} - {product.naam}
                                    </div>
                                    <div data-testid='aanbod-naam' className='text-gray-500'>
                                        {aanbieding.naam}
                                    </div>
                                </td>
                                <td className='admin-table--body-cell w-10'>
                                    <FaEdit
                                        data-testid={`edit-${product.naam}${aanbieding.naam}`}
                                        className='admin-icon hover:text-black'
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <AddButton
                onClick={() => navigate(`/admin/partners/${partnerId}/aanbod/new`)}
                dataTestId='create-aanbod-btn'
            />
        </>
    );
}
