import Button from '../../../common/button/Button';
import { Standaardaddon, StandaardaddonDefinitie } from '../../../generated/softwarematching-api';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';
import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

type Props = {
    name: string;
    standaardaddonDefinities: StandaardaddonDefinitie[];
    onAdd: () => void;
    onEdit: (standaardaddon: Standaardaddon, index: number) => void;
};

export default function StandaardaddonListField({ name, standaardaddonDefinities, onAdd, onEdit }: Props) {
    const { t } = useTranslation();
    const [field] = useField<Standaardaddon[]>(name);

    function findStandaardaddonDefinitie(standaardaddonDefinitieId: string) {
        return standaardaddonDefinities.find(
            standaardaddonDefinitie => standaardaddonDefinitie.id === standaardaddonDefinitieId
        );
    }

    function renderStandaardaddon(item: Standaardaddon, index: number, arrayHelpers: FieldArrayRenderProps) {
        const standaardAddonName = findStandaardaddonDefinitie(item.standaardaddonDefinitieId)!.naam;
        return (
            <tr key={index} className='admin-table--body-row hover:cursor-pointer' onClick={() => onEdit(item, index)}>
                <td className='admin-table--body-cell pl-4'>{standaardAddonName}</td>
                <td className='admin-table--body-cell w-10' data-testid={`edit-standaardaddon-${standaardAddonName}`}>
                    <FaEdit className='admin-icon hover:text-black' />
                </td>
                <td
                    className='admin-table--body-cell w-10'
                    data-testid={`delete-standaardaddon-${standaardAddonName}`}
                    onClick={event => {
                        event.stopPropagation();
                        arrayHelpers.remove(index);
                    }}>
                    <FaTrash className='admin-icon fill-warning hover:fill-red-800' />
                </td>
            </tr>
        );
    }

    return (
        <FieldArray
            name={name}
            render={(arrayHelpers: FieldArrayRenderProps) => (
                <div>
                    {field.value.length ? (
                        <table className='admin-table' data-testid='standaardaddon-table'>
                            <thead>
                                <tr className='admin-table--header-row'>
                                    <th className='admin-table--header-cell pl-4'>Naam</th>
                                    <th className='admin-table--header-cell'></th>
                                    <th className='admin-table--header-cell'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {field.value.map((item, index) => renderStandaardaddon(item, index, arrayHelpers))}
                            </tbody>
                        </table>
                    ) : (
                        <span className='block py-4 text-lg italic'>{t('partner:standaardaddon.noAddons')}</span>
                    )}
                    <Button variant='outlined' dataTestId='add-standaardaddon-btn' onClick={() => onAdd()}>
                        {t('partner:standaardaddon.add')}
                    </Button>
                </div>
            )}
        />
    );
}
