import * as React from 'react';
import { useTranslation } from 'react-i18next';
import BottomBar from '../../../common/bottom-bar/BottomBar';
import { FaArrowRightLong } from 'react-icons/fa6';
import { UseMatchSelection } from '../hooks/useMatchSelection';
import { IntakeMatch } from '../../../generated/softwarematching-api';

type Props = {
    matches: IntakeMatch[];
};

export default function MatchSelectionBottomBar({ matches }: Props) {
    const { t } = useTranslation();
    const { isEnabled, handleSubmit, isEnabledMin, isEnabledMax, values } = UseMatchSelection(matches);

    const background = isEnabled ? 'bg-blue-500' : 'bg-gray-400';
    const cursor = isEnabled ? 'cursor-pointer' : 'cursor-not-allowed';

    return (
        <>
            <BottomBar bgColor={background}>
                <div
                    data-testid='match-bottom-bar'
                    className={`lg:container lg:mx-auto lg:px-4 lg:gap-10 lg:mb-12 flex items-center justify-between hover:underline decoration-white underline-offset-2 ${cursor}`}
                    onClick={handleSubmit}
                    onKeyDown={handleSubmit}>
                    <div>
                        <span className='portal-body--small text-white'>
                            {t('matchSelection.selectMatchStepperCount', { count: values.selected.length })}
                        </span>
                        <h4 className='portal-heading-5 text-white'>
                            {isEnabled && t('matchSelection.selectMatchStepperTitle')}
                            {!isEnabledMin && t('matchSelection.selectMatchStepperTitleAltMin')}
                            {!isEnabledMax && t('matchSelection.selectMatchStepperTitleAltMax')}
                        </h4>
                    </div>
                    <FaArrowRightLong className='text-white'></FaArrowRightLong>
                </div>
            </BottomBar>
        </>
    );
}
