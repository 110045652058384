import React from 'react';
import { useTranslation } from 'react-i18next';
import { IntakeWithMatchState } from '../../../generated/softwarematching-api';
import InProgressMatchListViewItem from './InProgressMatchListViewItem';
import { FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

interface Props {
    inProgressIntakes: IntakeWithMatchState[];
}

export default function InProgressMatchListView({ inProgressIntakes }: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    var hasMoreMatches = false;
    var isEndOfIntake = false;

    const handleClick = (intakeId: String) => {
        navigate(`/portal/intakes/${intakeId}/matches`);
    };

    const renderSelectedMatchItems = (matchState: IntakeWithMatchState) => {
        if (!(matchState.match.status === 'MATCHED')) {
            return <InProgressMatchListViewItem key={matchState.match.matchResultId} matchState={matchState} />;
        } else {
            hasMoreMatches = true;
        }
    };

    const calculateIsEndOfIntake = (index: number) => {
        return (
            index === inProgressIntakes.length - 1 ||
            (index < inProgressIntakes.length - 1 &&
                inProgressIntakes[index].intake.id !== inProgressIntakes[index + 1].intake.id)
        );
    };

    const renderMorePartnersButton = (index: number) => {
        return (
            <div
                className='w-full bg-white px-5 py-2 flex justify-between items-center border-l-2 border-gray-300 hover:border-black hover:bg-gray-50 cursor-pointer'
                onClick={() => handleClick(inProgressIntakes[index].intake.id)}
                data-testid='more-partners-button'>
                <div className='text-gray-500 hover:underline'>{t('portal.morePartners')}</div>
                <FaPlus />
            </div>
        );
    };

    const renderIntakeEnd = (index: number) => {
        isEndOfIntake = calculateIsEndOfIntake(index);
        if (isEndOfIntake) {
            return <div className='mb-5 '>{hasMoreMatches && renderMorePartnersButton(index)}</div>;
        }
    };

    return (
        <>
            {inProgressIntakes.length > 0 && (
                <div className='space-y-5 mb-11'>
                    <h2 className='portal-heading-2'> {t('portal.inProgressMatches')}</h2>
                    <ul data-testid='in-progress-matches-list'>
                        {inProgressIntakes.map((matchState, index) => (
                            <>
                                {renderSelectedMatchItems(matchState)}
                                {renderIntakeEnd(index)}
                                {isEndOfIntake && (hasMoreMatches = false)}
                            </>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
}
