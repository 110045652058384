import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import NotFoundPage from '../common/page/NotFoundPage';
import IntakeRouting from './intake/IntakeRouting';
import AdminRouting from './admin/AdminRouting';
import PortalRouting from './portal/PortalRouting';

export default function Routing() {
    return (
        <Routes>
            <Route path='*' element={<NotFoundPage />} />
            <Route path='/' element={<Navigate to='/portal' />} />
            <Route path='/intake/*' element={<IntakeRouting />} />
            <Route path='/admin/*' element={<AdminRouting />} />
            <Route path='/portal/*' element={<PortalRouting />} />
        </Routes>
    );
}
