import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NewMatchListView } from '../components/NewMatchListView';
import { NewIntake } from '../components/NewIntake';
import { useAuthWrapper } from '../../../hooks/auth-hooks';
import InProgressMatchListView from '../components/InProgressMatchListView';
import { Intake, IntakeWithMatchState, SoftwareType } from '../../../generated/softwarematching-api';
import { makeCancellable } from '../../../helpers/promise';
import useProtectedService from '../../intake/hooks/useProtectedService';
import useIntakeService from '../../intake/hooks/useIntakeService';
import { showErrorResponse } from '../../../helpers/notify';
import Loader from '../../../common/Loader';
import { useNavigate } from 'react-router-dom';

export default function IntakeList() {
    const { t } = useTranslation();
    const auth = useAuthWrapper();
    const navigate = useNavigate();
    const protectedService = useProtectedService();
    const intakeService = useIntakeService();
    const [intakes, setIntakes] = useState<Intake[]>([]);
    const [inProgressIntakes, setInProgressIntakes] = useState<IntakeWithMatchState[]>([]);
    const [softwareTypes, setSoftwareTypes] = useState<SoftwareType[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);

        const [progressPromise, progressCancel] = makeCancellable(protectedService.getInProgressIntakes());
        const [intakePromise, intakeCancel] = makeCancellable(protectedService.getMatchedIntakes());
        const [softwareTypesPromise, softwareTypesCancel] = makeCancellable(intakeService.getSoftwareTypes());

        Promise.all([progressPromise, intakePromise, softwareTypesPromise])
            .then(([inProgressIntakes, intakes, softwareTypes]) => {
                if (intakes.length === 1 && inProgressIntakes.length === 0) {
                    navigate(`/portal/intakes/${intakes[0].id}/matches`);
                }
                setInProgressIntakes(inProgressIntakes);
                setIntakes(intakes);
                setSoftwareTypes(softwareTypes);
            })
            .catch(error => showErrorResponse(t('common:failedToRetrieveData'), error, t))
            .finally(() => setLoading(false));

        return () => {
            progressCancel();
            intakeCancel();
            softwareTypesCancel();
        };
    }, [protectedService, intakeService, navigate, t]);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className='container lg:max-w-4xl mx-auto px-4'>
            <h1 className='portal-heading-1 mb-2.5'>{t('portal.welcome', { naam: auth.userName })}</h1>
            <p className='portal-body--large mb-6'>{t('portal.landingText')}</p>

            <NewMatchListView intakes={intakes} softwareTypes={softwareTypes} />
            <InProgressMatchListView inProgressIntakes={inProgressIntakes} />
            <NewIntake />
        </div>
    );
}
