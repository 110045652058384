import { Form, Formik, FormikProps, FormikValues } from 'formik';
import React, { ReactNode } from 'react';
import { AnySchema } from 'yup';
import ResetButton from '../button/ResetButton';
import SubmitButton from '../button/SubmitButton';
import Loader from '../Loader';
import Modal from '../modal/Modal';
import ModalContent from '../modal/ModalContent';
import ModalHeader from '../modal/ModalHeader';
import { Size } from '../Size';
import ModalFooter from '../modal/ModalFooter';
import DeleteButton from '../button/DeleteButton';

type Props<T extends FormikValues> = {
    title: ReactNode;
    size: Size;
    initialValues: T;
    validationSchema: AnySchema;
    children: ReactNode;
    dataTestId?: string;
    loading?: boolean;
    formRef?: React.Ref<FormikProps<any>>;
    resettable: boolean;
    onSubmit: (values: T) => void;
    onDelete?: () => void;
    onCancel: () => void;
    submitLabel: string;
};

FormModal.defaultProps = {
    size: Size.Medium,
    resettable: false,
};

/**
 * Generic component to quickly construct a Formik form with validation inside a modal.
 * Pass the Formik fields directly as children.
 */
export default function FormModal<T extends FormikValues>({
    size,
    title,
    initialValues,
    validationSchema,
    children,
    dataTestId,
    loading,
    formRef,
    resettable,
    onSubmit,
    onDelete,
    onCancel,
    submitLabel,
}: Props<T>) {
    return (
        <Modal dataTestId={dataTestId ?? 'form-modal'} size={size}>
            <ModalHeader onClose={onCancel}>
                <h1 className='text-2xl'>{loading ? <Loader /> : title}</h1>
            </ModalHeader>
            <ModalContent>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize
                    innerRef={formRef}
                    onSubmit={values => onSubmit(values)}>
                    <Form className='flex gap-4 flex-col' noValidate>
                        {children}
                        <ModalFooter>
                            {onDelete && <DeleteButton disabled={loading} onClick={onDelete} />}
                            {resettable && <ResetButton disabled={loading} />}
                            <SubmitButton disabled={loading} label={submitLabel} />
                        </ModalFooter>
                    </Form>
                </Formik>
            </ModalContent>
        </Modal>
    );
}
