import React, { useCallback } from 'react';
import FormFieldText from '../../../common/form/FormFieldText';
import yup from '../../../common/yup/YupExtended';
import { useTranslation } from 'react-i18next';
import usePartnerService from '../hooks/usePartnerService';
import { Size } from '../../../common/Size';
import FormModal from '../../../common/modal/FormModal';
import { showErrorResponse } from '../../../helpers/notify';

type Props = {
    onClose: () => void;
};

interface PartnerEnrollment {
    bedrijfsnaam: string;
    voornaam: string;
    achternaam: string;
    telefoonnummer: string;
    email: string;
}

const initialValues: PartnerEnrollment = {
    bedrijfsnaam: '',
    voornaam: '',
    achternaam: '',
    telefoonnummer: '',
    email: '',
};

export default function PartnerCreationModal({ onClose }: Props) {
    const { t } = useTranslation();
    const partnerService = usePartnerService();

    const validationScheme = yup.object().shape({
        bedrijfsnaam: yup.string().required().max(200),
        voornaam: yup.string().required().max(200),
        achternaam: yup.string().required().max(200),
        email: yup.string().required().validEmail().max(200),
        telefoonnummer: yup.string().required().max(20),
    });

    const handleSubmitEnrollment = useCallback(
        (params: PartnerEnrollment) => {
            return partnerService
                .enrollPartner(params.bedrijfsnaam, {
                    voornaam: params.voornaam,
                    achternaam: params.achternaam,
                    telefoonnummer: params.telefoonnummer,
                    email: params.email,
                })
                .then(onClose)
                .catch(error => showErrorResponse(t('common:failedToSubmit'), error, t));
        },
        [partnerService, t, onClose]
    );

    return (
        <FormModal
            title={t('partner:management.add-implementation-partner')}
            size={Size.Medium}
            initialValues={initialValues}
            validationSchema={validationScheme}
            submitLabel={t('common:add')}
            onSubmit={handleSubmitEnrollment}
            onCancel={onClose}>
            <FormFieldText type='text' name='bedrijfsnaam' label={t('partner:bedrijfsnaam')} required={true} />
            <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                <FormFieldText
                    name='voornaam'
                    label={t('domain:contactgegevens.voornaam')}
                    type='text'
                    required={true}
                />
                <FormFieldText
                    name='achternaam'
                    label={t('domain:contactgegevens.achternaam')}
                    type='text'
                    required={true}
                />
            </div>
            <FormFieldText
                name='email'
                label={t('domain:contactgegevens.email')}
                type='text'
                inputMode='email'
                required={true}
            />
            <FormFieldText
                name='telefoonnummer'
                label={t('domain:contactgegevens.telefoonnummer')}
                type='text'
                inputMode='tel'
                required={true}
            />
        </FormModal>
    );
}
