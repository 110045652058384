/* tslint:disable */
/* eslint-disable */
/**
 * Software Matching Api
 * Software Matching API for the match-making questionnaire service
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: support@example.net
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * The status of the individual match
 * @export
 * @enum {string}
 */

export const MatchStatus = {
    MATCHED: 'MATCHED',
    SELECTED: 'SELECTED',
    PROPOSED: 'PROPOSED',
    PROPOSED_ALTERNATIVE: 'PROPOSED_ALTERNATIVE',
    PLANNED: 'PLANNED',
    REJECTED: 'REJECTED',
    KENNISMAKING_REVIEWED: 'KENNISMAKING_REVIEWED',
    WON: 'WON',
    LOST: 'LOST',
    KEUZE_LOST_REVIEWED: 'KEUZE_LOST_REVIEWED',
    KEUZE_WON_REVIEWED: 'KEUZE_WON_REVIEWED',
    IMPLEMENTATIE_REVIEWED: 'IMPLEMENTATIE_REVIEWED'
} as const;

export type MatchStatus = typeof MatchStatus[keyof typeof MatchStatus];



