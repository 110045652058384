import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PortalLayout } from './layouts/PortalLayout';
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { User } from 'oidc-client-ts';
import AppRequiredAuthentication from '../../common/AppRequiredAuthentication';
import IntakeList from './pages/IntakeList';
import NotFoundPage from '../../common/page/NotFoundPage';
import SelectMatches from './pages/SelectMatches';
import Placeholder from './pages/Placeholder';
import MatchDetailsPage from './pages/MatchDetailsPage';

const oidcConfig: AuthProviderProps = {
    authority: '/auth/realms/' + window.env.REACT_APP_KEYCLOAK_REALM,
    client_id: window.env.REACT_APP_KEYCLOAK_CLIENT_ID,
    redirect_uri: window.location.href,
    onSigninCallback: (user: User | void) => {
        window.history.replaceState({}, document.title, window.location.pathname);
    },
};
export default function PortalRouting() {
    return (
        <AuthProvider {...oidcConfig}>
            <AppRequiredAuthentication>
                <PortalRoutes />
            </AppRequiredAuthentication>
        </AuthProvider>
    );
}

function PortalRoutes() {
    return (
        <Routes>
            <Route element={<PortalLayout />}>
                <Route path='*' element={<NotFoundPage />} />
                <Route path='/' element={<Navigate to='intakes' />} />
                <Route path='intakes' element={<IntakeList />} />
                <Route
                    path='intakes/:intakeId/matches'
                    element={
                        window.env.REACT_APP_FEATURE_MATCH_SELECTION_ENABLED ? (
                            <SelectMatches />
                        ) : (
                            <Placeholder domain='intake' />
                        )
                    }
                />
                <Route path='intakes/matches/:matchResultId' element={<MatchDetailsPage />} />
            </Route>
        </Routes>
    );
}
