import React from 'react';
import Gebruikerslist from '../components/Gebruikerslist';
import { useTranslation } from 'react-i18next';
import PartnerPageHeader from '../components/PartnerPageHeader';
import { useParams } from 'react-router-dom';

export default function PartnersGebruikersPage() {
    const { t } = useTranslation();
    const { partnerId } = useParams();

    if (!partnerId) {
        return <div>{t('common:error.REQUEST_MISSING_PARAMETER')}</div>;
    }
    return (
        <>
            <PartnerPageHeader></PartnerPageHeader>
            <Gebruikerslist partnerId={partnerId} />
        </>
    );
}
