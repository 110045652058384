import * as React from 'react';
import { MatchDetailVraaggroep } from '../../../generated/softwarematching-api';

type Props = {
    vraaggroep: MatchDetailVraaggroep;
};

export function MatchDetailVraaggroepVragen({ vraaggroep }: Props) {
    return (
        <div className='space-y-4'>
            <div className='underline'>{vraaggroep.naam}</div>
            {vraaggroep.vragenEnAntwoorden.map(va => {
                return (
                    <div key={va.vraag}>
                        <div>{va.vraag}</div>
                        <ul className='list-inside list-disc'>
                            {va.antwoorden.map(a => (
                                <li className='' key={a}>
                                    {a}
                                </li>
                            ))}
                        </ul>
                    </div>
                );
            })}
        </div>
    );
}
