import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IntakeAntwoord, IntakeVoortgang, IntakeVoortgangItem } from '../../../../generated/softwarematching-api';
import useIntakeService from '../../hooks/useIntakeService';
import FormAnswerVraag from '../FormAnswerVraag';
import IntakePhase from '../IntakePhase';
import { findFunctionaliteitVraaggroepById } from '../../helpers/intake';
import { BrowseDirection } from '../../../../common/Constants';

type Props = {
    intakeVoortgang: IntakeVoortgang;
    currentIntakeVoortgangItem: IntakeVoortgangItem;
    onUpdateIntakeVoortgang: (intakeVoortgang: IntakeVoortgang) => void;
    browse: (direction: BrowseDirection) => void;
};

export default function IntakeFunctionaliteitenVraaggroepen({
    intakeVoortgang,
    currentIntakeVoortgangItem,
    onUpdateIntakeVoortgang,
    browse,
}: Props) {
    const klantvraagId = intakeVoortgang.klantvraagId!!;
    const currentVraagId = currentIntakeVoortgangItem.vraagId;
    const currentVraaggroep = findFunctionaliteitVraaggroepById(
        intakeVoortgang,
        currentIntakeVoortgangItem.vraagGroepId!!
    );

    const { t } = useTranslation();

    const intakeService = useIntakeService();

    const handleSubmitVraag = useCallback(
        (intakeAntwoord: IntakeAntwoord) => {
            return intakeService
                .postFunctionaliteitAntwoord(klantvraagId, intakeAntwoord)
                .then(onUpdateIntakeVoortgang);
        },
        [intakeService, klantvraagId, onUpdateIntakeVoortgang]
    );

    const handleEditVraag = useCallback(
        (intakeAntwoord: IntakeAntwoord) => {
            return intakeService
                .putFunctionaliteitAntwoord(klantvraagId, currentVraagId!, intakeAntwoord)
                .then(onUpdateIntakeVoortgang);
        },
        [intakeService, klantvraagId, currentVraagId, onUpdateIntakeVoortgang]
    );

    return (
        <IntakePhase
            title={currentVraaggroep?.naam ?? t('intake.intakePhase.FUNCTIONALITEITSGROEP_VRAAGGROEPEN')}
            intakeVoortgang={intakeVoortgang}
            currentIntakeVoortgangItem={currentIntakeVoortgangItem}
            showPreviousButton
            showNextButton
            previousCallback={() => browse('previous')}>
            <FormAnswerVraag
                klantvraagId={klantvraagId}
                vraagId={currentVraagId!}
                onEdit={handleEditVraag}
                onSave={handleSubmitVraag}
                skipQuestion={() => browse('next')}
            />
        </IntakePhase>
    );
}
