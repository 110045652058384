import React from 'react';
import { useTranslation } from 'react-i18next';
import LoginLogoutButton from '../button/LoginLogoutButton';

export default function UnauthorizedPage() {
    const { t } = useTranslation();

    return (
        <div className='min-h-screen flex text-center justify-center items-center flex-col bg-white'>
            <main>
                <h1 className='text-2xl pb-5'>{t('common:pageUnauthorized')}</h1>
                <LoginLogoutButton />
            </main>
        </div>
    );
}
